/* eslint-disable @nx/enforce-module-boundaries */
import {
    Box,
    Button,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Stack,
} from '@mybridge/ui';
import { forwardRef, useEffect, useRef } from 'react';
import { useDisclosure } from '@mybridge/ui';
import { useOutsideClick } from '@mybridge/ui';
import styles from './filter-popover.module.scss';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const FilterPopover = forwardRef(
    ({ trigger, children, onPopClose, withForm, isFetching, placement, ...props }, ref) => {
        const { onOpen, onClose, isOpen, onToggle } = useDisclosure();
        const refElem = useRef();
        const initialFocusRef = useRef();
        const { t } = useTranslator();
        useEffect(() => {
            if (isFetching) {
                // onOpen();
            }
        }, [isFetching]);

        useOutsideClick({
            ref: refElem,
            handler: () => { 
                onClose() 
                onPopClose(false)
            },
        })

        return (
            <Box ref={refElem} width= "175px" className={styles.filterDropdownWrapper}>
                <Box className={isOpen ? styles.statusContainerActive : styles.statusContainer} onClick={onToggle}>
                    {trigger}
                </Box>
                {isOpen && (
                    <Stack className={withForm ? styles.jobKeywordsForm : styles.filterDropdown}>
                        <Stack p={0}>{children}</Stack>
                        {withForm && (
                            <HStack style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        onClose();
                                        onPopClose(false);
                                    }}
                                    size="sm"
                                >
                                    {t('buttons.cancel') || "Cancel"}
                                </Button>
                                <Button type="submit" variant="primary" size="sm">
                                    {t('searchPage.showResults') || "Show Results"}
                                </Button>
                            </HStack>
                        )}
                    </Stack>
                )}
            </Box>
        );
    }
);
