/* eslint-disable @nx/enforce-module-boundaries */
import { Center } from '@chakra-ui/react';
import { Button } from '@mybridge/ui/button';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import dynamic from 'next/dynamic';
import { useDisclosure } from '@chakra-ui/react';
const PhotonJS = dynamic(
  () => import('@silvia-odwyer/photon').then((mod) => mod),
  {
    ssr: false,
  }
);

import {
  Checkbox,
  FormControl,
  IconButton,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
} from '@mybridge/ui';
import { isMobile } from 'react-device-detect';
import { DeleteIcon } from '@mybridge/icons';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const ImageUploaderCropTypes = {
  profile: {
    name: 'profile',
    ratio: 1,
    circular: true,
  },
  profilePhoto: {
    name: 'profilePhoto',
    ratio: 1,
    circular: true,
  },
  adSquarePhoto: {
    name: 'adSquarePhoto',
    ratio: 1,
  },
  profile_banner_pic: {
    name: 'profile_banner_pic',
    ratio: 95 / 34,
  },
  event_banner_pic: {
    name: 'event_banner_pic',
    ratio: 16 / 9,
  },
  banner: {
    name: 'banner',
    ratio: 95 / 34,
  },
  article_banner: {
    name: 'article_banner',
    ratio: 16 / 9,
  },
};

export const ImageUploaderNew = ({
  // ratio,
  index = 0,
  noUploadRequire = false,
  _src,
  _image,
  _fileData,
  cropType = 'profile', // profile | banner
  onSave,
  accept = 'image/png, image/jpeg, image/jpg',
  forProfile,
  onFeelingChange,
  onProfilePostData,
  ...props
}) => {
  const [photon, setPhoton] = useState();
  const imgRef = useRef(null);
  const lgImgRef = useRef(null);
  const canvasRef = useRef(null);
  const pCanvasRef = useRef(null);
  const inputRef = useRef(null);
  const tidRef = useRef(-1);
  const tid2Ref = useRef(-1);

  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState();
  const [fileData, setFileData] = useState(undefined);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [shareOnFeeds, setShareOnFeeds] = useState(forProfile ? true : false);
  const [profileDesc, setProfileDesc] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [filter, setFilter] = useState();
  const [adjustments, setAdjustments] = useState();
  const [aspect, setAspect] = useState();
  const [processedImage, setProcessedImage] = useState();
  const [processedImageBlob, setProcessedImageBlob] = useState();
  const router = useRouter();
  const { t } = useTranslator();
  const filters = useMemo(
    () => [
      {
        title: 'Oceanic',
        name: 'oceanic',
      },
      {
        title: 'Serinity',
        name: 'serinity',
      },
      {
        title: 'Lofi',
        name: 'lofi',
      },
      {
        title: 'Dramatic',
        name: 'dramatic',
      },
      { title: 'Islands', name: 'islands' },
      { title: 'Marine', name: 'marine' },
      { title: 'Seagreen', name: 'seagreen' },
      { title: 'Flagblue', name: 'flagblue' },
      { title: 'Liquid', name: 'liquid' },
      { title: 'Diamante', name: 'diamante' },
      { title: 'Vintage', name: 'vintage' },
      { title: 'Perfume', name: 'perfume' },
      { title: 'Cali', name: 'cali' },
      { title: 'Obsidian', name: 'obsidian' },
      { title: 'Firenze', name: 'firenze' },
      { title: 'Golden', name: 'golden' },
      { title: 'Pastel Pink', name: 'pastel_pink' },
    ],
    []
  );

  const ADJUSTMENTS = useMemo(
    () => [
      {
        title: t('imageUpload.brightness') || 'Brightness',
        name: 'inc_brightness',
        cb: async (p, img, val) => {
          await p.inc_brightness(img, val);
        },
        step: 1,
        min: 1,
        max: 100,
      },
      {
        title: t('imageUpload.contrast') || 'Contrast',
        name: 'adjust_contrast',
        cb: async (p, img, val = 1) => {
          await p.adjust_contrast(img, val);
        },
        step: 1,
        min: 1,
        max: 100,
      },
      {
        title: t('imageUpload.saturation') || 'Saturation',
        name: 'saturation',
        cb: async (p, img, val = 1) => {
          await p.adjust_contrast(img, val);
        },
        step: 1,
        min: 1,
        max: 100,
      },
    ],
    []
  );

  useEffect(() => {
    initializeCropper();
  }, [cropType, canvasRef.current, imgRef.current]);

  useEffect(() => {
    import('@silvia-odwyer/photon')
      .then((p) => {
        setPhoton(p);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (noUploadRequire) {
      deletePhoto();
      setFileData(_fileData);
      setSrc(_src);
      setImage(_image);
    }
  }, []);

  useEffect(() => {
    if (!imgRef.current || !canvasRef.current || !image || !photon || !fileData)
      return;
    render();
  }, [scale, rotate, crop]);
  useEffect(() => {
    if (!imgRef.current || !canvasRef.current || !image || !photon || !fileData)
      return;
    clearTimeout(tid2Ref.current);
    tid2Ref.current = setTimeout(() => {
      render();
    }, 1);
  }, [filter, adjustments, photon, canvasRef?.current, imgRef.current, image]);

  const initializeCropper = () => {
    if (!canvasRef.current || !imgRef.current) return;

    const cropType_ = ImageUploaderCropTypes?.[cropType];
    console.warn(canvasRef.current, imgRef.current, cropType_);
    if (!cropType_?.ratio) return;
    let iw = imgRef.current.width;
    let ih = imgRef.current.height;
    let w = imgRef.current.width;
    let h = imgRef.current.height;
    if (cropType_.ratio === 1) {
      w = Math.min(w, h);
      h = w;
    } else {
      h = w / cropType_.ratio;
    }
    let x = iw / 2 - w / 2;
    let y = ih / 2 - h / 2;

    const crop_ = {
      x,
      y,
      width: w,
      height: h,
      unit: 'px',
    };
    setCrop(crop_);
    setAspect(cropType_?.ratio);
  };

  const handleCropChange = (val) => {
    if (val && val.width > 0 && val.height > 0) {
      setCrop(val);
    }
  };

  const handleFileChange = (e) => {
    deletePhoto();
    setFileData(e.target.files[0]);
    const url = URL.createObjectURL(e.target.files[0]);
    setSrc(url);
    try {
      if (typeof window === 'undefined') return;
      const img = new window.Image();
      img.onload = function () {
        setImage(img);
        console.warn(img.width, img.height);
      };
      img.src = url;
    } catch (e) {
      console.error(e);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const url = URL.createObjectURL(e.dataTransfer.files[0]);
      setFileData(e.dataTransfer.files[0]);
      setSrc(url);
      try {
        if (typeof window === 'undefined') return;
        const img = new window.Image();
        img.onload = function () {
          setImage(img);
          console.warn(img.width, img.height);
        };
        img.src = url;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const render = async () => {
    if (!photon) return;
    if (!canvasRef.current || !imgRef.current) return;
    const ctx = canvasRef?.current?.getContext('2d');
    const canvas = canvasRef?.current;

    const cw = (canvas.width = imgRef.current.width);
    const ch = (canvas.height = imgRef.current.height);

    ctx.transform(
      scale,
      0,
      0,
      scale,
      (-(scale - 1) * cw) / 2,
      (-(scale - 1) * ch) / 2
    );

    ctx.translate(cw / 2, ch / 2);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.translate(-cw / 2, -ch / 2);

    ctx.drawImage(image, 0, 0, cw, ch);

    const img = photon.open_image(canvasRef.current, ctx);

    if (filter?.length) {
      await photon.filter(img, filter);
    }
    if (adjustments?.length) {
      for (let adjustment of adjustments) {
        await adjustment?.cb?.(photon, img, adjustment?.value);
      }
    }

    photon.putImageData(canvasRef.current, ctx, img);
    clearTimeout(tidRef.current);
    // do not change timeout value (it will make the uploader sluggish)
    tidRef.current = setTimeout(() => renderLarge?.(), 500);
  };

  const renderLarge = async () => {
    const ctx = pCanvasRef?.current?.getContext?.('2d');
    if (!ctx) return;
    const canvas = pCanvasRef?.current;

    const cw = (canvas.width = lgImgRef.current.width);
    const ch = (canvas.height = lgImgRef.current.height);

    ctx.transform(
      scale,
      0,
      0,
      scale,
      (-(scale - 1) * cw) / 2,
      (-(scale - 1) * ch) / 2
    );

    ctx.translate(cw / 2, ch / 2);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.translate(-cw / 2, -ch / 2);

    ctx.drawImage(image, 0, 0, cw, ch);

    const img = photon.open_image(canvas, ctx);

    if (filter?.length) {
      await photon.filter(img, filter);
    }
    if (adjustments?.length) {
      for (let adjustment of adjustments) {
        await adjustment?.cb?.(photon, img, adjustment?.value);
      }
    }

    photon.putImageData(canvas, ctx, img);
    const rx = lgImgRef.current.width / imgRef.current.width;
    const ry = lgImgRef.current.height / imgRef.current.height;
    try {
      const crw = crop?.width ?? cw;
      const crh = crop?.height ?? ch;
      const crx = crop?.x ?? 0;
      const cry = crop?.y ?? 0;
      const pi = ctx.getImageData(
        (crx ?? 0) * rx,
        (cry ?? 0) * ry,
        crw * rx,
        crh * ry
      );
      canvas.width = crw * rx;
      canvas.height = crh * ry;
      canvas.style.width = crx * rx + 'px';
      canvas.style.height = cry * ry + 'px';

      ctx.putImageData(pi, 0, 0, 0, 0, crw * rx, crh * ry);
      const url = canvas.toDataURL();
      setProcessedImage(url);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (filter) => (e) => {
    setFilter(filter);
  };
  const toggleAdjustment = (adj) => (e) => {
    const exists = adjustments?.find?.((a) => a.name === adj.name);
    if (exists) {
      const nadjs = adjustments?.filter?.((a) => a.name !== adj.name);
      setAdjustments(nadjs);
    } else {
      const nadjs = [...(adjustments ?? []), adj];
      setAdjustments(nadjs);
    }
  };

  const handleAdjustmentValueChange = (adjustment, value) => {
    clearTimeout(tidRef?.current);
    tidRef.current = setTimeout(() => {
      const adj = adjustments?.find?.((a) => a.name === adjustment?.name);
      if (adj) {
        adj.value = value;
        setAdjustments([...(adjustments ?? [])]);
      }
      // setAdjustmentValue(value);
    }, 300);
  };

  const deletePhoto = () => {
    setImage(null);
    setFilter(null);
    setFileData(null);
  };

  const onSaveClick = async (e) => {
   
    if (!fileData) return;
    console.log('enerrrrrrrrrrrrrr')
    pCanvasRef?.current?.toBlob?.(
      (blob) => {
        const file = new File([blob], fileData?.name, {
          type: fileData?.type,
          lastModified: fileData?.lastModified,
        });
        console.warn(file);
        if(shareOnFeeds)onProfilePostData({post_description: profileDesc, show_on_feed:shareOnFeeds});
        onSave?.(file, index);
      },
      { type: fileData?.type }
    );
  };

  const handleRedirect = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { id } = router?.query;
    const { asPath } = router;
    if (['/company'].some((condition) => asPath?.includes(condition))) {
      router?.push(`/page/private/${id?.[0]}/photos`);
    } else {
      router?.push(`/profile/photos`);
    }
  };

  useEffect(() => {
    onProfilePostData({post_description: profileDesc, show_on_feed:shareOnFeeds});
  }, [profileDesc, shareOnFeeds])

  useEffect(() => {
    const img = imgRef.current;
    if (img && src) { // Ensure src is set
      const handleImageLoad = () => {
        initializeCropper(); 
      };
  
      if (img.complete) { // Image already cached
        handleImageLoad(); 
      } else {
        img.onload = handleImageLoad;
      }
  
      return () => {
        img.onload = null; // Cleanup
      };
    }
  }, [src]); // Trigger on src change 

  // console.log('shareOnFeeds', profileDesc)

  if (!fileData) {
    return (
      <div className="d-flex flex-column pt-0 p-1 image-uploader">
        {/* Body */}
        <Stack
          my="2"
          bg="blackAlpha.100"
          p={4}
          borderRadius="12"
          alignItems="center"
          justifyContent="center"
          minH="40vh"
        >
          <Input
            type="file"
            ref={inputRef}
            accept={accept}
            id="image-uploader-input"
            className="image-uploader__input"
            display="none"
            onChange={handleFileChange}
          />
          <Stack
            alignItems="center"
            onClick={(e) => inputRef?.current?.click?.()}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Box>
              <Text
                mr="1"
                style={{ cursor: 'pointer' }}
                textDecoration="underline"
                fontSize="18"
                display="inline"
                fontWeight="medium"
              >
                {t('imageUpload.clickToUpload') || "Click to upload"}
              </Text>
              <Text mr="1" fontSize="18" display="inline">
                or
              </Text>

              <Text
                onClick={(e) => handleRedirect(e)}
                mr="1"
                style={{ cursor: 'pointer', zIndex: 999 }}
                textDecoration="underline"
                fontSize="18"
                display="inline"
                fontWeight="medium"
              >
                {t('imageUpload.photoLibrary') || "Photo library"}
              </Text>
            </Box>
            <Text fontSize="18" display="inline">
              {t('imageUpload.orDragAndDrop') || "or drag and drop"}
            </Text>
            <Text fontSize="14">{t('imageUpload.maximumFileSize') || "Maximum file size 50 MB"}</Text>
            <Text fontSize="13">{t('imageUpload.uploadImage') || "Upload image"}</Text>
            <Text fontSize="13">
              {t('imageUpload.editOnNextStep') || "You will be able to edit on the next step."}
            </Text>
          </Stack>
          {/* {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
            </div>
          )} */}
        </Stack>

        {/* Footer */}

        <Box>
          <Center>
            <Button
              variant="primary"
              className="btn btn-primary h-36"
              onClick={() => inputRef?.current?.click()}
            >
              {t('imageUpload.uploadPhoto') || "Upload Photo"}
            </Button>
          </Center>
        </Box>
      </div>
    );
  }

  return (
    <Stack>
      <Stack>
      {shareOnFeeds &&  cropType === 'profilePhoto' ? (
          <FormControl>
            <Textarea
              placeholder={t('profileExperience.description') || "Description"}
              variant="whiteInput"
              border="1px solid #E4E6EB"
              onChange={(e) => setProfileDesc(e.target.value)}
              maxLength="250"
            />
          </FormControl>
        ) : (
          <></>
        )}
        
        <HStack spacing={0} bg="blackAlpha.500" justifyContent="center">
          <Box>
            <ReactCrop
              style={{ display: 'block' }}
              circularCrop={ImageUploaderCropTypes?.[cropType]?.circular}
              aspect={aspect}
              crop={crop}
              onChange={handleCropChange}
            >
              {/* <Image maxH="50vh" src={src} /> */}
              <Box bg="gray.500" pos="relative">
                <Image
                  ref={imgRef}
                  src={src}
                  pos="relative"
                  zIndex={0}
                  opacity={0}
                  height="100%"
                  maxH="50vh"
                  alt=""
                  // style={{ transform: `scale(${scale})` }}
                />
                <Box
                  zIndex={0}
                  pos="absolute"
                  left={0}
                  right={0}
                  top={0}
                  bottom={0}
                  // display={activeTab > 0 ? 'block' : 'none'}
                >
                  <canvas
                    ref={canvasRef}
                    style={{ width: '100%', height: '100%' }}
                    willReadFrequently={true}
                  />
                </Box>
              </Box>
            </ReactCrop>
          </Box>
        </HStack>

        <Stack>
          <Tabs index={activeTab}>
            <TabList>
              <Tab onClick={(e) => setActiveTab(0)}>{t('imageUpload.crop') || "Crop"}</Tab>
              <Tab onClick={(e) => setActiveTab(1)}>{t('imageUpload.filters') || "Filters"}</Tab>
              <Tab onClick={(e) => setActiveTab(2)}>{t('imageUpload.adjust') || "Adjust"}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HStack w="100%" spacing={8}>
                  <Stack flex={1}>
                    <Text>{t('imageUpload.zoom') || "Zoom"}</Text>
                    <Slider
                      onChange={(v) => setScale(v)}
                      step={0.1}
                      min={1}
                      defaultValue={1}
                      max={10}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Stack>

                  <Stack flex={1}>
                    <Text>{t('imageUpload.straighten') || "Straighten"}</Text>
                    <Slider
                      onChange={(v) =>
                        setRotate(Math.min(360, Math.max(-360, v)))
                      }
                      step={0.1}
                      min={0}
                      defaultValue={0}
                      max={360}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Stack>
                </HStack>
              </TabPanel>

              <TabPanel>
                <HStack w="100%" flexWrap="wrap">
                  {filters?.map?.((f, find) => (
                    <Button
                      variant={f.name === filter ? 'primary' : 'secondary'}
                      onClick={handleFilterChange(
                        f.name === filter ? '' : f.name
                      )}
                      key={find}
                    >
                      {f.title}
                    </Button>
                  ))}
                  <Stack flex={1}></Stack>
                </HStack>
              </TabPanel>
              <TabPanel>
                <Stack flex={1} spacing={6}>
                  <HStack w="100%">
                    {ADJUSTMENTS?.map?.((a, aind) => (
                      <Button
                        variant={
                          adjustments?.find?.(({ name }) => name === a.name)
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={toggleAdjustment(a)}
                        key={aind}
                      >
                        {a.title}
                      </Button>
                    ))}
                  </HStack>
                  <Stack>
                    {adjustments?.map?.((a, aind) => (
                      <Stack key={aind}>
                        <Text>{a.title}</Text>
                        <Slider
                          onChange={(v) => handleAdjustmentValueChange(a, v)}
                          step={a?.step ?? 1}
                          min={a?.min ?? 0}
                          defaultValue={0}
                          max={a?.max ?? 100}
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
                    {forProfile && <HStack>
          <Text>{t('imageUpload.shareUpdateToFeed') || "Share your update to News Feed"}</Text>
          <Checkbox value={shareOnFeeds} defaultChecked={shareOnFeeds} onChange={(e) => setShareOnFeeds(e.target.checked)} />
        </HStack>}
        
      </Stack>

      {/* Footer */}

      <Stack>
        <HStack w="100%" justifyContent="space-between">
          {!isMobile ? (
            <HStack flex={1}>
              <Button
                variant="outlined"
                className="btn btn-danger h-36 me-2"
                color="red"
                onClick={deletePhoto}
              >
                {t('imageUpload.deletePhoto') || "Delete Photo"}
              </Button>
            </HStack>
          ) : (
            <IconButton icon={<DeleteIcon />} variant="transparent" />
          )}

          <HStack>
            <Input
              type="file"
              ref={inputRef}
              accept="image/png, image/jpeg, image/jpg"
              id="image__uploader__input"
              onChange={handleFileChange}
              display="none"
            />
            <Button
              className="camera__button h-36 me-2"
              variant="secondary"
              fontSize="15"
              px="4"
              onClick={() => {
                inputRef?.current?.click();
              }}
              mx="2"
            >
              {t('imageUpload.changePhoto') || "Change Photo"}
            </Button>
            <Button
              // isLoading={isLoading}
              variant="primary"
              onClick={onSaveClick}
            >
              {t('imageUpload.savePhoto') || "Save Photo"}
            </Button>
          </HStack>
        </HStack>
        {/* <Image maxW="100vw" src={processedImage} /> */}
      </Stack>

      <Box display="none" overflow="scroll">
        <Box pos="relative">
          <Image opacity="0" ref={lgImgRef} src={src} alt="" />
          <Box
            zIndex={0}
            pos="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            // display={activeTab > 0 ? 'block' : 'none'}
          >
            <canvas ref={pCanvasRef} willReadFrequently={true} />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
