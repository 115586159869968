const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const genralMonths = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const genderData = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Agender', value: 'Agender' },
  { label: 'Androgyne', value: 'Androgyne' },
  { label: 'Androgynous', value: 'Androgynous' },
  { label: 'Bigender', value: 'Bigender' },
  { label: 'Cis', value: 'Cis' },
  { label: 'Cis Female', value: 'Cis Female' },
  { label: 'Cis Male', value: 'Cis Male' },
  { label: 'Cis Man', value: 'Cis Man' },
  { label: 'Cis Woman', value: 'Cis Woman' },
  { label: 'Cisgender', value: 'Cisgender' },
];

const pronounsData = [
  { label: 'She/Her', value: 'She/Her' },
  { label: 'He/Him', value: 'He/Him' },
  { label: 'They/Them', value: 'They/Them' },
];

const causesYouCare = [
  'Animal Welfare',
  'Civil Rights and Social Action',
  'Economic Empowerment',
  'Environment',
  'Human Rights',
  'Poverty Alleviation',
  'Social Services',
  'Children',
  'Disaster and Humanitarian Relief',
  'Education',
  'Health',
  'Politics',
  'Science and Technology',
  'Others',
];

export const EMPLOYMENT_EXPERIENCE_LEVEL = [
  {
    label: 'Internship',
    value: 'internship',
  },
  {
    label: 'Entry Level',
    value: 'Entry Level',
  },
  {
    label: 'Associate',
    value: 'Associate',
  },
  {
    label: 'Mid-Senior level',
    value: 'Mid-Senior level',
  },
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'Executive',
    value: 'Executive',
  },
];

export const EMPLOYMENT_EXPERIENCE_LEVEL_FILTERS = [
  {
    label: 'Internship',
    value: 'INTERNSHIP',
  },
  {
    label: 'Entry Level',
    value: 'ENTRYLEVEL',
  },
  {
    label: 'Associate',
    value: 'ASSOCIATE',
  },
  {
    label: 'Mid-Senior level',
    value: 'MIDSENIORLEVEL',
  },
  {
    label: 'Director',
    value: 'DIRECTOR',
  },
  {
    label: 'Executive',
    value: 'EXECUTIVE',
  },
];

const EMPLOYMENT_CHOICES = [
  { label: 'Full-Time', value: 'FT', translationKey: 'jobs.fullTime' },
  { label: 'Part-Time', value: 'PT', translationKey: 'jobs.partTime' },
  { label: 'Contractor', value: 'CT', translationKey: 'jobs.contractor' },
  { label: 'Temporary', value: 'T', translationKey: 'jobs.temporary' },
  { label: 'Other', value: 'OTH', translationKey: 'jobs.other' },
];
const PAYMENT_TYPE = [
  { label: 'Annually', value: 'Annually', translationKey: 'otherModules.annually' },
  { label: 'Monthly', value: 'Monthly', translationKey: 'otherModules.monthly' },
  { label: 'Weekly', value: 'Weekly', translationKey: 'otherModules.weekly' },
  { label: 'Daily', value: 'Daily', translationKey: 'otherModules.daily' },
  { label: 'Hourly', value: 'Hourly', translationKey: 'otherModules.hourly' },
];

const BENEFITS = [
  { label: 'Medical Insurance', value: 'Medical_Insurance', id: 1, translationKey: 'otherModules.medicalInsurance' },
  { label: 'Dental Insurance', value: 'Dental_Insurance', id: 2, translationKey: 'otherModules.dentalInsurance' },
  { label: 'Vision Insurance', value: 'Vision_Insurance', id: 3, translationKey: 'otherModules.visionInsurance' },
  { label: '401K', value: '401K', id: 4, translationKey: 'otherModules.401K' },
  { label: 'Life Insurance', value: 'Life_Insurance', id: 5, translationKey: 'otherModules.lifeInsurance' },
  // { label: 'None of these', value: 'None_of_these', id: 6 },
];

const LANG_CHOICES = [
  { value: 'ab', label: 'Abkhazian' },
  { value: 'aa', label: 'Afar' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ae', label: 'Avestan' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bm', label: 'Bambara' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'eu', label: 'Basque' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bh', label: 'Bihari languages' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'ca', label: 'Catalan, Valencian' },
  { value: 'km', label: 'Central Khmer' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ny', label: 'Chichewa, Chewa, Nyanja' },
  { value: 'zh', label: 'Chinese' },
  { value: 'cu', label: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'kw', label: 'Cornish' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'dv', label: 'Divehi, Dhivehi, Maldivian' },
  { value: 'nl', label: 'Dutch, Flemish' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'ee', label: 'Ewe' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'ff', label: 'Fulah' },
  { value: 'gd', label: 'Gaelic, Scottish Gaelic' },
  { value: 'gl', label: 'Galician' },
  { value: 'lg', label: 'Ganda' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'ki', label: 'Gikuyu, Kikuyu' },
  { value: 'el', label: 'Greek (Modern)' },
  { value: 'kl', label: 'Greenlandic, Kalaallisut' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian, Haitian Creole' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hz', label: 'Herero' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'io', label: 'Ido' },
  { value: 'ig', label: 'Igbo' },
  { value: 'id', label: 'Indonesian' },
  {
    value: 'ia',
    label: 'Interlingua (International Auxiliary Language Association)',
  },
  { value: 'ie', label: 'Interlingue' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ik', label: 'Inupiaq' },
  { value: 'ga', label: 'Irish' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'kv', label: 'Komi' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ko', label: 'Korean' },
  { value: 'kj', label: 'Kwanyama, Kuanyama' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'ky', label: 'Kyrgyz' },
  { value: 'lo', label: 'Lao' },
  { value: 'la', label: 'Latin' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lb', label: 'Letzeburgesch, Luxembourgish' },
  { value: 'li', label: 'Limburgish, Limburgan, Limburger' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lu', label: 'Luba-Katanga' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'gv', label: 'Manx' },
  { value: 'mi', label: 'Maori' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'ro', label: 'Moldovan, Moldavian, Romanian' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'na', label: 'Nauru' },
  { value: 'nv', label: 'Navajo, Navaho' },
  { value: 'nd', label: 'Northern Ndebele' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'ne', label: 'Nepali' },
  { value: 'se', label: 'Northern Sami' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nb', label: 'Norwegian Bokmål' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'ii', label: 'Nuosu, Sichuan Yi' },
  { value: 'oc', label: 'Occitan (post 1500)' },
  { value: 'oj', label: 'Ojibwa' },
  { value: 'or', label: 'Oriya' },
  { value: 'om', label: 'Oromo' },
  { value: 'os', label: 'Ossetian, Ossetic' },
  { value: 'pi', label: 'Pali' },
  { value: 'pa', label: 'Panjabi, Punjabi' },
  { value: 'ps', label: 'Pashto, Pushto' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rn', label: 'Rundi' },
  { value: 'ru', label: 'Russian' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sg', label: 'Sango' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sc', label: 'Sardinian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sn', label: 'Shona' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'si', label: 'Sinhala, Sinhalese' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'st', label: 'Sotho, Southern' },
  { value: 'nr', label: 'South Ndebele' },
  { value: 'es', label: 'Spanish, Castilian' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ss', label: 'Swati' },
  { value: 'sv', label: 'Swedish' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'tg', label: 'Tajik' },
  { value: 'ta', label: 'Tamil' },
  { value: 'tt', label: 'Tatar' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'to', label: 'Tonga (Tonga Islands)' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tn', label: 'Tswana' },
  { value: 'tr', label: 'Turkish' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tw', label: 'Twi' },
  { value: 'ug', label: 'Uighur, Uyghur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volap_k' },
  { value: 'wa', label: 'Walloon' },
  { value: 'cy', label: 'Welsh' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'wo', label: 'Wolof' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang, Chuang' },
  { value: 'zu', label: 'Zulu' },
];

const COUNTRY_CODE = [
  { country: 'Afghanistan', code: '93', iso: 'AF' },
  { country: 'Albania', code: '355', iso: 'AL' },
  { country: 'Algeria', code: '213', iso: 'DZ' },
  { country: 'American Samoa', code: '1-684', iso: 'AS' },
  { country: 'Andorra', code: '376', iso: 'AD' },
  { country: 'Angola', code: '244', iso: 'AO' },
  { country: 'Anguilla', code: '1-264', iso: 'AI' },
  { country: 'Antarctica', code: '672', iso: 'AQ' },
  { country: 'Antigua and Barbuda', code: '1-268', iso: 'AG' },
  { country: 'Argentina', code: '54', iso: 'AR' },
  { country: 'Armenia', code: '374', iso: 'AM' },
  { country: 'Aruba', code: '297', iso: 'AW' },
  { country: 'Australia', code: '61', iso: 'AU' },
  { country: 'Austria', code: '43', iso: 'AT' },
  { country: 'Azerbaijan', code: '994', iso: 'AZ' },
  { country: 'Bahamas', code: '1-242', iso: 'BS' },
  { country: 'Bahrain', code: '973', iso: 'BH' },
  { country: 'Bangladesh', code: '880', iso: 'BD' },
  { country: 'Barbados', code: '1-246', iso: 'BB' },
  { country: 'Belarus', code: '375', iso: 'BY' },
  { country: 'Belgium', code: '32', iso: 'BE' },
  { country: 'Belize', code: '501', iso: 'BZ' },
  { country: 'Benin', code: '229', iso: 'BJ' },
  { country: 'Bermuda', code: '1-441', iso: 'BM' },
  { country: 'Bhutan', code: '975', iso: 'BT' },
  { country: 'Bolivia', code: '591', iso: 'BO' },
  { country: 'Bosnia and Herzegovina', code: '387', iso: 'BA' },
  { country: 'Botswana', code: '267', iso: 'BW' },
  { country: 'Brazil', code: '55', iso: 'BR' },
  { country: 'British Indian Ocean Territory', code: '246', iso: 'IO' },
  { country: 'British Virgin Islands', code: '1-284', iso: 'VG' },
  { country: 'Brunei', code: '673', iso: 'BN' },
  { country: 'Bulgaria', code: '359', iso: 'BG' },
  { country: 'Burkina Faso', code: '226', iso: 'BF' },
  { country: 'Burundi', code: '257', iso: 'BI' },
  { country: 'Cambodia', code: '855', iso: 'KH' },
  { country: 'Cameroon', code: '237', iso: 'CM' },
  { country: 'Canada', code: '1', iso: 'CA' },
  { country: 'Cape Verde', code: '238', iso: 'CV' },
  { country: 'Cayman Islands', code: '1-345', iso: 'KY' },
  { country: 'Central African Republic', code: '236', iso: 'CF' },
  { country: 'Chad', code: '235', iso: 'TD' },
  { country: 'Chile', code: '56', iso: 'CL' },
  { country: 'China', code: '86', iso: 'CN' },
  { country: 'Christmas Island', code: '61', iso: 'CX' },
  { country: 'Cocos Islands', code: '61', iso: 'CC' },
  { country: 'Colombia', code: '57', iso: 'CO' },
  { country: 'Comoros', code: '269', iso: 'KM' },
  { country: 'Cook Islands', code: '682', iso: 'CK' },
  { country: 'Costa Rica', code: '506', iso: 'CR' },
  { country: 'Croatia', code: '385', iso: 'HR' },
  { country: 'Cuba', code: '53', iso: 'CU' },
  { country: 'Curacao', code: '599', iso: 'CW' },
  { country: 'Cyprus', code: '357', iso: 'CY' },
  { country: 'Czech Republic', code: '420', iso: 'CZ' },
  { country: 'Democratic Republic of the Congo', code: '243', iso: 'CD' },
  { country: 'Denmark', code: '45', iso: 'DK' },
  { country: 'Djibouti', code: '253', iso: 'DJ' },
  { country: 'Dominica', code: '1-767', iso: 'DM' },
  { country: 'Dominican Republic', code: '1-809, 1-829, 1-849', iso: 'DO' },
  { country: 'East Timor', code: '670', iso: 'TL' },
  { country: 'Ecuador', code: '593', iso: 'EC' },
  { country: 'Egypt', code: '20', iso: 'EG' },
  { country: 'El Salvador', code: '503', iso: 'SV' },
  { country: 'Equatorial Guinea', code: '240', iso: 'GQ' },
  { country: 'Eritrea', code: '291', iso: 'ER' },
  { country: 'Estonia', code: '372', iso: 'EE' },
  { country: 'Ethiopia', code: '251', iso: 'ET' },
  { country: 'Falkland Islands', code: '500', iso: 'FK' },
  { country: 'Faroe Islands', code: '298', iso: 'FO' },
  { country: 'Fiji', code: '679', iso: 'FJ' },
  { country: 'Finland', code: '358', iso: 'FI' },
  { country: 'France', code: '33', iso: 'FR' },
  { country: 'French Polynesia', code: '689', iso: 'PF' },
  { country: 'Gabon', code: '241', iso: 'GA' },
  { country: 'Gambia', code: '220', iso: 'GM' },
  { country: 'Georgia', code: '995', iso: 'GE' },
  { country: 'Germany', code: '49', iso: 'DE' },
  { country: 'Ghana', code: '233', iso: 'GH' },
  { country: 'Gibraltar', code: '350', iso: 'GI' },
  { country: 'Greece', code: '30', iso: 'GR' },
  { country: 'Greenland', code: '299', iso: 'GL' },
  { country: 'Grenada', code: '1-473', iso: 'GD' },
  { country: 'Guam', code: '1-671', iso: 'GU' },
  { country: 'Guatemala', code: '502', iso: 'GT' },
  { country: 'Guernsey', code: '44-1481', iso: 'GG' },
  { country: 'Guinea', code: '224', iso: 'GN' },
  { country: 'Guinea-Bissau', code: '245', iso: 'GW' },
  { country: 'Guyana', code: '592', iso: 'GY' },
  { country: 'Haiti', code: '509', iso: 'HT' },
  { country: 'Honduras', code: '504', iso: 'HN' },
  { country: 'Hong Kong', code: '852', iso: 'HK' },
  { country: 'Hungary', code: '36', iso: 'HU' },
  { country: 'Iceland', code: '354', iso: 'IS' },
  { country: 'India', code: '91', iso: 'IN' },
  { country: 'Indonesia', code: '62', iso: 'ID' },
  { country: 'Iran', code: '98', iso: 'IR' },
  { country: 'Iraq', code: '964', iso: 'IQ' },
  { country: 'Ireland', code: '353', iso: 'IE' },
  { country: 'Isle of Man', code: '44-1624', iso: 'IM' },
  { country: 'Israel', code: '972', iso: 'IL' },
  { country: 'Italy', code: '39', iso: 'IT' },
  { country: 'Ivory Coast', code: '225', iso: 'CI' },
  { country: 'Jamaica', code: '1-876', iso: 'JM' },
  { country: 'Japan', code: '81', iso: 'JP' },
  { country: 'Jersey', code: '44-1534', iso: 'JE' },
  { country: 'Jordan', code: '962', iso: 'JO' },
  { country: 'Kazakhstan', code: '7', iso: 'KZ' },
  { country: 'Kenya', code: '254', iso: 'KE' },
  { country: 'Kiribati', code: '686', iso: 'KI' },
  { country: 'Kosovo', code: '383', iso: 'XK' },
  { country: 'Kuwait', code: '965', iso: 'KW' },
  { country: 'Kyrgyzstan', code: '996', iso: 'KG' },
  { country: 'Laos', code: '856', iso: 'LA' },
  { country: 'Latvia', code: '371', iso: 'LV' },
  { country: 'Lebanon', code: '961', iso: 'LB' },
  { country: 'Lesotho', code: '266', iso: 'LS' },
  { country: 'Liberia', code: '231', iso: 'LR' },
  { country: 'Libya', code: '218', iso: 'LY' },
  { country: 'Liechtenstein', code: '423', iso: 'LI' },
  { country: 'Lithuania', code: '370', iso: 'LT' },
  { country: 'Luxembourg', code: '352', iso: 'LU' },
  { country: 'Macao', code: '853', iso: 'MO' },
  { country: 'Macedonia', code: '389', iso: 'MK' },
  { country: 'Madagascar', code: '261', iso: 'MG' },
  { country: 'Malawi', code: '265', iso: 'MW' },
  { country: 'Malaysia', code: '60', iso: 'MY' },
  { country: 'Maldives', code: '960', iso: 'MV' },
  { country: 'Mali', code: '223', iso: 'ML' },
  { country: 'Malta', code: '356', iso: 'MT' },
  { country: 'Marshall Islands', code: '692', iso: 'MH' },
  { country: 'Mauritania', code: '222', iso: 'MR' },
  { country: 'Mauritius', code: '230', iso: 'MU' },
  { country: 'Mayotte', code: '262', iso: 'YT' },
  { country: 'Mexico', code: '52', iso: 'MX' },
  { country: 'Micronesia', code: '691', iso: 'FM' },
  { country: 'Moldova', code: '373', iso: 'MD' },
  { country: 'Monaco', code: '377', iso: 'MC' },
  { country: 'Mongolia', code: '976', iso: 'MN' },
  { country: 'Montenegro', code: '382', iso: 'ME' },
  { country: 'Montserrat', code: '1-664', iso: 'MS' },
  { country: 'Morocco', code: '212', iso: 'MA' },
  { country: 'Mozambique', code: '258', iso: 'MZ' },
  { country: 'Myanmar', code: '95', iso: 'MM' },
  { country: 'Namibia', code: '264', iso: 'NA' },
  { country: 'Nauru', code: '674', iso: 'NR' },
  { country: 'Nepal', code: '977', iso: 'NP' },
  { country: 'Netherlands', code: '31', iso: 'NL' },
  { country: 'Netherlands Antilles', code: '599', iso: 'AN' },
  { country: 'New Caledonia', code: '687', iso: 'NC' },
  { country: 'New Zealand', code: '64', iso: 'NZ' },
  { country: 'Nicaragua', code: '505', iso: 'NI' },
  { country: 'Niger', code: '227', iso: 'NE' },
  { country: 'Nigeria', code: '234', iso: 'NG' },
  { country: 'Niue', code: '683', iso: 'NU' },
  { country: 'North Korea', code: '850', iso: 'KP' },
  { country: 'Northern Mariana Islands', code: '1-670', iso: 'MP' },
  { country: 'Norway', code: '47', iso: 'NO' },
  { country: 'Oman', code: '968', iso: 'OM' },
  { country: 'Pakistan', code: '92', iso: 'PK' },
  { country: 'Palau', code: '680', iso: 'PW' },
  { country: 'Palestine', code: '970', iso: 'PS' },
  { country: 'Panama', code: '507', iso: 'PA' },
  { country: 'Papua New Guinea', code: '675', iso: 'PG' },
  { country: 'Paraguay', code: '595', iso: 'PY' },
  { country: 'Peru', code: '51', iso: 'PE' },
  { country: 'Philippines', code: '63', iso: 'PH' },
  { country: 'Pitcairn', code: '64', iso: 'PN' },
  { country: 'Poland', code: '48', iso: 'PL' },
  { country: 'Portugal', code: '351', iso: 'PT' },
  { country: 'Puerto Rico', code: '1-787, 1-939', iso: 'PR' },
  { country: 'Qatar', code: '974', iso: 'QA' },
  { country: 'Republic of the Congo', code: '242', iso: 'CG' },
  { country: 'Reunion', code: '262', iso: 'RE' },
  { country: 'Romania', code: '40', iso: 'RO' },
  { country: 'Russia', code: '7', iso: 'RU' },
  { country: 'Rwanda', code: '250', iso: 'RW' },
  { country: 'Saint Barthelemy', code: '590', iso: 'BL' },
  { country: 'Saint Helena', code: '290', iso: 'SH' },
  { country: 'Saint Kitts and Nevis', code: '1-869', iso: 'KN' },
  { country: 'Saint Lucia', code: '1-758', iso: 'LC' },
  { country: 'Saint Martin', code: '590', iso: 'MF' },
  { country: 'Saint Pierre and Miquelon', code: '508', iso: 'PM' },
  { country: 'Saint Vincent and the Grenadines', code: '1-784', iso: 'VC' },
  { country: 'Samoa', code: '685', iso: 'WS' },
  { country: 'San Marino', code: '378', iso: 'SM' },
  { country: 'Sao Tome and Principe', code: '239', iso: 'ST' },
  { country: 'Saudi Arabia', code: '966', iso: 'SA' },
  { country: 'Senegal', code: '221', iso: 'SN' },
  { country: 'Serbia', code: '381', iso: 'RS' },
  { country: 'Seychelles', code: '248', iso: 'SC' },
  { country: 'Sierra Leone', code: '232', iso: 'SL' },
  { country: 'Singapore', code: '65', iso: 'SG' },
  { country: 'Sint Maarten', code: '1-721', iso: 'SX' },
  { country: 'Slovakia', code: '421', iso: 'SK' },
  { country: 'Slovenia', code: '386', iso: 'SI' },
  { country: 'Solomon Islands', code: '677', iso: 'SB' },
  { country: 'Somalia', code: '252', iso: 'SO' },
  { country: 'South Africa', code: '27', iso: 'ZA' },
  { country: 'South Korea', code: '82', iso: 'KR' },
  { country: 'South Sudan', code: '211', iso: 'SS' },
  { country: 'Spain', code: '34', iso: 'ES' },
  { country: 'Sri Lanka', code: '94', iso: 'LK' },
  { country: 'Sudan', code: '249', iso: 'SD' },
  { country: 'Suriname', code: '597', iso: 'SR' },
  { country: 'Svalbard and Jan Mayen', code: '47', iso: 'SJ' },
  { country: 'Swaziland', code: '268', iso: 'SZ' },
  { country: 'Sweden', code: '46', iso: 'SE' },
  { country: 'Switzerland', code: '41', iso: 'CH' },
  { country: 'Syria', code: '963', iso: 'SY' },
  { country: 'Taiwan', code: '886', iso: 'TW' },
  { country: 'Tajikistan', code: '992', iso: 'TJ' },
  { country: 'Tanzania', code: '255', iso: 'TZ' },
  { country: 'Thailand', code: '66', iso: 'TH' },
  { country: 'Togo', code: '228', iso: 'TG' },
  { country: 'Tokelau', code: '690', iso: 'TK' },
  { country: 'Tonga', code: '676', iso: 'TO' },
  { country: 'Trinidad and Tobago', code: '1-868', iso: 'TT' },
  { country: 'Tunisia', code: '216', iso: 'TN' },
  { country: 'Turkey', code: '90', iso: 'TR' },
  { country: 'Turkmenistan', code: '993', iso: 'TM' },
  { country: 'Turks and Caicos Islands', code: '1-649', iso: 'TC' },
  { country: 'Tuvalu', code: '688', iso: 'TV' },
  { country: 'U.S. Virgin Islands', code: '1-340', iso: 'VI' },
  { country: 'Uganda', code: '256', iso: 'UG' },
  { country: 'Ukraine', code: '380', iso: 'UA' },
  { country: 'United Arab Emirates', code: '971', iso: 'AE' },
  { country: 'United Kingdom', code: '44', iso: 'GB' },
  { country: 'United States', code: '1', iso: 'US' },
  { country: 'Uruguay', code: '598', iso: 'UY' },
  { country: 'Uzbekistan', code: '998', iso: 'UZ' },
  { country: 'Vanuatu', code: '678', iso: 'VU' },
  { country: 'Vatican', code: '379', iso: 'VA' },
  { country: 'Venezuela', code: '58', iso: 'VE' },
  { country: 'Vietnam', code: '84', iso: 'VN' },
  { country: 'Wallis and Futuna', code: '681', iso: 'WF' },
  { country: 'Western Sahara', code: '212', iso: 'EH' },
  { country: 'Yemen', code: '967', iso: 'YE' },
  { country: 'Zambia', code: '260', iso: 'ZM' },
  { country: 'Zimbabwe', code: '263', iso: 'ZW' },
];

const BOOL_CHOICE = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const DEACT_REASONS = [
  { label: 'I have a duplicate account', value: 'I have a duplicate account' },
  {
    label: "I'm getting too many emails",
    value: "I'm getting too many emails",
  },
  {
    label: "I'm not getting any value from my membership",
    value: "I'm not getting any value from my membership",
  },
  { label: 'I have a privacy concern', value: 'I have a privacy concern' },
  {
    label: "I'm receiving unwanted contacts",
    value: "I'm receiving unwanted contacts",
  },
  { label: 'Other', value: 'other' },
];

const CURRENCY = [
  {
    symbol: '$',
    label: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'USD',
    name_plural: 'US dollars',
  },
  {
    symbol: 'CA$',
    label: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'CAD',
    name_plural: 'Canadian dollars',
  },
  {
    symbol: '€',
    label: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    value: 'EUR',
    name_plural: 'euros',
  },
  {
    symbol: 'AED',
    label: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'AED',
    name_plural: 'UAE dirhams',
  },
  {
    symbol: 'Af',
    label: 'Afghan Afghani',
    symbol_native: '؋',
    decimal_digits: 0,
    rounding: 0,
    value: 'AFN',
    name_plural: 'Afghan Afghanis',
  },
  {
    symbol: 'ALL',
    label: 'Albanian Lek',
    symbol_native: 'Lek',
    decimal_digits: 0,
    rounding: 0,
    value: 'ALL',
    name_plural: 'Albanian lekë',
  },
  {
    symbol: 'AMD',
    label: 'Armenian Dram',
    symbol_native: 'դր.',
    decimal_digits: 0,
    rounding: 0,
    value: 'AMD',
    name_plural: 'Armenian drams',
  },
  {
    symbol: 'AR$',
    label: 'Argentine Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'ARS',
    name_plural: 'Argentine pesos',
  },
  {
    symbol: 'AU$',
    label: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'AUD',
    name_plural: 'Australian dollars',
  },
  {
    symbol: 'man.',
    label: 'Azerbaijani Manat',
    symbol_native: 'ман.',
    decimal_digits: 2,
    rounding: 0,
    value: 'AZN',
    name_plural: 'Azerbaijani manats',
  },
  {
    symbol: 'KM',
    label: 'Bosnia-Herzegovina Convertible Mark',
    symbol_native: 'KM',
    decimal_digits: 2,
    rounding: 0,
    value: 'BAM',
    name_plural: 'Bosnia-Herzegovina convertible marks',
  },
  {
    symbol: 'Tk',
    label: 'Bangladeshi Taka',
    symbol_native: '৳',
    decimal_digits: 2,
    rounding: 0,
    value: 'BDT',
    name_plural: 'Bangladeshi takas',
  },
  {
    symbol: 'BGN',
    label: 'Bulgarian Lev',
    symbol_native: 'лв.',
    decimal_digits: 2,
    rounding: 0,
    value: 'BGN',
    name_plural: 'Bulgarian leva',
  },
  {
    symbol: 'BD',
    label: 'Bahraini Dinar',
    symbol_native: 'د.ب.‏',
    decimal_digits: 3,
    rounding: 0,
    value: 'BHD',
    name_plural: 'Bahraini dinars',
  },
  {
    symbol: 'FBu',
    label: 'Burundian Franc',
    symbol_native: 'FBu',
    decimal_digits: 0,
    rounding: 0,
    value: 'BIF',
    name_plural: 'Burundian francs',
  },
  {
    symbol: 'BN$',
    label: 'Brunei Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BND',
    name_plural: 'Brunei dollars',
  },
  {
    symbol: 'Bs',
    label: 'Bolivian Boliviano',
    symbol_native: 'Bs',
    decimal_digits: 2,
    rounding: 0,
    value: 'BOB',
    name_plural: 'Bolivian bolivianos',
  },
  {
    symbol: 'R$',
    label: 'Brazilian Real',
    symbol_native: 'R$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BRL',
    name_plural: 'Brazilian reals',
  },
  {
    symbol: 'BWP',
    label: 'Botswanan Pula',
    symbol_native: 'P',
    decimal_digits: 2,
    rounding: 0,
    value: 'BWP',
    name_plural: 'Botswanan pulas',
  },
  {
    symbol: 'Br',
    label: 'Belarusian Ruble',
    symbol_native: 'руб.',
    decimal_digits: 2,
    rounding: 0,
    value: 'BYN',
    name_plural: 'Belarusian rubles',
  },
  {
    symbol: 'BZ$',
    label: 'Belize Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BZD',
    name_plural: 'Belize dollars',
  },
  {
    symbol: 'CDF',
    label: 'Congolese Franc',
    symbol_native: 'FrCD',
    decimal_digits: 2,
    rounding: 0,
    value: 'CDF',
    name_plural: 'Congolese francs',
  },
  {
    symbol: 'CHF',
    label: 'Swiss Franc',
    symbol_native: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    value: 'CHF',
    name_plural: 'Swiss francs',
  },
  {
    symbol: 'CL$',
    label: 'Chilean Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    value: 'CLP',
    name_plural: 'Chilean pesos',
  },
  {
    symbol: 'CN¥',
    label: 'Chinese Yuan',
    symbol_native: 'CN¥',
    decimal_digits: 2,
    rounding: 0,
    value: 'CNY',
    name_plural: 'Chinese yuan',
  },
  {
    symbol: 'CO$',
    label: 'Colombian Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    value: 'COP',
    name_plural: 'Colombian pesos',
  },
  {
    symbol: '₡',
    label: 'Costa Rican Colón',
    symbol_native: '₡',
    decimal_digits: 0,
    rounding: 0,
    value: 'CRC',
    name_plural: 'Costa Rican colóns',
  },
  {
    symbol: 'CV$',
    label: 'Cape Verdean Escudo',
    symbol_native: 'CV$',
    decimal_digits: 2,
    rounding: 0,
    value: 'CVE',
    name_plural: 'Cape Verdean escudos',
  },
  {
    symbol: 'Kč',
    label: 'Czech Republic Koruna',
    symbol_native: 'Kč',
    decimal_digits: 2,
    rounding: 0,
    value: 'CZK',
    name_plural: 'Czech Republic korunas',
  },
  {
    symbol: 'Fdj',
    label: 'Djiboutian Franc',
    symbol_native: 'Fdj',
    decimal_digits: 0,
    rounding: 0,
    value: 'DJF',
    name_plural: 'Djiboutian francs',
  },
  {
    symbol: 'Dkr',
    label: 'Danish Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    value: 'DKK',
    name_plural: 'Danish kroner',
  },
  {
    symbol: 'RD$',
    label: 'Dominican Peso',
    symbol_native: 'RD$',
    decimal_digits: 2,
    rounding: 0,
    value: 'DOP',
    name_plural: 'Dominican pesos',
  },
  {
    symbol: 'DA',
    label: 'Algerian Dinar',
    symbol_native: 'د.ج.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'DZD',
    name_plural: 'Algerian dinars',
  },
  {
    symbol: 'Ekr',
    label: 'Estonian Kroon',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    value: 'EEK',
    name_plural: 'Estonian kroons',
  },
  {
    symbol: 'EGP',
    label: 'Egyptian Pound',
    symbol_native: 'ج.م.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'EGP',
    name_plural: 'Egyptian pounds',
  },
  {
    symbol: 'Nfk',
    label: 'Eritrean Nakfa',
    symbol_native: 'Nfk',
    decimal_digits: 2,
    rounding: 0,
    value: 'ERN',
    name_plural: 'Eritrean nakfas',
  },
  {
    symbol: 'Br',
    label: 'Ethiopian Birr',
    symbol_native: 'Br',
    decimal_digits: 2,
    rounding: 0,
    value: 'ETB',
    name_plural: 'Ethiopian birrs',
  },
  {
    symbol: '£',
    label: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    value: 'GBP',
    name_plural: 'British pounds sterling',
  },
  {
    symbol: 'GEL',
    label: 'Georgian Lari',
    symbol_native: 'GEL',
    decimal_digits: 2,
    rounding: 0,
    value: 'GEL',
    name_plural: 'Georgian laris',
  },
  {
    symbol: 'GH₵',
    label: 'Ghanaian Cedi',
    symbol_native: 'GH₵',
    decimal_digits: 2,
    rounding: 0,
    value: 'GHS',
    name_plural: 'Ghanaian cedis',
  },
  {
    symbol: 'FG',
    label: 'Guinean Franc',
    symbol_native: 'FG',
    decimal_digits: 0,
    rounding: 0,
    value: 'GNF',
    name_plural: 'Guinean francs',
  },
  {
    symbol: 'GTQ',
    label: 'Guatemalan Quetzal',
    symbol_native: 'Q',
    decimal_digits: 2,
    rounding: 0,
    value: 'GTQ',
    name_plural: 'Guatemalan quetzals',
  },
  {
    symbol: 'HK$',
    label: 'Hong Kong Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'HKD',
    name_plural: 'Hong Kong dollars',
  },
  {
    symbol: 'HNL',
    label: 'Honduran Lempira',
    symbol_native: 'L',
    decimal_digits: 2,
    rounding: 0,
    value: 'HNL',
    name_plural: 'Honduran lempiras',
  },
];

const COUNTRY = [
  {
    id: 'ABW',
    iso2Code: 'AW',
    name: 'Aruba',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Oranjestad',
    longitude: '-70.0167',
    latitude: '12.5167',
  },
  {
    id: 'AFE',
    iso2Code: 'ZH',
    name: 'Africa Eastern and Southern',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFG',
    iso2Code: 'AF',
    name: 'Afghanistan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kabul',
    longitude: '69.1761',
    latitude: '34.5228',
  },
  {
    id: 'AFR',
    iso2Code: 'A9',
    name: 'Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFW',
    iso2Code: 'ZI',
    name: 'Africa Western and Central',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AGO',
    iso2Code: 'AO',
    name: 'Angola',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Luanda',
    longitude: '13.242',
    latitude: '-8.81155',
  },
  {
    id: 'ALB',
    iso2Code: 'AL',
    name: 'Albania',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tirane',
    longitude: '19.8172',
    latitude: '41.3317',
  },
  {
    id: 'AND',
    iso2Code: 'AD',
    name: 'Andorra',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Andorra la Vella',
    longitude: '1.5218',
    latitude: '42.5075',
  },
  {
    id: 'ARB',
    iso2Code: '1A',
    name: 'Arab World',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ARE',
    iso2Code: 'AE',
    name: 'United Arab Emirates',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Abu Dhabi',
    longitude: '54.3705',
    latitude: '24.4764',
  },
  {
    id: 'ARG',
    iso2Code: 'AR',
    name: 'Argentina',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Buenos Aires',
    longitude: '-58.4173',
    latitude: '-34.6118',
  },
  {
    id: 'ARM',
    iso2Code: 'AM',
    name: 'Armenia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Yerevan',
    longitude: '44.509',
    latitude: '40.1596',
  },
  {
    id: 'ASM',
    iso2Code: 'AS',
    name: 'American Samoa',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Pago Pago',
    longitude: '-170.691',
    latitude: '-14.2846',
  },
  {
    id: 'ATG',
    iso2Code: 'AG',
    name: 'Antigua and Barbuda',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: "Saint John's",
    longitude: '-61.8456',
    latitude: '17.1175',
  },
  {
    id: 'AUS',
    iso2Code: 'AU',
    name: 'Australia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Canberra',
    longitude: '149.129',
    latitude: '-35.282',
  },
  {
    id: 'AUT',
    iso2Code: 'AT',
    name: 'Austria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Vienna',
    longitude: '16.3798',
    latitude: '48.2201',
  },
  {
    id: 'AZE',
    iso2Code: 'AZ',
    name: 'Azerbaijan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Baku',
    longitude: '49.8932',
    latitude: '40.3834',
  },
  {
    id: 'BDI',
    iso2Code: 'BI',
    name: 'Burundi',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bujumbura',
    longitude: '29.3639',
    latitude: '-3.3784',
  },
  {
    id: 'BEA',
    iso2Code: 'B4',
    name: 'East Asia & Pacific (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEC',
    iso2Code: 'B7',
    name: 'Europe & Central Asia (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEL',
    iso2Code: 'BE',
    name: 'Belgium',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Brussels',
    longitude: '4.36761',
    latitude: '50.8371',
  },
  {
    id: 'BEN',
    iso2Code: 'BJ',
    name: 'Benin',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Porto-Novo',
    longitude: '2.6323',
    latitude: '6.4779',
  },
  {
    id: 'BFA',
    iso2Code: 'BF',
    name: 'Burkina Faso',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Ouagadougou',
    longitude: '-1.53395',
    latitude: '12.3605',
  },
  {
    id: 'BGD',
    iso2Code: 'BD',
    name: 'Bangladesh',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dhaka',
    longitude: '90.4113',
    latitude: '23.7055',
  },
  {
    id: 'BGR',
    iso2Code: 'BG',
    name: 'Bulgaria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sofia',
    longitude: '23.3238',
    latitude: '42.7105',
  },
  {
    id: 'BHI',
    iso2Code: 'B1',
    name: 'IBRD countries classified as high income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BHR',
    iso2Code: 'BH',
    name: 'Bahrain',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Manama',
    longitude: '50.5354',
    latitude: '26.1921',
  },
  {
    id: 'BHS',
    iso2Code: 'BS',
    name: 'Bahamas, The',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Nassau',
    longitude: '-77.339',
    latitude: '25.0661',
  },
  {
    id: 'BIH',
    iso2Code: 'BA',
    name: 'Bosnia and Herzegovina',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sarajevo',
    longitude: '18.4214',
    latitude: '43.8607',
  },
  {
    id: 'BLA',
    iso2Code: 'B2',
    name: 'Latin America & the Caribbean (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BLR',
    iso2Code: 'BY',
    name: 'Belarus',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Minsk',
    longitude: '27.5766',
    latitude: '53.9678',
  },
  {
    id: 'BLZ',
    iso2Code: 'BZ',
    name: 'Belize',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Belmopan',
    longitude: '-88.7713',
    latitude: '17.2534',
  },
  {
    id: 'BMN',
    iso2Code: 'B3',
    name: 'Middle East & North Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BMU',
    iso2Code: 'BM',
    name: 'Bermuda',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Hamilton',
    longitude: '-64.706',
    latitude: '32.3293',
  },
  {
    id: 'BOL',
    iso2Code: 'BO',
    name: 'Bolivia',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'La Paz',
    longitude: '-66.1936',
    latitude: '-13.9908',
  },
  {
    id: 'BRA',
    iso2Code: 'BR',
    name: 'Brazil',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Brasilia',
    longitude: '-47.9292',
    latitude: '-15.7801',
  },
  {
    id: 'BRB',
    iso2Code: 'BB',
    name: 'Barbados',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bridgetown',
    longitude: '-59.6105',
    latitude: '13.0935',
  },
  {
    id: 'BRN',
    iso2Code: 'BN',
    name: 'Brunei Darussalam',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bandar Seri Begawan',
    longitude: '114.946',
    latitude: '4.94199',
  },
  {
    id: 'BSS',
    iso2Code: 'B6',
    name: 'Sub-Saharan Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BTN',
    iso2Code: 'BT',
    name: 'Bhutan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Thimphu',
    longitude: '89.6177',
    latitude: '27.5768',
  },
  {
    id: 'BWA',
    iso2Code: 'BW',
    name: 'Botswana',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Gaborone',
    longitude: '25.9201',
    latitude: '-24.6544',
  },
  {
    id: 'CAA',
    iso2Code: 'C9',
    name: 'Sub-Saharan Africa (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CAF',
    iso2Code: 'CF',
    name: 'Central African Republic',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bangui',
    longitude: '21.6407',
    latitude: '5.63056',
  },
  {
    id: 'CAN',
    iso2Code: 'CA',
    name: 'Canada',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Ottawa',
    longitude: '-75.6919',
    latitude: '45.4215',
  },
  {
    id: 'CEA',
    iso2Code: 'C4',
    name: 'East Asia and the Pacific (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEB',
    iso2Code: 'B8',
    name: 'Central Europe and the Baltics',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEU',
    iso2Code: 'C5',
    name: 'Europe and Central Asia (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHE',
    iso2Code: 'CH',
    name: 'Switzerland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bern',
    longitude: '7.44821',
    latitude: '46.948',
  },
  {
    id: 'CHI',
    iso2Code: 'JG',
    name: 'Channel Islands',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHL',
    iso2Code: 'CL',
    name: 'Chile',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Santiago',
    longitude: '-70.6475',
    latitude: '-33.475',
  },
];

const COMPANY_TYPE = [
  { label: 'Private', value: 'Private' },
  { label: 'Public', value: 'Public' },
  { label: 'Goverment', value: 'Goverment' },
  { label: 'Private Limited', value: 'Private_Limited' },
  { label: 'Associate Companies', value: 'Associate_Companies' },
];

const COMPANY_TIMING_TYPE = [
  { value: 'NO', label: 'No Hours Available' },
  { value: 'AO', label: 'Always Open' },
  { value: 'PC', label: 'Permanently Closed' },
  { value: 'TC', label: 'Temporarily Closed' },
  { value: 'O', label: 'Open on selected hours' },
];

const COMPANY_SIZE = [
  { label: '0 to 15', value: 5 },
  { label: '15 TO 50', value: 50 },
  { label: '50 TO 100', value: 100 },
  { label: '100 TO 500', value: 500 },
  { label: '500 TO 1000', value: 1000 },
];

const WORKPLACE_TYPE = [
  { label: 'On-site', value: 'N' ,translationKey:"createJob.onSite"},
  { label: 'Hybrid', value: 'HYBRID' ,translationKey:"createJob.hybrid"},
  { label: 'Remote', value: 'Y' ,translationKey:"createJob.remote"},
];

const EXP_LEVEL = [
  { label: 'Internship', value: 'INTERNSHIP',translationKey:"createJob.internship" },
  { label: 'Entry level', value: 'ENTRYLEVEL' ,translationKey:"createJob.entryLevel"},
  { label: 'Associate', value: 'ASSOCIATE', translationKey:"createJob.associate"},
  { label: 'Mid-Senior level', value: 'MIDSENIORLEVEL',translationKey:"createJob.midSeniorLevel" },
  { label: 'Director', value: 'DIRECTOR', translationKey:"createJob.director"},
  { label: 'Executive', value: 'EXECUTIVE',translationKey:"createJob.executive"},
];

const PAGE_NUMBER = [
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];

const BULK_ACTION = [
  { label: 'Delete All', value: 'Delete All' },
  { label: 'Send Mail', value: 'Send Mail' },
];

const SHAREOPTION = [
  { label: 'Can Edit', value: 1 },
  { label: 'Can View', value: 2 },
];

const MEETING_LOCATION = [
  { label: 'Built-in Audio / Video Conferencing', value: 'builtin' },
  { label: 'Provide a location', value: 'provide' },
];
const TIME_FORMATE = [
  { label: '12:00 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '01:00 AM', value: '01:00' },
  { label: '01:30 AM', value: '01:30' },
  { label: '02:00 AM', value: '02:00' },
  { label: '02:30 AM', value: '02:30' },
  { label: '03:00 AM', value: '03:00' },
  { label: '03:30 AM', value: '03:30' },
  { label: '04:00 AM', value: '04:00' },
  { label: '04:30 AM', value: '04:30' },
  { label: '05:00 AM', value: '05:00' },
  { label: '05:30 AM', value: '05:30' },
  { label: '06:00 AM', value: '06:00' },
  { label: '06:30 AM', value: '06:30' },
  { label: '07:00 AM', value: '07:00' },
  { label: '07:30 AM', value: '07:30' },
  { label: '08:00 AM', value: '08:00' },
  { label: '08:30 AM', value: '08:30' },
  { label: '09:00 AM', value: '09:00' },
  { label: '09:30 AM', value: '09:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '01:00 PM', value: '13:00' },
  { label: '01:30 PM', value: '13:30' },
  { label: '02:00 PM', value: '14:00' },
  { label: '02:30 PM', value: '14:30' },
  { label: '03:00 PM', value: '15:00' },
  { label: '03:30 PM', value: '15:30' },
  { label: '04:00 PM', value: '16:00' },
  { label: '04:30 PM', value: '16:30' },
  { label: '05:00 PM', value: '17:00' },
  { label: '05:30 PM', value: '17:30' },
  { label: '06:00 PM', value: '18:00' },
  { label: '06:30 PM', value: '18:30' },
  { label: '07:00 PM', value: '19:00' },
  { label: '07:30 PM', value: '19:30' },
  { label: '08:00 PM', value: '20:00' },
  { label: '08:30 PM', value: '20:30' },
  { label: '09:00 PM', value: '21:00' },
  { label: '09:30 PM', value: '21:30' },
  { label: '10:00 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11:00 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' },
];

const TIME_DURATION = [
  { label: '0 hr', value: '0' },
  { label: '1 hr', value: '1' },
  { label: '2 hr', value: '2' },
  { label: '3 hr', value: '3' },
  { label: '4 hr', value: '4' },
  { label: '5 hr', value: '5' },
  { label: '6 hr', value: '6' },
  { label: '7 hr', value: '7' },
  { label: '8 hr', value: '8' },
  { label: '9 hr', value: '9' },
  { label: '10 hr', value: '10' },
  { label: '11 hr', value: '11' },
  { label: '12 hr', value: '12' },
];

const MINUTES_DURATION = [
  { label: '00 min', value: '0' },
  { label: '15 min', value: '15' },
  { label: '30 min', value: '30' },
  { label: '45 min', value: '45' },
];

// apply|download|view_quote|learn_more|sign_up|subscribe|register|attend|request_demo
const CALL_TO_ACTION = [
  { label: 'Apply', value: 'apply' },
  { label: 'Download', value: 'download' },
  { label: 'View Quote', value: 'view_quote' },
  { label: 'Learn more', value: 'learn_more' },
  { label: 'Sign Up', value: 'sign_up' },
  { label: 'Subscribe', value: 'subscribe' },
  { label: 'Register', value: 'register' },
  { label: 'Attend', value: 'attend' },
  { label: 'Request Demo', value: 'request_demo' },
];

export {
  months,
  genralMonths,
  causesYouCare,
  EMPLOYMENT_CHOICES,
  LANG_CHOICES,
  COUNTRY_CODE,
  BOOL_CHOICE,
  DEACT_REASONS,
  CURRENCY,
  PAYMENT_TYPE,
  BENEFITS,
  COUNTRY,
  COMPANY_TYPE,
  COMPANY_SIZE,
  COMPANY_TIMING_TYPE,
  WORKPLACE_TYPE,
  SHAREOPTION,
  MEETING_LOCATION,
  TIME_FORMATE,
  PAGE_NUMBER,
  BULK_ACTION,
  EXP_LEVEL,
  TIME_DURATION,
  MINUTES_DURATION,
  genderData,
  pronounsData,
  CALL_TO_ACTION
};
