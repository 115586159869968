import { ChevronRightBulletIcon } from '@mybridge/icons/ChevronRightBullet';
import { Button } from '@mybridge/ui/button';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { getUserFullName } from 'v4/lib/commons';

export const NetworkBlockUserModal = ({ ...props }) => {
  const { selectedUser, blockUserLoading, blockUser } =
    useContext(MyNetworkContext);
  const { t } = useTranslator();
  const blockCons = useMemo(() => [
    {
      title: t('reportBlock.seeYourPostsOnTimeline') || 'See your posts on your timeline',
    },
    {
      title: t('reportBlock.tagYou') || 'Tag you',
    },
    {
      title: t('reportBlock.inviteYouToEventsOrGroups') || 'Invite you to events or groups',
    },
    {
      title: t('reportBlock.messageYou') || 'Message you',
    },
    {
      title: t('reportBlock.addYouAsAFriend') || 'Add you as a friend',
    },
    {
      title: `${t('reportBlock.blockingWillUnfriendPart1') || "If you're friends, blocking"} ${getUserFullName(selectedUser)} ${t('reportBlock.blockingWillUnfriendPart2') || "will also unfriend her."}`,
    },
    {
      title:
        `${t('reportBlock.limitSharing') || "If you want to limit what you share with"} ${getUserFullName(selectedUser)}, ${t('reportBlock.seeLessOfThem') || "or see less of them, you can choose to take a break from them."}`,
    },
  ],[selectedUser]);
  const handleBlockUser = () => {
    blockUser(selectedUser);
  };

  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            {t('profileNetwork.block') || "Block"} {getUserFullName(selectedUser)}
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>
                  {selectedUser?.first_name} {t('reportBlock.willNoLongerBeAbleTo') || "will no longer be able to"}:
                </Text>
              </Stack>

              <Stack spacing={4}>
                {blockCons?.map?.((brr, index) => (
                  <HStack key={index} alignItems="flex-start">
                    <Box flexShrink={0} pt={1}>
                      <ChevronRightBulletIcon />
                    </Box>
                    <Text>{brr?.title}</Text>
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                {t('buttons.cancel') || "Cancel"}
              </Button>
              <Button
                onClick={handleBlockUser}
                variant="primary"
                isLoading={blockUserLoading}
              >
                {t('profileNetwork.block') || "Block"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
