import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDashboardClicked: false,
};

const dashboardActiveSlice = createSlice({
  name: 'dashboardActive',
  initialState,
  reducers: {
    toggleDashboardClicked: (state) => {
      state.isDashboardClicked = !state.isDashboardClicked;
    },
    setDashboardClicked: (state, action) => {
      state.isDashboardClicked = action.payload;
    },
  },
});

export const { toggleDashboardClicked, setDashboardClicked } =
  dashboardActiveSlice.actions;

export default dashboardActiveSlice.reducer;
