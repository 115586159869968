import { Text } from '@mybridge/ui';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import ImageUploader from 'v4/components/image-uploader';

export const ImageUploadModal = ({
  cropType,
  uploadCropedImg,
  handlePopups,
  ratio,
  ...props
}) => {
  const { t } = useTranslator();
  return (
    <Modal size="xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('myDisk.upload') || "Upload"}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <ImageUploader
            cropType={cropType}
            uploadCropedImg={(val) => {
              uploadCropedImg?.(val);
              props?.onClose?.();
            }}
            onProfilePostData={()=>{<></>}}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
