export default async function API({
  url,
  method = 'GET',
  body = '',
  isFormData = false,
  isNoToken = false,
  headers,
  isResponseJSON = true,
  external = false,
  caching = false
}) {
  let options = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };
   // Get user language from localStorage, default to 'en' if not found
  //  const userLanguage = localStorage.getItem('userLanguage') || 'en';
  let userLanguage = 'en';
  if (typeof window !== 'undefined') {
    // We're in the browser
    userLanguage = localStorage.getItem('userLanguage') || 'en';
  }
   // Add Accept-Language header with the user's selected language
   options.headers['Accept-Language'] = userLanguage;
  if (!isNoToken) {
    options.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('access');
  }
  if (body) {
    options.body = body;
  }
  if (!isFormData) {
    options.headers['content-type'] = 'application/json; charset=UTF-8';
  }
  if (headers) {
    options.headers['content-type'] =
      'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e';
  }
  if (caching) {
    options.headers['Cache-Control'] =
    "public, max-age=86400";
  }

  const res = await fetch(
    `${external ? '' : process.env.BASE_API_URL}${url}`,
    options
  );
  if (isResponseJSON) {
    return await res.json();
  }
  return res;
}
