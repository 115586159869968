import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNotificationClicked: false,
};

const notificationActiveSlice = createSlice({
  name: 'notificationActive',
  initialState,
  reducers: {
    toggleNotificationClicked: (state) => {
      state.isNotificationClicked = !state.isNotificationClicked;
    },
    setNotificationClicked: (state, action) => {
      state.isNotificationClicked = action.payload;
    },
  },
});

export const { toggleNotificationClicked, setNotificationClicked } =
  notificationActiveSlice.actions;

export default notificationActiveSlice.reducer;