import { createReducer } from '@reduxjs/toolkit';
import { getCurrentDayEvent, getAllMeetings, getEventsFromGoogle, getEventsFromOutlook, getAllEvent, fetchingAllCalendarEvents, getUpcomingEvents, getMonthlyEvents, getAppointmentsList, setIsPublicAppointment,setIsSelectedEvent } from '../actions/calendar.actions';

const initialState = {
  data: [],
  allCalendarEvents: [],
  loading: false,
  loadingMonthlyView: false,
  upcomingEvents: [],
  monthlyEvents: [],
  weeklyEvents: [],
  dailyEvents: [],
  meetingSubject:[],
  allCalendarMeeting:[],
  allGoogleMeetings:[],
  allOutlookMeetings:[],
  loadingAppointmentView:false,
  appointmentsList:[],
  isPublicAppointment:false,
  isSelectedEvent:{}
};

export const calendarReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(getCurrentDayEvent.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCurrentDayEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(getCurrentDayEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getAllMeetings.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllMeetings.fulfilled, (state, action) => {
      state.loading = false;
      state.allCalendarMeeting = action.payload;
    })
    .addCase(getAllMeetings.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getEventsFromGoogle.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getEventsFromGoogle.fulfilled, (state, action) => {
      state.loading = false;
      state.allGoogleMeetings = action.payload;
    })
    .addCase(getEventsFromGoogle.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getEventsFromOutlook.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getEventsFromOutlook.fulfilled, (state, action) => {
      state.loading = false;
      state.allOutlookMeetings = action.payload;
    })
    .addCase(getEventsFromOutlook.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getAllEvent.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.allCalendarEvents = action.payload;
    })
    .addCase(getAllEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(fetchingAllCalendarEvents.pending, (state, action) => {
      state.loading = false;
    })
    .addCase(fetchingAllCalendarEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.allCalendarEvents = action.payload;
    })
    .addCase(fetchingAllCalendarEvents.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getUpcomingEvents.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getUpcomingEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.upcomingEvents = action.payload;
    })
    .addCase(getUpcomingEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getMonthlyEvents.pending, (state, action) => {
      state.loadingMonthlyView = true;
    })
    .addCase(getMonthlyEvents.fulfilled, (state, action) => {
      state.loadingMonthlyView = false;
      state.monthlyEvents = action.payload;
    })
    .addCase(getMonthlyEvents.rejected, (state, action) => {
      state.loadingMonthlyView = false;
      state.error = 'Try again later or contact customer support';
    })
    // appointments
    .addCase(getAppointmentsList.pending, (state, action) => {
      state.loadingAppointmentView = true;
    })
    .addCase(getAppointmentsList.fulfilled, (state, action) => {
      state.loadingAppointmentView = false;
      state.appointmentsList = action.payload;
    })
    .addCase(getAppointmentsList.rejected, (state, action) => {
      state.loadingAppointmentView = false;
      state.error = 'Try again later or contact customer support';
    }).addCase(setIsPublicAppointment, (state, action) => {
      state.isPublicAppointment = action.payload;
    }).addCase(setIsSelectedEvent, (state, action) => {
      state.isSelectedEvent = action.payload;
    })

    
});
