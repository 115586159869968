/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { Text } from '@mybridge/ui/text';
import { FormControl, FormLabel } from '@mybridge/ui/form-control';
import { Heading } from '@mybridge/ui/heading';
import { Input } from '@mybridge/ui/input';
import {
  Container,
  HStack,
  Stack,
  VStack,
  Wrap,
  WrapItem,
  Box,
} from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MultiStepFormContext } from 'v4/components/multi-step-form/context';
import { useLocation } from 'v4/lib/hooks/use-location';
import { useToast } from '@mybridge/ui/hooks';
import {
  getOnboardingData,
  setOnboardingData,
} from 'v4/store/actions/onboarding.actions';
import { useRouter } from 'next/router';
import { MyNetworkContext } from 'v4/components/network/context';
import { Avatar, Card, CardBody, Scrollable } from '@mybridge/ui';
import { getUserExperience, getUserFullName } from 'v4/lib/commons';
import { HandShakeSimpleIcon } from '@mybridge/icons';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { MyNetworkSuggestionsListing } from 'v4/snippets/profile/manage-network/suggestions';
import { AuthenticatedProviders } from 'v4/snippets/providers/authenticated';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const UserOnboardingFormConnectStep = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { next, back } = useContext(MultiStepFormContext);
  const {
    suggestions,
    allSuggestions,
    refetchSuggestions,
    suggestionsIsFetching,
    suggestionsIsLoading,
    isFollowed,
    isFriend,
    followUser,
    handshakeUser,
    handshakeLoading,
    followLoading,
    isHandshaked,
    setSelectedUser,
  } = useContext(MyNetworkContext);
  const toast = useToast({ position: 'top' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslator();

  // useEffect(() => {
  //   refetchSuggestions?.();
  // }, []);

  const save = async (form) => {
    try {
      setLoading(true);
      const payload = {
        step: 'finish',
        emailId: userProfileInfo?.email,
      };
      const resp = await dispatch(setOnboardingData(payload));
      return true;
    } catch (e) {
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onNext = async (form) => {
    const done = await save(form);
    if (done) {
      toast({
        title: `${t('onboarding.welcomeTo') || "Welcome to myBridge"}, ${userProfileInfo?.first_name}!`,
        status: 'success',
      });
      // onClose?.();
      router.push('/');
    }
  };

  return (
    <Container maxW="1280px">
      <Stack>
        <Stack textAlign="center" alignItems="center">
          <Heading
            size="sm"
            as="h1"
            fontSize="20px"
            color="#3D5A80"
            fontWeight="400"
            mb={4}
          >
            {t('onboarding.connectWithPeople') || "Connect with people you know by extending a virtual handshake. Follow those you want to get to know better."}
          </Heading>
          <Text fontWeight="400" fontSize="16px" color="#868585">
            {t('onboarding.followRecommendation') || "We recommend following at least 5 people to help you begin to network."}
          </Text>
        </Stack>
        {/* {suggestionsIsLoading ? (
            <SectionLoader />
          ) : ( */}
        <Box maxH="100%" overflow="auto">
          <MyNetworkSuggestionsListing fromOnboarding={true}/>
        </Box>
        {/* )} */}

        <Stack spacing={4} alignItems="flex-end" w="100%" py={5}>
          <Button borderRadius="30px" height="48px" minW="200px"  variant="primary" onClick={() => onNext()}>
            {t('onboarding.finish') || "Finish"}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
