import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const getCurrentDayEvent = createAsyncThunk('getCurrentDayEvent', async (body) => {
    const obj = {
      url: APIEndPoints.TodayEvents(body),
    };
    return await API(obj);
  });
  
  export const getUpcomingEvents = createAsyncThunk('getUpcomingEvents', async (body) => {
    const obj = {
      url: APIEndPoints.UpcomingEvents(body.date),
    };
    return await API(obj);
  });
  
  export const getAllMeetings = createAsyncThunk('getAllMeetings', async () => {
    const obj = {
      url: APIEndPoints.AllMeetings,
    };
    return await API(obj);
  });
  
  export const getAllEvent = createAsyncThunk('getAllEvent', async () => {
    const obj = {
      url: APIEndPoints.AllEvents,
    };
    return await API(obj);
  });
  
  export const fetchingAllCalendarEvents = createAsyncThunk('fetchingAllCalendarEvents', async (body) => {
    const obj = {
      url: APIEndPoints.fetchingAllCalendarEvents(body),
    };
    return await API(obj);
  });
  
  export const getMonthlyEvents = createAsyncThunk('monthlyCalendarEvents', async ({month, year}) => {
    const obj = {
      url: APIEndPoints.monthlyCalendarEvents(month, year)
    }
    return await API(obj);
  })
  
  export const getEventsFromGoogle = createAsyncThunk('getEventsFromGoogle', async (body) => {
    const obj = {
      url: APIEndPoints.GOOGLE_EVENT,
    };
    return await API(obj);
  })
  export const getEventsFromOutlook = createAsyncThunk('getEventsFromOutlook', async (body) => {
    const obj = {
      url: APIEndPoints.OUTLOOK_EVENT,
    };
    return await API(obj);
  })

  // Appointments

  export const getAppointmentsList = createAsyncThunk('getAppointmentsList', async (body={}) => {
    try {
      const obj = {
        url: APIEndPoints.getAppointmentsList(body),
        method: 'GET',
        isNoToken: body.slug? true:false,
      };
      console.log(body,obj,"bodybody")
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

  export const createAppointments = createAsyncThunk('createAppointments', async (payload) => {
    try {
      const obj = {
        url: APIEndPoints.createAppointments,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

  export const checkAppointmentSlug = createAsyncThunk('checkAppointmentSlug', async (body) => {
    try {
      const obj = {
        url: APIEndPoints.checkAppointmentSlug(body),
        method: 'GET',
        isNoToken:true,

      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

  export const updateAppointments = createAsyncThunk('updateAppointments', async (payload) => {
    try {
      const obj = {
        url: APIEndPoints.updateAppointments(payload),
        method: 'PATCH',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });
  export const cloneAppointment = createAsyncThunk('cloneAppointment', async (id) => {
    try {
      const obj = {
        url: APIEndPoints.cloneAppointment(id),
        method: 'POST',
       
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

  export const getConnectsionUserList = createAsyncThunk('getConnectsionUserList', async (body) => {
    try {
      const obj = {
        url: APIEndPoints.getConnectsionUserList(body),
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

  export const inviteConnectionsapp = createAsyncThunk('inviteConnectionsapp', async (body) => {
    console.log()
    try {
      const obj = {
        url: APIEndPoints.inviteConnectionsapp(body),
        method: 'POST',
        body:JSON.stringify(body)
       
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      console.log(error)

      return error;
    }
  });


  export const bookAppointment = createAsyncThunk('bookAppointment', async (body) => {
    try {
      const obj = {
        url: APIEndPoints.bookAppointment(body),
        method: 'POST',
        body:JSON.stringify(body),
        isNoToken:true
       
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      console.log(error)

      return error;
    }
  });

  export const cancelBooking = createAsyncThunk('cancelBooking', async (body) => {
    try {
      const obj = {
        url: APIEndPoints.cancelBooking(body),
        method: 'DELETE',
        isNoToken:true,
        isResponseJSON:false
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  });

export const setIsPublicAppointment = createAction('setIsPublicAppointment');
export const setIsSelectedEvent = createAction('setIsSelectedEvent');

