/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Avatar,
  VStack,
  HStack,
  Stack,
  Divider,
  InputLeftElement,
  InputGroup,
  CloseButton,
  Spinner,
  useToast,
  Icon,
  Spacer,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  checkAppointmentSlug,
  getConnectsionUserList,
  inviteConnectionsapp,
} from 'v4/store/actions/calendar.actions';
import { useDispatch, useSelector } from 'react-redux';
import { CopyIcon } from '@mybridge/icons/Copy';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

const ShareLinkModalPublic = ({ isOpen, onClose }) => {
  const { t } = useTranslator();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUser, setFilteredUser] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const { query } = useRouter();
  const [getAccountInfo, setGetAccountInfo] = useState([]);

  const dispatch = useDispatch('access');
  const { pageLoaded } = useSelector((state) => state.user);
  let loggedIn =
    typeof window !== 'undefined' && localStorage.getItem('chatAccess');
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const toast = useToast({ position: 'top' });

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 10,
  });
  const [hasMore, setHasMore] = useState(true);
  // Reset data when the modal is reopened or search term changes
  useEffect(() => {
    if (!isOpen) return;
    resetData();
  }, [isOpen, searchTerm]);

  const resetData = () => {
    setMetaData({ page: 1, page_size: 10 });
    setFilteredUser([]);
    setHasMore(true);
    handleGetConnections(true); // Fetch data with reset flag
  };

  const handleSelectUser = (user) => {
    if (selectedUsers.some((u) => u.id === user.id)) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleUnselectAll = () => {
    setSelectedUsers([]);
  };

  const handleGetConnections = async (reset = false) => {
    if (!hasMore && !reset) return; // Prevent fetching if no more data and not resetting
    if (!loggedIn) return;
    dispatch(
      getConnectsionUserList({
        page_size: metaData.page_size,
        page: reset ? 1 : metaData.page,
        search: searchTerm,
      })
    )
      .then((res) => {
        if (res?.payload?.results?.length < metaData.page_size) {
          setHasMore(false); // No more data to load
        }

        setFilteredUser((prevItems) =>
          reset
            ? res?.payload?.results || []
            : [...prevItems, ...(res?.payload?.results || [])]
        );

        setMetaData((prevMetaData) => ({
          ...prevMetaData,
          page: reset ? 2 : prevMetaData.page + 1, // Correct page increment
        }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setHasMore(false); // Stop further requests on error
      });
  };

  useEffect(() => {
    if (!isOpen) return;
    handleGetConnections();
  }, [isOpen]);

  const isSelected = (user) => selectedUsers.some((u) => u.id === user.id);

  const handleInviteConnections = async () => {
    let selected = selectedUsers.map((user) => user?.id);
    let payload = {
      user_slug: userProfileInfo?.appointment_slug,
      invited_users: selected,
      user_link: `${process.env.BASE_PUBLIC_URL}appointment/${userProfileInfo?.appointment_slug}`,
      appointment_link: `${process.env.BASE_PUBLIC_URL}appointment/${userProfileInfo?.appointment_slug}/${isOpen.clickedAppointment?.slug}`,
    };

    dispatch(inviteConnectionsapp({ ...payload })).then((res) => {
      toast({
        title: t('scheduling.inviteSent') || 'Invite sent Successfully',
        description: res.message,
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
      onClose(false);

      setFilteredUser([]);
      setSelectedUsers([]);
    });
  };
  const handleCopyLink = () => {
    const fullLink = query?.id
      ? `${process.env.BASE_PUBLIC_URL}appointment/${query.slug}/${query.id}`
      : `${process.env.BASE_PUBLIC_URL}appointment/${query.slug}`;
    navigator.clipboard.writeText(fullLink).then(
      () => {
        setIsCopied(true);
        toast({
          title: t('myCalendar.linkCopied') || 'Link copied to clipboard.',
          // description: fullLink,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Reset the copied state after 2 seconds
        setTimeout(() => setIsCopied(false), 2000);
      },
      (err) => {
        toast({
          title: t('myCalendar.failedToCopy') || 'Failed to copy the link.',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={!!loggedIn ? '707px' : '450px'}
        height={!!loggedIn ? 590 : 130}
      >
        <ModalHeader fontSize={22} color={'#3D5A80'} fontWeight={'normal'}>
          {t('scheduling.shareLinkConnections') || "Share link with connections"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!loggedIn && (
            <Box>
              <FormControl mb={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    paddingInline={10}
                    placeholder={t('pages.searchByName') || "Search by name"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    borderRadius={50}
                  />
                </InputGroup>
              </FormControl>
              <Divider />
              <Box display={'flex'} gap={4}>
                <Stack mt={4} align="start" spacing={4}>
                  <Box
                    height={350}
                    minWidth={'100%'}
                    overflowY={'auto'}
                    overflowX={'hidden'}
                  >
                    <InfiniteScroll
                      dataLength={filteredUser.length}
                      next={handleGetConnections}
                      hasMore={hasMore}
                      loader={
                        <HStack p={4} justifyContent="center" w="100%">
                          <Spinner />
                        </HStack>
                      }
                      height={'360px'}
                    >
                      {filteredUser.length > 0 ? (
                        filteredUser.map((user) => (
                          <HStack w={'337px'} p={2} key={user.id}>
                            <Checkbox
                              isChecked={isSelected(user)}
                              onChange={() => handleSelectUser(user)}
                            />
                            <Avatar
                              name={user?.first_name + user?.last_name}
                              size="sm"
                              src={user.profile_pic}
                            />
                            <Box>
                              <Text
                                fontSize={16}
                                color="#5B5B5B"
                                fontWeight="medium"
                              >
                                {user.first_name + ' ' + user?.last_name}
                              </Text>
                              <Text
                                fontSize={12}
                                color="#868585"
                                fontWeight="medium"
                              >
                                {user.position}
                              </Text>
                            </Box>
                          </HStack>
                        ))
                      ) : (
                        <HStack p={2} key={'dk'} w="300px">
                          <Text
                            fontSize={16}
                            color="#5B5B5B"
                            fontWeight="medium"
                          >
                            {t('scheduling.noUserFound') || "No User Found"}
                          </Text>
                        </HStack>
                      )}
                    </InfiniteScroll>
                  </Box>
                </Stack>
                {selectedUsers.length > 0 && (
                  <Box width={'41%'} mt={4}>
                    <HStack justifyContent={'space-between'}>
                      <Text
                        ml={2}
                        fontSize={15}
                        color="#868585"
                        fontWeight="medium"
                      >
                        {selectedUsers.length} {t('pages.selected') || "Selected"}:
                      </Text>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={handleUnselectAll}
                      >
                        {t('pages.unselectAll') || "Unselect all"}
                      </Button>
                    </HStack>
                    {selectedUsers.map((user) => (
                      <HStack
                        p={2}
                        maxW={'100%'}
                        w={'full'}
                        justifyContent={'space-between'}
                        key={user.id}
                      >
                        <Flex alignItems={'center'}>
                          <Avatar
                            name={user?.first_name + user?.last_name}
                            size="sm"
                            src={user.profile_pic}
                          />
                          <Text
                            ml={2}
                            fontSize={14}
                            color="#5B5B5B"
                            fontWeight="medium"
                          >
                            {user.first_name + ' ' + user?.last_name}
                          </Text>
                        </Flex>
                        <CloseButton onClick={() => handleSelectUser(user)} />
                      </HStack>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </ModalBody>

        <ModalFooter justifyContent={'space-between'}>
          <Flex
            cursor={'pointer'}
            align={'center'}
            onClick={handleCopyLink}
            justifyContent={'space-between'}
          >
            <HStack minW={!!loggedIn ? '650px' : '393px'}>
              <span style={{ fontSize: '12px' }}>
                {query?.id
                  ? `${process.env.BASE_PUBLIC_URL}appointment/${query.slug}/${query.id}`
                  : `${process.env.BASE_PUBLIC_URL}appointment/${query.slug}`}
              </span>
              <Icon as={CopyIcon} mr={2} />

              <Spacer />
              {loggedIn && (
                <>
                  <Button
                    disabled={true}
                    bg={'#3D5A80'}
                    color={'white'}
                    _hover={{
                      bg: '#3D5A80',
                    }}
                    onClick={handleInviteConnections}
                  >
                    {t('eventDashboard.invite') || "Invite"}{' '}
                    {selectedUsers.length > 0 ? selectedUsers.length : ''}
                  </Button>
                </>
              )}
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareLinkModalPublic;
