import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { TeamsContext } from '../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const TeamMakeOrganizerModal = ({ onClose, title }) => {
  const { t } = useTranslator();
  const {
    refetchTeamDetails,
    makeTeamOrganizer,
    team,
    selectedUser,
    teamDetails,
    makeTeamOrganizerLoading,
    makeOrganizerDisc,
  } = useContext(TeamsContext);

  return (
    <Modal isOpen={true} {...makeOrganizerDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text  width={"96%"} >{t('teamsPage.make') || "Make"} {getUserFullName(selectedUser)} {t('teams.organizer') || "Organizer"}?</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text display="inline">
              {getUserFullName(selectedUser)} {t('teamsPage.willHaveAccess') || "will have access to"} {teamDetails?.name??""} {t('teamsPage.teamResources') || "and any team resources within, including all files and conversations."}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => makeOrganizerDisc?.onClose?.()}
            >
              {t('buttons.cancel') || "Cancel"}
            </Button>
            <Button
              isLoading={makeTeamOrganizerLoading}
              variant="primary"
              colorScheme="danger"
              onClick={(e) => {
                makeTeamOrganizer?.(selectedUser)
                  .then(() => refetchTeamDetails?.())
              }}
            >
              {t('teamsPage.makeorganizer') || "Make Organizer"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
