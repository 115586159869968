/* eslint-disable @nx/enforce-module-boundaries */
import { Camera, DeleteIcon } from '@mybridge/icons';
import { GoogleIcon } from '@mybridge/icons/Google';
import { Avatar } from '@mybridge/ui/avatar';
import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import { useDisclosure } from '@mybridge/ui/hooks';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiStepFormContext } from 'v4/components/multi-step-form/context';
import { getUserFullName } from 'v4/lib/commons';
import { ImageUploadModal } from '../img-upload-modal';
import { fileToDataURL } from 'v4/lib/file';
import { Alert, AlertIcon } from '@mybridge/ui/alert';
import {
  getOnboardingData,
  setOnboardingData,
} from 'v4/store/actions/onboarding.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';
import { Text } from '@mybridge/ui';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const UserOnboardingFormProfilePicStep = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { next, back, setFields, formData } = useContext(MultiStepFormContext);
  const fullname = getUserFullName(userProfileInfo);
  const uploadDisc = useDisclosure();
  const [pic, setPic] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const  { t } = useTranslator();
  const uploadCropedImg = async (profilePic) => {
    setPic(await fileToDataURL(profilePic));
    setFields({ profilePic });
    uploadDisc?.onClose?.();
  };

  const removePic = () => {
    setFields({ profilePic: undefined });
    setPic(undefined);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } =
        (
          await dispatch(
            getOnboardingData({
              step: 'photo',
              emailId: userProfileInfo?.email,
            })
          )
        )?.payload ?? {};
      if (data) {
        const { profile_pic } = data ?? {};
        console.log('pp', profile_pic);
        if (profile_pic) {
          setPic(profile_pic);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const save = async () => {
    try {
      setError('');
      setLoading(true);
      if (!formData?.profilePic && !userProfileInfo?.profile_pic?.length) {
        setError(t('onboarding.selectPicture') || 'Please select a picture first');
        return;
      }
      const payload = {
        step: 'photo',
        emailId: userProfileInfo?.email,
        data: {
          profile_pic: formData?.profilePic,
        },
        isFormData: true,
        headers: true,
      };
      const resp = await dispatch(setOnboardingData(payload));
      await dispatch(getUserProfileInfo());
      return true;
    } catch (e) {
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    const done = await save();
    if (done) {
      next?.();
    }
  };

  const handleSkip = async () => {
    try {
      const payload = {
        step: 'photo',
        emailId: userProfileInfo?.email,
        data: {
          use_google_photo: true,
        },
        headers: true,
      };
      await dispatch(setOnboardingData(payload));
      dispatch(getUserProfileInfo());
      next?.();
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const { experience, education, city, state, country } = userProfileInfo ?? {};
  const title = education?.title ?? experience?.title;

  const intitution = education?.title ?? experience?.company_name;
  const location = [city ?? '', state ?? '', country ?? '']
    ?.filter?.((v) => v.length > 0)
    ?.join(', ');
  return (
    <>
      <Stack>
        <Stack textAlign="center" alignItems="center">
          <Heading color={'#3D5A80'} fontWeight={600} fontSize={'16px'} fontFamily={"Inter"}>
            {t('onboarding.addPhoto') || "Ok, now let's put a face to that name! Add a photo to myProfile"}
          </Heading>
        </Stack>

        <form>
          <Stack spacing={4} pt={6}>
            <HStack justifyContent="center">
              <Stack alignItems="center">
                <Box pos="relative">
                  <Link
                    href="javascript:void(0);"
                    onClick={(e) => uploadDisc.onOpen?.()}
                  >
                    <Avatar
                      size="2xl"
                      {...(pic ? { src: pic } : {})}
                      name={fullname}
                    />
                    {pic ? (
                      <Box
                        p={2}
                        bg="gray.100"
                        borderRadius="full"
                        pos="absolute"
                        right={0}
                        top={0}
                        onClick={(e) => {
                          e.stopPropagation();
                          removePic?.();
                        }}
                      >
                        <DeleteIcon width="20" height="20" />
                      </Box>
                    ) : (
                      ''
                    )}
                    <Box
                      p={2}
                      bg="gray.100"
                      borderRadius="full"
                      pos="absolute"
                      right={0}
                      bottom={0}
                    >
                      <Camera width={20} height={20} />
                    </Box>
                  </Link>
                </Box>
                <Stack spacing={0} alignItems="center">
                  <Heading 
                     marginTop={"4px"}
                     color={'#3D5A80'}
                     fontFamily= "Inter"
                     fontSize= "20px"
                     fontWeight= "500"
                     lineHeight= "17.4px"
                  >
                    {fullname}
                  </Heading>
                  <Box fontWeight="medium">
                    {title?.length ? <Text
                      color = "#5B5B5B"
                      fontFamily= "Inter"
                      fontSize= "14px"
                      fontWeight= "400"
                      lineHeight= "12.18px"
                    display="inline">{title}</Text> : ''}
                    {title?.length && intitution?.length ? ' at ' : ''}
                    {intitution?.length ? (
                      <Text display="inline" 
                      color = "#5B5B5B"
                      fontFamily= "Inter"
                      fontSize= "14px"
                      fontWeight= "400"
                      lineHeight= "12.18px"
                      >{intitution}</Text>
                    ) : (
                      ''
                    )}
                  </Box>
                  {location?.length > 0 ? (
                    <Box mt="-1">
                      {title?.length ? (
                        <Text 
                        color = "#5B5B5B"
                        fontFamily= "Inter"
                        fontSize= "12px"
                        fontWeight= "400"
                        lineHeight= "10.44px"
                        display="inline">{location}</Text>
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                </Stack>
              </Stack>
            </HStack>
            <Stack>
              {error?.length ? (
                <Text textAlign="center" color="red">
                  {error}
                </Text>
              ) : (
                ''
              )}
              <Button
                isLoading={loading}
                onClick={handleNext}
                py={6}
                variant="primary"
                fontWeight = "600"
                fontSize ="16px"
                borderRadius="30px"
                height="48px"
              >
                {t('onboarding.setProfilePicture') || "Set Profile Picture"}
              </Button>
              {/* <Button
                leftIcon={<GoogleIcon />}
                py={6}
                variant="secondary"
                onClick={back}
              >
                Use my Google Photo
              </Button> */}
            </Stack>
            <HStack justifyContent="space-between">
              <Button 
               height="48px"
               borderRadius="30px"
               color='#5B5B5B'
               fontFamily="Inter"
               fontSize="16px"
               fontWeight="500"
               lineHeight="19.2px"
               textAlign="center"
              variant="ghost" onClick={back}>
                {t('buttons.back') || "Back"}
              </Button>
              <Button 
               height="48px"
               borderRadius="30px"
               color='#5B5B5B'
               fontFamily="Inter"
               fontSize="16px"
               fontWeight="500"
               lineHeight="19.2px"
               textAlign="center"
              variant="ghost" onClick={handleSkip}>
                {t('onboarding.skip') || "Skip"}
              </Button>
            </HStack>
          </Stack>
        </form>
      </Stack>
      <ImageUploadModal
        uploadCropedImg={uploadCropedImg}
        cropType={'profilePhoto'}
        {...uploadDisc}
      />
    </>
  );
};
