import React, { useEffect, useState, useContext } from 'react';
import { Box, HStack } from '@mybridge/ui/layout';
import styles from './statusdrop.module.scss';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { List, ListItem } from '@mybridge/ui/list';
import { Text } from '@mybridge/ui/text';
import { getUserPresence, updateUserPresence } from 'v4/store/actions/chat.actions';
import { useDispatch, useSelector } from 'react-redux';
import { statusColor, userStatus, userStatusMsg } from './constant';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { Image } from '@mybridge/ui';
import classNames from 'classnames';
import { getUserProfileInfo, getUserPresenceStatus, updateUserPresenceStatus } from 'v4/store/actions/user-profile.actions';
import _ from 'lodash';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

const StatusAccordion = () => {
  const dispatch = useDispatch();
  const { matrixClient, loggedInUser } = useContext(MatrixContext);
  const [selected, setSelected] = useState(userStatus[0]);
  const { userPresenceStatus } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(getUserPresence(loggedInUser));
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (userPresenceStatus) {
      presenceState(
        userPresenceStatus?.matrix_presence,
        userPresenceStatus?.matrix_status
      );
    }
  }, [userPresenceStatus]);
  const { t } = useTranslator();

  const presenceState = (presence, status) => {
    switch (presence) {
      case 'online':
        return setSelected({
          id: 1,
          title: status || 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      case 'offline':
        return setSelected({
          id: 3,
          title: status || 'Away',
          color: '#5B5B5B',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      case 'busy':
        return setSelected({
          id: 2,
          title: status || 'Busy',
          subTitle: 'Mute chat notifications',
          color: '#FF0600',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      default:
        return setSelected({
          id: 1,
          title: 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
        });
    }
  };

  const handleSelect = (param) => {
    setSelected(param);
    const stateMap = {
      1: { state: 'online', statusMsg: 'Available' },
      2: { state: 'busy', statusMsg: 'Busy' },
      3: { state: 'offline', statusMsg: 'Away' },
    };
    const { state, statusMsg } = stateMap[param.id];
    const body = {
      matrix_presence: state,
      matrix_status: statusMsg,
      is_presence_manual: true,
    };
    dispatch(updateUserPresenceStatus(body)).then(() => {
      dispatch(getUserPresenceStatus());
    });
  };

  const handleMsgSelect = (message) => {
    const state = message === 'In a meeting' ? 'busy' : userPresenceStatus?.matrix_presence;
    const body = {
      matrix_presence: state,
      matrix_status: message,
      is_presence_manual: true,
    };
    dispatch(updateUserPresenceStatus(body)).then(() => {
      dispatch(getUserPresenceStatus());
    });
  };

  return (
    <Box 
    // pr={"40px"}
    >
      <Accordion style={{
                backgroundColor: 'transparent',
                border: '1px solid #D9D9D9',
                boxShadow: '0px 2px 4px 0px #00000029',
                borderRadius: '8px',

            }} allowToggle>
        <AccordionItem borderColor={'transparent'}>
          <h2>
            <AccordionButton >
              <Box flex="1" textAlign="left" className={styles.statusContainer}>
                <Box
                  as="span"
                  className={styles.statusIcon}
                  style={{ backgroundColor: `${selected.color}` }}
                >
                  Dot
                </Box>
                {selected.title}
              </Box>
              <AccordionIcon
                fontSize={"1.75em"}
              />
            </AccordionButton>
          </h2>
          <AccordionPanel border="none" boxShadow={"none"} borderRadius= '0px 0px 8px 8px' pb={4} className={styles.statusDropdown}>
            <List>
              {userStatus.map((status) => (
                <ListItem
                  key={status.id}
                  cursor="pointer"
                  className={styles.dropItem}
                  as={HStack}
                  alignItems="flex-start"
                //   _hover={{ bg: 'gray.100' }}
                  borderRadius="5"
                  onClick={() => handleSelect(status)}
                >
                  <HStack flex={1} alignItems="flex-start">
                    <Box
                      as="span"
                      className={styles.statusIcon}
                      style={{ backgroundColor: `${status.color}` }}
                    >
                      Dot
                    </Box>
                    <Box>
                      <Text>{t(status.translationKey) || status.title}</Text>
                      {status.subTitle && <Box as="span">{t(status.subTranslationKey) || status.subTitle}</Box>}
                    </Box>
                  </HStack>
                  <Box>{selected?.id === status?.id ? <CheckIcon /> : ''}</Box>
                </ListItem>
              ))}
              <hr style={{ marginBottom: '10px' }} />
              {userStatusMsg.map((stateMessage) => (
                <ListItem
                  key={stateMessage.id}
                  cursor="pointer"
                  className={classNames(styles.dropItem, styles.small)}
                  as={HStack}
                  alignItems="flex-start"
                //   _hover={{ bg: 'gray.100' }}
                  borderRadius="5"
                  onClick={() => handleMsgSelect(stateMessage.title)}
                >
                  <HStack flex={1} alignItems="flex-start">
                    <Image src={stateMessage.icon} />
                    <Text>{stateMessage.title}</Text>
                  </HStack>
                  <Box>
                    {selected?.statusObj?.id === stateMessage?.id ? (
                      <CheckIcon />
                    ) : (
                      ''
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default StatusAccordion;
