/* eslint-disable @nx/enforce-module-boundaries */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import 'react-base-table/styles.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-range/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import 'swiper/css';
import 'swiper/css/navigation';
import 'v4/components/common/Editor/react-draft-wysiwyg.css';
import 'react-phone-number-input/style.css';
import "@cyntler/react-doc-viewer/dist/index.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-calendar/dist/Calendar.css';
import { GlobalContextProvider } from 'v4/snippets/global/context';
import StaticPageLayout from 'v4/snippets/layouts/static-page';
import { ThemeProviders } from '../snippets/providers/providers';

import dynamic from 'next/dynamic';
import { RootLayout } from 'v4/snippets/layouts';
import { MatrixProvider } from '../context/chat-context';
import "react-multi-carousel/lib/styles.css";
// const MatrixProvider = dynamic(() => import('../context/chat-context'));
import './global.css';
import Head from 'next/head';
const GlobalPostContainer = dynamic(() =>
  import('v4/snippets/dashboard/global-post-container').then(
    (mod) => mod.GlobalPostContainer
  )
);
const GlobalReelContainer = dynamic(() =>
  import('v4/snippets/dashboard/global-reel-container').then(
    (mod) => mod.GlobalReelContainer
  )
);

if (typeof window !== 'undefined') {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(err => {
      console.log('Service Worker registration failed:', err);
    });
}

const qc = new QueryClient();

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  return (
    <>

      {/* {!loading && ( */}
      <SessionProvider
        // baseUrl={process?.env?.NEXTAUTH_URL}
        session={pageProps?.session}
      >
        <QueryClientProvider client={qc}>
          <ThemeProviders>
            <MatrixProvider>
              <GlobalContextProvider>
                {router.pathname.includes('about-us') ||
                router.pathname.includes('terms-of-use') ||
                router.pathname.includes('privacy-policy') || router.pathname.includes('business')||router.pathname.includes('csae-policy') ? (
                  <StaticPageLayout>
                    <Component {...pageProps} />
                  </StaticPageLayout>
                ) : (
                  <RootLayout>
                    <Component {...pageProps} />
                    <GlobalPostContainer />
                    <GlobalReelContainer />
                  </RootLayout>
                )}
              </GlobalContextProvider>
            </MatrixProvider>
          </ThemeProviders>
        </QueryClientProvider>
      </SessionProvider>
      {/* )} */}
    </>
  );
};

export default App;
