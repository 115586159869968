/* eslint-disable @nx/enforce-module-boundaries */
import {
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@mybridge/ui';
import { useContext, useMemo, useState } from 'react';
import { TeamsContext } from '../context';
import { Controller, useForm } from 'react-hook-form';
import { report } from 'process';
import {
  ArrowDown,
  ArrowRight,
  SimpleTickIcon,
  TagTick,
  TickMarkIcon,
} from '@mybridge/icons';
import { Box } from '@chakra-ui/react';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const TeamReportModal = ({ onClose, title }) => {
  const { reportTeam, team, teamDetails, reportTeamLoading, reportTeamDisc,errorExistCompany } =
    useContext(TeamsContext);
  const { t } = useTranslator();

  const [openModal, setOpenModal] = useState({ open: false, data: '' });

  const reportMessages = useMemo(
    () => [
      {
        title: t('teamsPage.pretending') || 'Pretending to be someone else',
      },
      {
        title: t('teamsPage.fakeAccount') || 'Fake account',
      },
      {
        title: t('teamsPage.harassment') || 'Harassment or bullying',
      },
      {
        title: t('teamsPage.somethingWrong') || "Something's not right",
      },
    ],
    []
  );

  const { handleSubmit, control } = useForm();

  const onReport = (form) => {
    const payload = { team: team ?? teamDetails, message: form?.message };
    reportTeam({payload,redirectToListing:false,onReport:true});
  };
  return (
    <>
      <Modal {...reportTeamDisc}>
        <ModalOverlay />
        <ModalContent minW="559px">
          <form>
            <ModalHeader
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {/* Centering the text */}
              <Text color={'#3D5A80'} fontWeight={400} lineHeight={'24px'}>
                {t('teamsPage.reportTeam') || "Report the Team"}
              </Text>
              <ModalCloseButton />
            </ModalHeader>
            <hr
              style={{
                width: '90%',
                margin: '0 auto',
                padding: '0 10px',
                border: '1 px solid #F6F6F6',
              }}
            />
            <ModalBody mb={6}>
              <Heading
                pl={1}
                fontSize={'18px'}
                color={'#3D5A80'}
                fontWeight={400}
                lineHeight={'36px'}
              >
                {t('teamsPage.selectProblem') || "Please select a problem"}
              </Heading>
              <Text mb={6} pl={1}>
                {
                  t('teamsPage.reportWarning') || "If someone is in immediate danger, get help before reporting to myBridge. Don't wait."
                }
              </Text>

              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Stack spacing={6}>
                    {reportMessages?.map((rm, rmIndex) => (
                      <Box
                        key={rmIndex}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        display="flex"
                        justifyContent="space-between" // Add this line
                        alignItems="center"
                        onClick={() => {
                          onReport({ message: rm.title });
                          onChange(rm.title); // Update the form value
                          setOpenModal({ open: true, data: rm.title });

                          // handleOptionSelect(rm);  // Call if you need to handle selection
                        }}
                        pr={2} // Apply padding to the right side only
                        pl={1}
                      >
                        <Box>
                          <Text>{rm.title}</Text>
                        </Box>
                        <ArrowRight ml="auto" />
                      </Box>
                    ))}
                  </Stack>
                )}
              />
            </ModalBody>
            {/* <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={(e) => reportTeamDisc?.onClose?.()}
              >
                Cancel
              </Button>
              <Button
                isLoading={reportTeamLoading}
                variant="primary"
                type="submit"
              >
                Report
              </Button>
            </HStack>
          </ModalFooter> */}
          </form>
        </ModalContent>
      </Modal>

      {openModal?.open && !errorExistCompany&& (
        <Modal isOpen={openModal?.open} onClose={() => setOpenModal(false)}>
          <ModalOverlay />

          <ModalContent minW="559px">
            <form>
              <ModalHeader
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box mt={5}>
                  <TickMarkIcon />
                </Box>

                {/* Centering the text */}

                <ModalCloseButton />
              </ModalHeader>
              <ModalBody>
                <Text
                  color={'#3D5A80'}
                  fontSize={'20px'}
                  fontWeight={400}
                  lineHeight={'24px'}
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                >
                  {t('teamsPage.reportTeam') || "Report the Team"}
                </Text>
                <Heading
                  fontSize={'16px'}
                  fontWeight={400}
                  lineHeight={'19.2px'}
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  color={'#747474'}
                  padding={'12px'}
                >
                  {t('teamsPage.thanksForReporting') || "Thanks for reporting team"}
                </Heading>
                <Box
                   pl={1} 
                   bg={'#D9D9D9'} 
                   borderRadius={'10px'} 
                   display={'flex'}
                   alignItems={'center'} // Center vertically
                   justifyContent={'center'} // Center horizontally
                   gap={1}
                   m={"0 22%"}
                   width="auto" // Dynamic width based on content
                >
                  <TagTick />
                  <Text>{openModal?.data}</Text>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setOpenModal(false);
                    reportTeamDisc?.onClose?.(); // Close the first modal
                  }}
                  variant="primary"
                  type="submit"
                  width="100%"
                >
                  {t('buttons.done') || 'Done'}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
