/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { FormControl, FormErrorMessage } from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { PinInput, PinInputField } from '@mybridge/ui/pin-input';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateYourAccount,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';

export const ActivateAccountModal = ({ ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const { email } = userProfileInfo ?? {};
  const onFormSubmit = async (form) => {
    if (!form.code) return;
    setLoading(true);
    try {
      const resp = await dispatch(activateYourAccount(form?.code));
      const { code } = resp?.payload ?? {};
      if (code === 'INVALID_OTP') {
        toast({
          title: t('activateAccount.invalidCode') || 'Invalid verification code entered! Please try again',
          status: 'error',
        });
        setLoading(false);
        return;
      } else {
        toast({
          title: t('activateAccount.accountActivated') || 'You account has now been activated successfully!',
          status: 'success',
        });
      }
      props?.onClose?.();
      dispatch(getUserProfileInfo());
    } catch (e) {
      toast({
        title: t('toast.registrationError') || 'Error while registering!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="12px !important">
        <ModalHeader textAlign="center">{t('activateAccount.confirmEmail') || "Confirm your email"}</ModalHeader>
        {/* <ModalCloseButton /> */}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody marginLeft="-12px" marginRight="-12px" marginTop="-12px" pb={2}>
            <Stack>
              <Text textAlign="center">
                {t('activateAccount.enterCode') || "Type in the code we sent to"} {email}{' '}
                <Link
                  onClick={(e) => {
                    dispatch(setOpenAuthModal('change-email'));
                  }}
                  display="inline"
                  fontFamily="Inter"
                  fontWeight="700"
                  lineHeight="20.8px"
                  fontSize="16px"
                  color="#5B5B5B"
                >
                  {t('activateAccount.editEmail') || "Edit Email"}
                </Link>
              </Text>
              <HStack marginTop="10px" flex={1} alignItems="flex-start" justifyContent="center">
                <FormControl
                  as={Stack}
                  alignItems="center"
                  justifyContent="center"
                  flex={1}
                  isInvalid={errors?.code}
                >
                  <HStack
                    justifyContent="center"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="5"
                    w="100%"
                    bg="#FFFFFF"
                  >
                    <Controller
                      render={({ field }) => (
                        <PinInput placeholder="-" {...field}>
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                          <PinInputField
                            w={4}
                            border="none"
                            _active={{ border: 'none', outline: 'none' }}
                          />
                        </PinInput>
                      )}
                      control={control}
                      name="code"
                      rules={{ required: true }}
                    />
                  </HStack>
                  {errors?.code && (
                    <FormErrorMessage>
                      {t('activateAccount.validCode') || "Please enter a valid code"}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
              <HStack pt={2} justifyContent="center">
                <Text 
                 fontFamily="Inter"
                 fontWeight="500"
                 lineHeight="20.8px"
                 fontSize="16px"
                 color="#5B5B5B"
                >
                  {t('activateAccount.noCode') || "Didn’t receive the code?"}{' '}
                  <Link
                    onClick={(e) => {
                      dispatch(setOpenAuthModal('send-verify-link'));
                    }}
                    display="inline"

                    fontFamily="Inter"
                    fontWeight="500"
                    lineHeight="20.8px"
                    fontSize="16px"
                    color="#5B5B5B"
                  >
                    {t('activateAccount.sendAgain') || "Send again"}
                  </Link>
                </Text>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter marginLeft="-12px" marginRight="-12px" marginTop="-4px" justifyContent="center">
            <Stack flex={1}>
              <Button
                borderRadius="30px"
                height="48px"
                py={6}
                fontWeight="bold"
                w="100%"
                type="submit"
                variant="primary"
                isLoading={loading}
                onClick={handleSubmit(onFormSubmit)}
              >
                {t('activateAccount.agreeConfirm') || "Agree & Confirm"}
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
