import React, { memo } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Spinner } from '../spinner';
import { Box, HStack } from '../layout';
import { PlayIcon, PauseIcon, VolumeUp, VolumeOff,FullScreenIcon } from '@mybridge/icons';
import styles from './videoplayer.module.scss'

class VideoPlayer_ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            isVisible:false,
            playing: false,
            vidMuted: true,
            duration: 0,
            played: 0,
            seeking: false,
            hovering: false,
            isFullScreen: false
        }
        this.videoRef = React.createRef();
    }  

    componentDidMount() {
        this.observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              this.setState({ isVisible: true, ready:true, playing:true });
            } else {
              this.setState({ isVisible: false });
            }
          },
          {
            threshold: 0.5,
          }
        );
    
        if (this.videoRef.current) {  
          this.observer.observe(this.videoRef.current);
        }
      }
    
      componentWillUnmount() {
        if (this.observer && this.videoRef.current) {
          this.observer.unobserve(this.videoRef.current);
        }
      }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.ready !== this.state.ready || nextState.isVisible !== this.state.isVisible || nextState.playing !== this.state.playing || nextState.vidMuted !== this.state.vidMuted || nextState.played !== this.state.played) {
            return true;
        }
        if (JSON.stringify(nextProps.style) !== JSON.stringify(this.props.style)) {
            return true;
        }
        if (nextProps.src !== this.props.src) {
            return true;
        }
        return false;
    }
    handleClick = () => {
      this.setState({ready:!this.state.ready});
    };
    handlePlayPause = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ playing: !this.state.playing });
    };
  
    handleMuteUnmute = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ vidMuted: !this.state.vidMuted });
    };
  
    handleProgress = (state) => {
      if (!this.state.seeking) {
        this.setState({ played: state.played });
      }
    };
  
    handleDuration = (duration) => {
      this.setState({ duration });
    };
  
    handleSeekChange = (event) => {
      this.setState({ played: parseFloat(event.target.value) });
    };
  
    handleSeekMouseUp = (event) => {
      this.setState({ seeking: false });
      this.player?.seekTo(parseFloat(event.target.value), "fraction");
    };
  
    handleSeekMouseDown = (event) => {
      this.setState({ seeking: true });
    };
  
    handleMouseEnter = () => {
      this.setState({ hovering: true });
    };
    
    handleMouseLeave = () => {
      this.setState({ hovering: false });
    };
  
    ref = (player) => {
      this.player = player;
    };

    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
  
      if (hours > 0) {
        return [
          String(hours).padStart(2, '0'),
          String(minutes).padStart(2, '0'),
          String(secs).padStart(2, '0'),
        ].join(':');
      } else {
        return [
          String(minutes).padStart(2, '0'),
          String(secs).padStart(2, '0'),
        ].join(':');
      }
    }
  
    handleFullScreen = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!document.fullscreenElement) {
        this.videoRef.current.requestFullscreen().then(() => {
          this.setState({ isFullScreen: true });
        }).catch((err) => {
          console.error("Error attempting to enable full-screen mode:", err);
        });
      } else {
        document.exitFullscreen().then(() => {
          this.setState({ isFullScreen: false });
        }).catch((err) => {
          console.error("Error attempting to exit full-screen mode:", err);
        });
      }
    };

    render() {
        const { isVisible,playing, vidMuted, played, duration , hovering} = this.state;
        return (
          <Box
            pos="relative"
            minH="300px"
            w="100%"
            ref={this.videoRef}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            {...(this.props.wrapperProps ?? {})}
          >
            <HStack
              pos="absolute"
              justifyContent="center"
              alignItems="center"
              opacity={this.state.ready ? 0 : 1}
              zIndex={this.state.ready ? -1 : 4}
              top={0}
              bottom={0}
              right={0}
              left={0}
              pointerEvents={this.state.ready ? 'none' : 'all'}
            >
                <Spinner size="lg"  />
            </HStack >
            <ReactPlayer
              ref={this.ref}
              url={this.props.src}
                controls = {false}
              muted={vidMuted}
              playing={isVisible && playing}
              // light
              // onReady={() => {
              //     this.props?.onLoad?.()
              //     this.setState({ ready: true })
              // }}
              // onProgress={(e) => this.props?.getVideoDuration?.(e)}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              {...this.props}
                style={{ opacity: this.state.ready ? 1 : 0, ...(this.props.style ?? {}) }}
            />
            
            <div className={styles.controlCont}>
              {hovering && (
                <>
                  <div className={styles.barCont}>
                    <div style={{ width: '100%' }}>
                      <input
                        type="range"
                        min={0}
                        max={1}
                        step="0.01"
                        value={played}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          this.handleSeekMouseDown(event);
                        }}
                        onChange={(event) => {
                          event.stopPropagation();
                          this.handleSeekChange(event);
                        }}
                        onMouseUp={(event) => {
                          event.stopPropagation();
                          this.handleSeekMouseUp(event);
                        }}
                        className={styles.rangeBar}
                      />
                    </div>
                  </div>

                  <div className={styles.btnsCont}>
                    <div
                      onClick={this.handlePlayPause}
                      className={styles.playBtns}
                      style={{ opacity: `${hovering ? 1 : 0}` }}
                    >
                      {playing ? (
                        <PauseIcon fill="white" width={20} />
                      ) : (
                        <PlayIcon fill="white" width={15} />
                      )}
                    </div>

                    <div className={styles.rightSideCont}>
                      <div className={styles.timeCont}>
                        {this.formatTime(played * duration)}/
                        {this.formatTime(duration)}&nbsp;
                      </div>
                      <div onClick={this.handleMuteUnmute}>
                        {vidMuted ? (
                          <VolumeOff fill="white" width={20} />
                        ) : (
                          <VolumeUp fill="white" width={20} />
                        )}
                      </div>
                      <div onClick={this.handleFullScreen}>
                        <FullScreenIcon color="white" width={20} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!hovering && (
              <div className={styles.outerTime}>
                {this.formatTime(duration - played * duration)}
              </div>
            )}
          </Box>
        );
    }
}

VideoPlayer_.propTypes = {
    src: PropTypes.string.isRequired,
};

export const VideoPlayer = memo(VideoPlayer_);

export default VideoPlayer;