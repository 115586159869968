import { PopoverTrigger } from '@chakra-ui/react';
import {
  ArticleIcon,
  ArticleIconColored,
  CalendarNewIcon,
  ChevronRight,
  DiskNewIcon,
  HelpSupportIcon,
  JobsIcon,
  JobsIconColored,
  MessageColoredIcon,
  MyNetworkNewIcon,
  PageIconColored,
  ProfileIcon,
} from '@mybridge/icons';
import {
  MyCalendarIcon,
  MyDiskIcon,
  PagesIcon,
  SettingsIcon,
  SettingsIconColored,
  TeamsIcon,
  TeamsIconColored,
} from '@mybridge/icons/user-menu';
import { Avatar } from '@mybridge/ui/avatar';
import { Button } from '@mybridge/ui/button';
import { Divider } from '@mybridge/ui/divider';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, SimpleGrid, Stack, VStack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
} from '@mybridge/ui/popover';
import { Text } from '@mybridge/ui/text';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { logout } from 'packages/mybridgev4/store/actions/auth.actions';
import { useDisclosure } from '@mybridge/ui/hooks';
import { useContext, useEffect } from 'react';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { useSession, signIn, signOut } from 'next-auth/react';
import { isMobile } from 'react-device-detect';
import StatusDropdown from 'v4/components/header/status-dropdown';
import { Card, Portal } from '@mybridge/ui';
import { SiteShortcutLinks } from 'v4/snippets/dashboard/site-shortcut-links';
import { useMemo } from 'react';
import { MyEventsIcon, MyEventsIconColored } from '@mybridge/icons/MyEvents';
import { useRouter } from 'next/router';
import StatusAccordion from 'v4/components/header/status-accordian';

export const ProfileMenu = ({ children }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { experience, educations, position } = userProfileInfo;
  const dispatch = useDispatch();
  const { push } = useRouter();
  const router = useRouter();
  const menuDisc = useDisclosure();
  const { matrixClient } = useContext(MatrixContext);
  const { data: session } = useSession();

  const mainMenu = useMemo(() => {
    return [
      {
        title: 'myCalendar',
        icon: <CalendarNewIcon width="20" height="20" />,
        link: '/profile/calendar',
      },
      {
        title: 'myDisk',
        icon: <DiskNewIcon width="20" height="20" />,
        link: '/profile/disk',
      },
      {
        title: 'myEvents',
        icon: <MyEventsIconColored width="20" height="20" />,
        link: '/myevents/',
      },
      {
        title: 'myArticles',
        icon: <ArticleIconColored width="20" height="20" />,
        link: '/articles/',
      },
      {
        title: 'myPages',
        icon: <PageIconColored width="20" height="20" />,
        link: '/settings/pages',
      },
      {
        title: 'myTeams',
        icon: <TeamsIconColored width="20" height="20" />,
        link: '/teams',
      },
      {
        title: 'myJobs',
        icon: <JobsIconColored width="20" height="20" />,
        link: '/jobs',
      },
      {
        title: 'myProfile',
        icon: <ProfileIcon width="20" height="20" />,
        link: '/profile/me',
      },
      {
        title: 'myConnection',
        icon: <MyNetworkNewIcon  width="20" height="20" />,
        link: '/profile/network/my-circle',
      },
      {
        title: 'myMessages',
        icon: <MessageColoredIcon width="20" height="20" />,
        link: '/profile/message',
      },
      // {
      //   title: 'Settings',
      //   icon: <SettingsIconColored width="20" height="20" />,
      //   link: isMobile ? '/settings' : '/settings/account',
      // },
    ];
  }, []);

  const handleLogout = async () => {
    if (matrixClient) {
      // await matrixClient.logout(true);
      await matrixClient.stopClient();
      await matrixClient.clearStores();
    }
    dispatch(logout());
    if (session && !loggedIn &&session.provider === 'google') {
      signOut()
    }
    push('/');
  };
  useEffect(() => {
    if (currentMeetingState) {
      dispatch(setMeetingWindowed());
    }
  }, [menuDisc.isOpen]);

  const userFullName = getUserFullName(userProfileInfo);
  const userProfilePic = getUserProfilePic(userProfileInfo);

  return (
    <>
      {!isMobile ? (
        <Popover {...menuDisc} placement="bottom-end">
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent shadow="base" borderRadius="xl" w="350px">
            <PopoverArrow />
            <PopoverBody p={2}>
              <Stack spacing={3}>
                <Card variant="thinBorderedCard" p={3}>
                  <HStack alignItems="flex-start">
                    <Avatar
                      name={userFullName}
                      src={userProfilePic}
                      width="50px"
                      height="50px"
                    />
                    <Stack spacing="0">
                      <Heading
                        fontSize="18px"
                        fontWeight="500"
                        color="brandPrimary.500"
                        as={Link}
                        onClick={(e) => menuDisc?.onClose?.()}
                        href="/profile/me"
                      >
                        {userFullName}
                      </Heading>
                      {experience?.id ? (
                        <>
                          <Text fontSize="13px">{experience?.title}</Text>
                          <Text fontSize="13px">
                            {experience?.company_name}
                          </Text>
                        </>
                      ) : educations?.length ? (
                        <Text fontSize="13px">{educations?.[0]?.name}</Text>
                      ) : (
                        <Text fontSize="13px">{position}</Text> ?? ''
                      )}
                    </Stack>
                  </HStack>
                  <Divider my={2} />
                  {/* <Link
                    onClick={(e) => menuDisc?.onClose?.()}
                    href="/profile/me"
                  > */}
                  <Button
                    color="brandPrimary.500"
                    fontSize="sm"
                    w="100%"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      router.push('/profile/me');
                    }}
                  >
                    View Profile
                  </Button>
                  {/* </Link> */}
                </Card>
                <Card variant="thinBorderedCard" p={3}>
                  <SiteShortcutLinks isDropDown />
                </Card>
                <Link href="/settings/account">
                  <Button
                    variant="transparent"
                    w="100%"
                    justifyContent="space-between"
                    rightIcon={<ChevronRight height="12px" />}
                    h="60px"
                    px={4}
                    my={-2}
                  >
                    <HStack>
                      <Box
                        w="35px"
                        h="35px"
                        borderRadius="full"
                        bg="#D9D9D9"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <SettingsIcon width="18" height="18" />
                      </Box>
                      <Text>Settings</Text>
                    </HStack>
                  </Button>
                </Link>
                <Button
                  onClick={handleLogout}
                  variant="secondary"
                  w="100%"
                  mb={1}
                >
                  Logout
                </Button>
                <HStack justifyContent="flex-start" gap={0}>
                  <Button
                    variant="transparent"
                    // leftIcon={<TermsIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/terms-of-use');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Terms
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<PrivacyIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/privacy-policy');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Privacy
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<BusinessIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/business');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Business
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<HelpSupportIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/support');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Support
                  </Button>
                </HStack>
                <HStack justifyContent="flex-start" gap={0} mt="-10px">
                  <Text fontSize="12px" color="#5B5B5B" ps={3}>
                    myBridge Corporation © 2024
                  </Text>
                </HStack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Popover {...menuDisc} placement="bottom-end">
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent width="100vw" height="100%">
              <PopoverBody>
                <Stack width="100%" p={3}>
                  <HStack 
                    boxShadow= '0px 0px 10px 0px #00000033'
                    padding= '16px 10px 16px 10px'
                    gap='12px'
                    borderRadius='8px'
                    alignItems='flex-start'
                  >
                    <Avatar name={userFullName} src={userProfilePic} />
                    <Stack spacing="0">
                      <Heading
                      //  size="xs"
                        fontSize="20px"
                        fontWeight="400"
                        // lineHeight="24px"
                        textAlign="left"
                        color="#3D5A80"
                      >
                        {userFullName}
                      </Heading>
                      {/* onClick={() => router.push(`/articles/create/${blog?.id}`)} */}
                      <Stack onClick={()=>{menuDisc?.onClose?.()
                             router.push(`/profile/me`)
                      }}>
                        <Text 
                          // color="brandPrimary.500" f
                          // ontSize="sm"
                          fontSize="14px"
                          fontWeight="400"
                          // lineHeight="18.2px"
                          textAlign="left"
                          color="#828282"
                        >
                          {userProfileInfo?.experience?.profile_headline}
                        </Text>
                      </Stack>
                      {/* <Link
                        onClick={(e) => menuDisc?.onClose?.()}
                        href="/profile/me"
                      >
                        <Text color="brandPrimary.500" fontSize="sm">
                          View Profile
                        </Text>
                      </Link> */}
                      <Text
                        // color="brandPrimary.500" 
                        // fontSize="sm"
                        fontWeight="400"
                        // lineHeight="18.2px"
                        textAlign="left"
                        fontSize="16px"
                        color="#828282"
                      >
                        {userProfileInfo?.experience?.company_name}
                      </Text>
                    </Stack>
                  </HStack>
                  <Divider py={2} borderColor='transparent' />
                  {/* <StatusDropdown /> */}
                  <StatusAccordion />

                  <Heading as="h5" fontSize="lg" mt={3}>
                    All shortcuts
                  </Heading>

                  <SimpleGrid columns={2} spacing={3}>
                    {mainMenu.map?.((mi, mind) => (
                      <Link
                        href={mi.link}
                        onClick={(e) => {
                          menuDisc?.onClose?.();
                        }}
                      >
                        <VStack
                          key={mind}
                          alignItems="flex-start"
                          gap={0}
                          borderRadius="8px"
                          border="1px solid #ddd"
                          boxShadow= '0px 2px 4px 0px #00000029'
                          flexDirection={"column"}
                          p={3}
                        >
                          {mi.icon}
                          <Text fontSize={["md", "lg"]}>{mi.title}</Text>
                        </VStack>
                      </Link>
                    ))}
                  </SimpleGrid>
                  <Divider py={4} />


{/* <Box> */}

<HStack
h="70px"
                  // className={Styles.tab}
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                  borderColor="transparent"
                  // bgColor={mi.name === activeTab ? 'brandGray.500' : ''}
                  // p={2}
                  borderRadius="full"
                  // key={miIndex}
                  onClick={(e) => {
                    menuDisc?.onClose?.();
                    push('/settings');
                  }}
                  gap="2"
                  alignItems="center"
                  cursor="pointer"
                
                >
                  <HStack
                    bg={'#E0E0E0'}
                    alignItems="center"
                    justifyContent="center"
                    w="10"
                    h="10"
                    borderRadius="full"
                    p={2}
                    boxShadow="2px 4px 10px 0px #00000040"
                  >
                    <SettingsIcon/>
                  </HStack>
                  <Text pr="4">{"Settings"}</Text>
                </HStack>

                <HStack
// h="70px"
mb={8}
                  // className={Styles.tab}
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                  borderColor="transparent"
                  // bgColor={mi.name === activeTab ? 'brandGray.500' : ''}
                  // p={2}
                  borderRadius="full"
                  onClick={(e) => {
                    menuDisc?.onClose?.();
                    push('/support');
                  }}
                  // key={miIndex}
                  gap="2"
                  alignItems="center"
                  cursor="pointer"
                
                >
                  <HStack
                    bg={'#E0E0E0'}
                    alignItems="center"
                    justifyContent="center"
                    w="10"
                    h="10"
                    borderRadius="full"
                    p={2}
                    boxShadow="2px 4px 10px 0px #00000040"
                  >
                    <HelpSupportIcon height="18px" width="18px"/>
                  </HStack>
                  <Text pr="4">{"Help & Support"}</Text>
                </HStack>


{/* </Box> */}

                  <Button
                    onClick={handleLogout}
                    variant="secondary"
                    w="100%"
                    mb={2}
                    borderRadius="50px"
                  >
                    Logout
                  </Button>
                  {/* <HStack justifyContent="center">
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/privacy-policy"
                      color="brandGray.700"
                    >
                      Privacy policy
                    </Text>
                    <Text color="brandGray.700">-</Text>
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/terms-of-use"
                      color="brandGray.700"
                    >
                      Term of use
                    </Text>
                    <Text color="brandGray.700">-</Text>
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/about-us"
                      color="brandGray.700"
                    >
                      About
                    </Text>
                  </HStack> */}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </>
  );
};
