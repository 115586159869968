import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInvalid: false,
};

const invalidMeetingIdSlice = createSlice({
  name: 'inValidMeetingId',
  initialState,
  reducers: {
    setMeetingIdValid: (state, action) => {
      state.isInvalid = action.payload;
    },
  },
});

export const { setMeetingIdValid } =
invalidMeetingIdSlice.actions;

export default invalidMeetingIdSlice.reducer;