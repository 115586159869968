import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { authReducer } from './reducers/auth.reducer';
import { calendarReducer } from './reducers/calendar.reducer';
import { chatReducer } from './reducers/chat.reducer';
import companyJobsReducer from './reducers/companyJobs.reducer';
import companyPageReducer from './reducers/companyPages.reducer';
import { connectionReducer } from './reducers/connection.reducer';
import globalDataReducer from './reducers/global.data.reducers';
import { jobsReducer } from './reducers/job.reducer';
import { campaignReducer } from './reducers/campaign.reducer';
import { meetingReducer } from './reducers/meeting.reducer';
import myDiskReducer from './reducers/myDisk.reducer';
import { notificationReducer } from './reducers/notification.reducer';
import { onboardingReducer } from './reducers/onboarding.reducer';
import { paymentSlice } from './reducers/payments.reducer';
import { postReducer } from './reducers/post.reducer';
import { teamCommunityReducer } from './reducers/teamCommunity.reducer';
import { userProfileReducer } from './reducers/user-profile.reducer';
import searchSlice from './slices/searchSlice';
import settingsSlice from './slices/settingsSlice';
import teamSlice from './slices/teamSlice';
import { permissionsReducer } from './reducers/permissions.reducer';
import blogSlice from './slices/blogSlice';
import { supportReducer } from './reducers/support.reducer';
import notificationActive from './slices/notificationActiveSlice';
import dashboardActive from './slices/dashboardAciveSlice';
import invalidMeetingIdSlice from './slices/invalidMeetingIdSlice';
import meetingStateSlice from './slices/meetingSlice';
import translationSlice from './slices/translationSlice';
export const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    user: authReducer,
    userProfile: userProfileReducer,
    companyPage: companyPageReducer,
    companyJobs: companyJobsReducer,
    teamsCommunity: teamCommunityReducer,
    search: searchSlice,
    meeting: meetingReducer,
    posts: postReducer,
    globalData: globalDataReducer,
    jobsReducer,
    connection: connectionReducer,
    chatReducer: chatReducer,
    settings: settingsSlice,
    payment: paymentSlice,
    team: teamSlice,
    myDisk: myDiskReducer,
    notifications: notificationReducer,
    calendar: calendarReducer,
    permissionsReducer,
    blog: blogSlice,
    supportReducer,
    campaignReducer,
    notificationActive: notificationActive,
    dashboardActive: dashboardActive,
    inValidMeetingId: invalidMeetingIdSlice,
    meetingStates: meetingStateSlice,
    translation: translationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});
