import { ListItem, UnorderedList } from '@mybridge/ui';
import { Button } from '@mybridge/ui/button';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { FormControl, FormErrorMessage } from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { Input } from '@mybridge/ui/input';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetPassword, setOpenAuthModal } from 'v4/store/actions/auth.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';

export const ConfirmResetPasswordModal = ({ token, ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      token,
    },
  });

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const onFormSubmit = async ({ token, password }) => {
    setLoading(true);
    try {
      const resp = await dispatch(resetPassword({ token, password }));
      const { status_code } = resp?.payload ?? {};
      if (status_code >= 400) {
        toast({
          title: t('toast.invalidToken') || 'Invalid token! Please try again...',
          status: 'error',
        });
        setLoading(false);
        return;
      } else {
        toast({
          title: t('toast.passwordResetSuccess') || 'Password was reset successfully!',
          status: 'success',
        });
      }
      push('/');
    } catch (e) {
      toast({
        title: t('toast.passwordResetError') || 'Error while reseting password!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('signingPage.resetPassword') || 'Reset Password'}</ModalHeader>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody pb={6}>
            <Stack>
              <Text>Please enter your new password</Text>
              {token?.length ? (
                <Input
                  type="hidden"
                  value={token}
                  {...register('token', { required: true })}
                />
              ) : (
                ''
              )}
              <HStack flex={1}>
                <FormControl isInvalid={errors?.password}>
                  <Input
                    type="password"
                    {...register('password', {
                      required: true,
                      minLength: 8,
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    })}
                    placeholder="New Password"
                  />
                  {errors?.password && (
                    <FormErrorMessage>
                      <Stack>
                        <Text>{t('activateAccount.validPassword') || "Please enter a valid password"}</Text>
                        <UnorderedList>
                          <ListItem>Min 8 characters</ListItem>
                          <ListItem>
                            At least 1 uppercase, 1 lowercase & 1 special
                            character
                          </ListItem>
                          <ListItem>At least 1 numeric</ListItem>
                        </UnorderedList>
                      </Stack>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
              <HStack flex={1}>
                <FormControl isInvalid={errors?.confirmPassword}>
                  <Input
                    type="password"
                    {...register('confirmPassword', {
                      required: true,
                      deps: 'password',
                      validate: (val) => val === watch('password'),
                    })}
                    placeholder="Confirm New Password"
                  />
                  {errors?.confirmPassword && (
                    <FormErrorMessage>
                      Please confirm your new password
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack flex={1}>
              <Button
                py={6}
                fontWeight="bold"
                w="100%"
                type="submit"
                variant="primary"
                isLoading={loading}
              >
                Reset Password
              </Button>
              <Button onClick={(e) => push("/")}>
                Back
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
