import { useRouter } from 'next/router';
import { forwardRef, useEffect } from 'react';
import { TeamsContext, useTeamsContext } from '../../context';
// import { TeamsCreateTeamModal } from '../../team-form';
import { TeamInviteConnectionsModal } from '../../invite-connections-modal';
import { TeamLeaveModal } from '../../team-leave-modal';
import { TeamMakeOrganizerModal } from '../../team-make-organizer-modal';
import { TeamRemoveMemberModal } from '../../team-remove-member-modal';
import { TeamRemoveOrganizerModal } from '../../team-remove-organizer-modal';
import { TeamReportModal } from '../../team-report-modal';
import Head from 'next/head';
import { useSelector } from 'react-redux';
export const TeamsLayout = forwardRef(({ children, ...props }, ref) => {
  const { query: { slug } = {} } = useRouter();
  const { loggedIn } = useSelector((state) => state.user);
  const { globalNotifCount } = useSelector((state) => state.notifications);
  const teamsContext = useTeamsContext({
    slug,
  });
  const {
    createEditDisc,
    invitationDisc,
    leaveTeamDisc,
    reportTeamDisc,
    removeMemberDisc,
    diskStatusDisc,
    makeOrganizerDisc,
    removeOrganizerDisc,
    refetchTeamDetails,
    refetchTeamMembers,
    refetchTeamsListing,
  } = teamsContext ?? {};

  useEffect(() => {
    refetchTeamsListing();
  }, []);

  return (
    <TeamsContext.Provider value={teamsContext}>
      <Head>
        <title>
          {loggedIn && globalNotifCount ? `(${globalNotifCount})` : ''}{' '}
          myTeams | myBridge.me
        </title>
      </Head>
      {children}
      {/* {createEditDisc?.isOpen ? (
        <TeamsCreateTeamModal {...createEditDisc} />
      ) : (
        ''
      )} */}
      {invitationDisc?.isOpen ? (
        <TeamInviteConnectionsModal {...(invitationDisc ?? {})} />
      ) : (
        ''
      )}
      {leaveTeamDisc?.isOpen ? (
        <TeamLeaveModal {...(leaveTeamDisc ?? {})} />
      ) : (
        ''
      )}
      {reportTeamDisc?.isOpen ? (
        <TeamReportModal {...(reportTeamDisc ?? {})} />
      ) : (
        ''
      )}
      {removeMemberDisc?.isOpen ? (
        <TeamRemoveMemberModal {...(removeMemberDisc ?? {})} />
      ) : (
        ''
      )}
      {makeOrganizerDisc?.isOpen ? (
        <TeamMakeOrganizerModal {...(makeOrganizerDisc ?? {})} />
      ) : (
        ''
      )}
      {removeOrganizerDisc?.isOpen ? (
        <TeamRemoveOrganizerModal {...(removeOrganizerDisc ?? {})} />
      ) : (
        ''
      )}
    </TeamsContext.Provider>
  );
});
