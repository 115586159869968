/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { Box, Container, HStack, VStack, Text } from '@mybridge/ui';
import React from 'react';
import styles from './footer.module.scss';
import NextLink from 'next/link';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

const StaticPageFooter = () => {
  const { t } = useTranslator();
  return (
    <Box className={styles.StaticPageFooter}>
      <Container maxW="1366px" w={['100%','100%']}>
        <VStack>
          <HStack gap={3}>
            <NextLink href="/about-us" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                {t('otherModules.about_us') || "About Us"}
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/terms-of-use" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                {t('TnC.termsConditions') || "Terms & Conditions"}
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/privacy-policy" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                {t('TnC.privacyPolicy') || "Privacy Policy"}
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/business" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                {t('otherModules.forBusiness') || "For Business"}
              </Text>
            </NextLink>
          </HStack>
          <Box color="#5B5B5B" fontSize="14px">
            {t('otherModules.myBridgeCorporation') || "myBridge Corporation © 2025"}
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default StaticPageFooter;
