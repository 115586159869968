/* eslint-disable @typescript-eslint/no-empty-function */
import { createReducer } from '@reduxjs/toolkit';
import {
  patchCompanyInfo,
  getCompanyServicesInfo,
  getAllCompanyPages,
  createCompanyPage,
  getCompanyPageInfo,
  getCompanyPageData,
  clear,
  companyAddInfo,
  createService,
  editCompanyService,
  deleteCompanyService,
  getCompanyPublicData,
  getCompanyAnalytics,
  getCompanyPageFollowers,
  uploadBrochure,
  deleteBrochure,
  companyPageActivity,
  unFollowCompanyPage,
  checkSlugAvalability,
  clearCompanyPageDetail,
  followCompanyPage, getAllCompanyTypePages,
  setCompanyPageDetail,
  companyPageActivityPublic
} from '../actions/companyPages.actions';

const initialState = {
  loading: false,
  companyPageList: [],
  companyPageTypeList: [],
  companyPageCount: 0,
  createdPage: undefined,
  companyPageDetail: null,
  companyPageDetailLoading: false,
  companyDetailUpdate: undefined,
  serviceReload: undefined,
  companyServicesList: [],
  companyPublicData: null,
  companyAnalyticsData: [],
  companyFollowers: [],
  conpanyActivities:[],
  brochureUpload:null, 
  error:false,
  message:null,
  success:false,
  slugAvailable:null,
  followUpdated:null,

  companyActivitiesPublic: {
    results: [],
    total: 0,
  },
  // loading: false,
  // error: null,
  hasMore: true,

};

const companyPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCompanyPageInfo.pending, (state, action) => {
      state.companyPageDetailLoading = true;
    })
    .addCase(getCompanyPageInfo.fulfilled, (state, action) => {
      state.companyPageDetail = action.payload;
      state.companyDetailUpdate = false;
      state.companyPageDetailLoading = false;
    })
    .addCase(getCompanyPageInfo.rejected, (state, action) => {
      state.companyPageDetailLoading = false;
    })
    .addCase(patchCompanyInfo.fulfilled, (state, action) => {
      if(action.payload.detail){
        state.error = true;
        state.message = action.payload.detail;
      } else {
        state.companyPageDetail = action.payload;
        state.success = true;
      }
      
    })
    .addCase(getCompanyServicesInfo.fulfilled, (state, action) => {
      state.companyServicesList = action.payload.results;
    })
    .addCase(getAllCompanyPages.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllCompanyPages.fulfilled, (state, action) => {
      state.loading = false;
      state.companyPageList = action.payload.results;
      state.companyPageCount = action.payload.count;
    })
    .addCase(getAllCompanyPages.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getAllCompanyTypePages.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllCompanyTypePages.fulfilled, (state, action) => {
      state.loading = false;
      state.companyPageTypeList = action.payload.results;
      state.companyPageCount = action.payload.count;
    })
    .addCase(clearCompanyPageDetail, (state, action) => {
      state.companyPageDetail = null;
    })
    .addCase(getAllCompanyTypePages.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createCompanyPage.fulfilled, (state, action) => {
      state.createdPage = action.payload;
    })
    .addCase(getCompanyPageData.fulfilled, (state, action) => {
      state.companyPageDetail = action.payload;
    })
    .addCase(companyAddInfo.fulfilled, (state, action) => {
      state.companyDetailUpdate = action.payload;
    })
    .addCase(getCompanyPublicData.fulfilled, (state, action) => {
      state.companyPageDetailLoading = false;
      state.companyPublicData = action.payload;
    })
    .addCase(getCompanyPublicData.pending, (state, action) => {
      state.companyPageDetailLoading = true;
    })
    .addCase(getCompanyPublicData.rejected, (state, action) => {
      state.companyPageDetailLoading = false;
    })
    .addCase(getCompanyAnalytics.fulfilled, (state, action) => {
      state.companyAnalyticsData = action.payload?.results;
    })
    .addCase(createService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
      state.createdPage = undefined;
    })
    .addCase(editCompanyService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
    })
    .addCase(companyPageActivity.fulfilled, (state, action) => {
      state.conpanyActivities = action.payload;
    }).addCase(companyPageActivityPublic.pending, (state) => {
      state.loading = true;
    })
    .addCase(companyPageActivityPublic.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.page === 1) {
        state.companyActivitiesPublic.results = action.payload.data.results;
      } else {
        state.companyActivitiesPublic.results = [
          ...state.companyActivitiesPublic.results,
          ...action.payload.data.results,
        ];
      }
console.log(action?.payload?.data?.results?.length>=10,"action?.payload?.data?.results?.length>10");
      // Update hasMore based on response
      state.hasMore = action?.payload?.data?.results?.length>=5
    })
    .addCase(companyPageActivityPublic.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(deleteCompanyService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
    })
    .addCase(uploadBrochure.fulfilled, (state, action) => {
      state.brochureUpload = action.payload;
    })
    .addCase(deleteBrochure.fulfilled, (state, action) => {
      state.brochureUpload = action.payload;
    })
    .addCase(getCompanyPageFollowers.fulfilled, (state, action) => {
      state.companyFollowers = action.payload.results;
    })
    .addCase(checkSlugAvalability.fulfilled, (state, action) => {
      state.slugAvailable = action.payload;
    })
    .addCase(followCompanyPage.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(followCompanyPage.fulfilled, (state, action) => {
      state.loading = false;
      state.followUpdated = action.payload
    })
    .addCase(followCompanyPage.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(unFollowCompanyPage.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(unFollowCompanyPage.fulfilled, (state, action) => {
      state.loading = false;
      state.followUpdated = action.payload
    })
    .addCase(unFollowCompanyPage.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(setCompanyPageDetail, (state, action) => {
      state.companyPageDetail = action.payload;
    })
    .addCase(clear, (state, action) => {
      state.createdPage = initialState.createdPage;
      state.serviceAdded = initialState.serviceAdded;
      state.serviceUpdated = initialState.serviceUpdated;
    })
    .addDefaultCase((state, action) => {});
});

export default companyPageReducer;

