/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'packages/mybridgev4/store/common/axios';
import { jsonToFormData } from 'v4/lib/utils';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';
import { stringify } from 'querystring';

export const setGroupId = createAction('setGroupId');
export const setTeamId = createAction('setTeamId');
export const clearTeamUpdated = createAction('clearTeamUpdated');
export const clearTeamCreated = createAction('clearTeamCreated');
export const clearTeamLeft = createAction('clearTeamLeft');
export const clearTeamReported = createAction('clearTeamReported');
export const clearUserRequested = createAction('clearUserRequested');
export const clearUserInvited = createAction('clearUserInvited');
export const clearUserAcceptedInvite = createAction('clearUserAcceptedInvite');
export const clearUsersUpdated = createAction('clearUsersUpdated');
export const clearDiskAccessUpdated = createAction('clearDiskAccessUpdated');
export const clearIsPostAccepted = createAction('clearIsPostAccepted');
export const setEditModalOpen = createAction('setEditModalOpen');

export const getTeamCommunityList = createAsyncThunk(
  'getTeamCommunityList',
  async ({ pageParam = 1, query }) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}?page=${pageParam}&organize_type=${query?.filter}&team_name=${query?.searchQuery}`,
      method: 'GET',
    };
    const res = await API(obj);
    return res;
  }
);

export const getTeamCommunityListByPage = createAsyncThunk(
  'getTeamCommunityListByPage',
  async (opts, store) => {
    const { teamsCommunity } = store?.getState?.() ?? {};
    const { page } = teamsCommunity ?? {};
    const obj = {
      url: `${APIEndPoints.TeamCommunity}?page=${(page ?? 0) + 1}`,
      method: 'GET',
    };
    const res = await API(obj);
    return res;
  }
);

export const getTeamCommunityAllTeamsList = createAsyncThunk(
  'getTeamCommunityAllTeamsList',
  async (opts, store) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}?page=${1}&page_size=1000`,
      method: 'GET',
    };
    const res = await API(obj);
    return res;
  }
);

export const createTeamCommunity = createAsyncThunk(
  'createTeamCommunity',
  async (payload) => {
    try {
      const token =  typeof window !== 'undefined' && localStorage.getItem('access');
      const headers = {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      const formData = jsonToFormData(payload);
      const response = await axiosInstance.post(
        `${process.env.BASE_API_URL}${APIEndPoints.TeamCommunity} `,
        formData,
        { headers }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateTeamDetails = createAsyncThunk(
  'updateTeamDetails',
  async (body) => {
    const token =  typeof window !== 'undefined' && localStorage.getItem('access');
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const formData = jsonToFormData(body?.data ?? body ?? {});
    const response = await axiosInstance.patch(
      `${process.env.BASE_API_URL}${APIEndPoints.TeamCommunity}${body?.id}/`,
      formData,
      { headers }
    );
    return response;
  }
);

export const removeTeamImage = createAsyncThunk(
  'removeTeamImage',
  async (body) => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.delete(
      `${process.env.BASE_API_URL}${APIEndPoints.TeamCommunity}${body?.id}/remove-photo/?image_type=${body.image_type}`,
      { headers }
    );
    return response;
  }
);

export const updateTeamDetailsImages = createAsyncThunk(
  'updateTeamDetailsImages',
  async (body) => {
    // const obj = {
    //     url: `${APIEndPoints.TeamCommunity}${body.id}/`,
    //     method: 'PATCH',
    //     body: JSON.stringify(body),
    // }
    // return await API(obj)
    const token =  typeof window !== 'undefined' && localStorage.getItem('access');
    try {
      const headers = {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      const response = await axiosInstance.patch(
        `${process.env.BASE_API_URL}${APIEndPoints.TeamCommunity}${body.id}`,
        body.form,
        { headers }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteTeam = createAsyncThunk('deleteTeam', async (id) => {
  const obj = {
    url: `${APIEndPoints.Team}${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const leaveTeamCommunity = createAsyncThunk(
  'leaveTeamCommunity',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/leave/`,
      method: 'DELETE',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);

export const reportTeamCommunity = createAsyncThunk(
  'reportTeamCommunity',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.ReportTeamCommunity}`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);

export const getTeamDetails = createAsyncThunk('getTeamDetails', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}${id}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const getTeamDetailsBySlug = createAsyncThunk(
  'getTeamDetailsBySlug',
  async (slug) => {
    const obj = {
      url: `${APIEndPoints.Slug}team/?search=${slug}`,
      method: 'GET',
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getTeamMemberList = createAsyncThunk(
  'getTeamMemberList',
  async (body) => {
    try {
      const response = await API({
        url: `${APIEndPoints.TeamCommunity}users/list/?${stringify(body)}`,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getInvitedMemberList = createAsyncThunk(
  'getInvitedMemberList',
  async (body) => {
    try {
      const response = await API({
        url: `${APIEndPoints.TeamCommunity}users/list/?${stringify(body)}`,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRequestedMemberList = createAsyncThunk(
  'getRequestedMemberList',
  async (body) => {
    try {
      const response = await API({
        url: `${APIEndPoints.TeamCommunity}users/list/?${stringify(body)}`,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getBlockedMemberList = createAsyncThunk(
  'getBlockedMemberList',
  async (body) => {
    try {
      const response = await API({
        url: `${APIEndPoints.TeamCommunity}users/list/?${stringify(body)}`,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTeamOrganiserList = createAsyncThunk(
  'getTeamOrganiserList',
  async (body) => {
    try {
      const response = await API({
      url: `${APIEndPoints.TeamCommunity}users/list/?${stringify(body)}`,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateTeamMemberStatus = createAsyncThunk(
  'updateTeamMemberStatus',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/status/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const inviteUsersToTeam = createAsyncThunk(
  'inviteUsersToTeam',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}invite/`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const inviteUsersToFollowCompany = createAsyncThunk(
  'inviteUsersToFollowCompany',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.InviteConnectionsToFollowCompany}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const joinTeamRequest = createAsyncThunk(
  'joinTeamRequest',
  async (body) => {
    // initialState.userRequestedTeams.push(body.for_team_community_id)
    const obj = {
      url: `${APIEndPoints.TeamCommunity}join/`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const acceptInviteRequest = createAsyncThunk(
  'acceptInviteRequest',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/status/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const denyTeamJoinRequest = createAsyncThunk(
  'denyTeamRequest',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/remove/`,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const withdrawTeamInvite = createAsyncThunk(
  'withdrawTeamInvite',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/remove/`,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const createGourpPost = createAsyncThunk(
  'createGourpPost',
  async (body) => {
    console.log('body', body);
    const token =  typeof window !== 'undefined' && localStorage.getItem('access');
    const formData = jsonToFormData(body);
    const headers = {
      'Content-type':
        'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.post(
      `${process.env.BASE_API_URL}${APIEndPoints.TeamChat}`,
      formData,
      { headers }
    );
    return response;
  }
);

export const getGroupPost = createAsyncThunk('getGroupPost', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamChat}user/?for_team_community_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getGroupAdminPost = createAsyncThunk(
  'getGroupAdminPost',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.TeamChat}?for_team_community_id=${id}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const approveGroupPost = createAsyncThunk(
  'approveGroupPost',
  async ({ id, status }) => {
    const token =  typeof window !== 'undefined' && localStorage.getItem('access');
    const headers = {
      'Content-type':
        'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.patch(
      `${process.env.BASE_API_URL}${APIEndPoints.TeamChat}${id}/`,
      { status },
      { headers }
    );
    return response;
  }
);

export const updateUserRole = createAsyncThunk(
  'updateUserRole',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/status/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const updateDiskStatus = createAsyncThunk(
  'updateDiskStatus',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/disk/status/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const sendApprovedMessageToGroup = createAsyncThunk(
  'sendApprovedMessageToGroup',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.SendTeamMessage}?room_id=${body.roomId}&event_type=${body.eventType}&user_id=${body.userId}`,
      method: 'PUT',
      body: jsonToFormData(body.content),
      isFormData: true
    };
    return await API(obj);
  }
);

export const removeTeamMember = createAsyncThunk(
  'removeTeamMember',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunity}user/remove/`,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);
