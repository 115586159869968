import { useSelector } from 'react-redux';

export const useTranslator = () => {
  const { dictionary, language } = useSelector((state) => state.translation);
  const isLoaded = Object.keys(dictionary).length > 0;

  const translate = (key) => {
    if (!isLoaded) return '';
    if (!key) return ''; // Return empty string if key is undefined or null
    
    // Ensure key is a string and split it
    const keys = typeof key === 'string' ? key.split('.') : [];
    
    // If keys is empty after splitting, return the original key
    if (keys.length === 0) return key;
    
    let value = dictionary;
    
    try {
      // Safely navigate through the dictionary
      for (const k of keys) {
        if (value === undefined || value === null) break;
        value = value[k];
      }
    } catch (error) {
      console.error(`Translation error for key "${key}":`, error);
      return key; // Return the original key if there's an error
    }
    
    // Return the found value or the original key if not found
    return value !== undefined ? value : key;
  };

  return { t: translate, isLoaded };
};