/* eslint-disable @nx/enforce-module-boundaries */
import { EditIcon } from '@mybridge/icons/Edit';
import { GmailIcon } from '@mybridge/icons/Gmail';
import { Button } from '@mybridge/ui/button';
import { Divider } from '@mybridge/ui/divider';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { IconButton } from '@mybridge/ui/icon-button';
import { Input, InputGroup, InputRightElement } from '@mybridge/ui/input';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName } from 'v4/lib/commons';
import {
  openSignInModal,
  resendVerificationEmail,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';
import { CancelIcon } from '@mybridge/icons';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const SendVerifyLinkModal = ({ ...props }) => {
  const { push } = useRouter();
  const { t } = useTranslator();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const { email } = userProfileInfo ?? {};
  const onFormSubmit = async () => {
    const payload = {
      email,
    };
    setLoading(true);
    try {
      const res = await dispatch(resendVerificationEmail(payload));
      const { status_code } = res ?? {};
      if (status_code >= 400) {
        let title = t('activateAccount.errorSendingLink') || 'Error while sending link!';
        const { email } = res ?? {};
        if (email.length) {
          title = email?.[0];
        }
        toast({
          title,
          status: 'error',
        });
        return;
      }
      toast({
        title: t('activateAccount.verificationCodeSent') || 'Verification code sent successfully!',
        status: 'success',
      });
      dispatch(setOpenAuthModal('activate-account'));
    } catch (e) {
      toast({
        title: t('activateAccount.errorSendingLink') || 'Error while sending link!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props}>
      <ModalOverlay />
      <ModalContent borderRadius="12px !important">
        <Box display="flex" alignItems="center" paddingRight="24px">
        <ModalHeader 
        flex="1"
        color="#3D5A80"
        fontFamily= "Inter"
        fontSize= "18px"
        fontWeight= "600"
        lineHeight= "21.6px"
        >
        {t('activateAccount.almostThere') || "You’re almost there"}  {getUserFullName(userProfileInfo)}!
        </ModalHeader>
        <Box cursor="pointer" onClick={() => dispatch(setOpenAuthModal(''))}>
        <CancelIcon/>
        </Box>
        </Box>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody marginTop="-4px" paddingRight="12px" paddingLeft="12px">
            <Stack>
              <Text 
              color="#5B5B5B"
              font-family= "Inter"
              font-size= "16px"
              font-weight= "400"
              line-height= "20.8px" 
              textAlign="center"     
              >
                {t('activateAccount.confirmEmail') || "We need to confirm your email address."} {t('activateAccount.checkYour') || "Check your"} {email}{' '}
                {t('activateAccount.accountRequest') || "account or request a new confirmation link."}
              </Text>
              <HStack alignItems="flex-start">
                <FormControl isInvalid={errors?.email}>
                  <InputGroup>
                    <Input
                      type="email"
                      isDisabled={true}
                      autoFocus
                      value={email}
                      placeholder={t('profileDetails.email') || "Email"}
                      {...register('email', { required: true })}
                    />
                    <InputRightElement>
                      <IconButton
                        onClick={(e) =>
                          dispatch(setOpenAuthModal('change-email'))
                        }
                        variant="ghost"
                      >
                        <EditIcon />
                      </IconButton>
                    </InputRightElement>
                  </InputGroup>
                  {errors?.email && (
                    <FormErrorMessage>
                      {t('createPage.pleaseEnterValidEmail') || "Please enter a valid email"}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter paddingRight="12px" paddingLeft="12px" paddingBottom="8px" paddingTop="10px" justifyContent="center">
            <Stack flex={1}>
              <Button
                height="48px"
                borderRadius="30px"
                py={6}
                fontWeight="bold"
                w="100%"
                variant="primary"
                isLoading={loading}
                type="submit"
                onClick={onFormSubmit}
              >
                {t('activateAccount.resendEmail') || "Resend Email"}
              </Button>
              <Button
                height="48px"
                borderRadius="30px"
                onClick={(e) => dispatch(setOpenAuthModal('activate-account'))}
                py={6}
                w="100%"
                variant="ghost"
                color="#5B5B5B"
                fontFamily= "Inter"
                fontSize= "16px"
                fontWeight= "400"
                lineHeight= "20.8px"


              >
                {t('activateAccount.alreadyHaveCode') || "I already have code"}
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
