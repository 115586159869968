/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar, AvatarBadge } from '@mybridge/ui';
import React, {useEffect, useState} from 'react';
import {useSelector } from 'react-redux';

const MyBridgeAvatar = (props) => {
    const {name, src, presence, size} = props;
    const { userProfileInfo, userPresenceStatus } = useSelector((state) => state.userProfile);
    const [status, setStatus] = useState({ color: 'brandRed.500' });

    const presenceState = (presence) => {
        switch (presence) {
          case 'online':
            return setStatus({
              id: 1,
              color: '#0AB33E',
            });
          case 'offline':
            return setStatus({
              id: 3,

              color: '#5B5B5B',
            });
          case 'busy':
            return setStatus({
              id: 2,
              color: '#FF0600',
            });
          default:
            return setStatus({
              id: 1,
              color: '#0AB33E',
            });
        }
      };

      useEffect(() => {
        if(presence){
            presenceState(presence);
        } else {
            presenceState(userPresenceStatus?.matrix_presence);
        }
      }, [userProfileInfo, presence, userPresenceStatus]);

  return (
    <Avatar name={name} key={src} src={src} fontSize="22px" border="1px solid #ffffff" {...props}> 
        <AvatarBadge bg={status.color} border="1px solid #ffffff" bottom="1px"/>
    </Avatar>
  )
}

export default MyBridgeAvatar