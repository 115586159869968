/* eslint-disable @nx/enforce-module-boundaries */
'use client';

import React, { useEffect } from 'react';
import { Provider as StoreProvider, useDispatch, useSelector } from 'react-redux';
import { store } from 'v4/store/store';
import Footer from '../footer';
import Header from '../header';
import { loadDictionary } from 'v4/store/slices/translationSlice';

export const RootLayout = ({ children }) => {
  const LanguageInitializer = ({ children }) => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state) => state.translation.language);
    console.log("currentLanguage = " + currentLanguage);
    useEffect(() => {
      dispatch(loadDictionary(currentLanguage));
    }, [currentLanguage, dispatch]);
  
    return children;
  };
  return (
    <StoreProvider store={store}>
      <React.Fragment>
        <Header />
        <main>
          {/* <LanguageInitializer> */}
            {children}
          {/* </LanguageInitializer> */}
        </main>
        <Footer />
      </React.Fragment>
    </StoreProvider>
  );
};
