/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure } from '@mybridge/ui/hooks';
import { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { MyNetworkProvider } from 'v4/components/network/context';
import Head from 'next/head';
import { MyNetworkProvider } from 'v4/components/network/context';
import { GlobalImagePopup } from './image-popup';
import { CreateJobsProvider } from 'v4/components/company/Layout/context';
import { JobsListingProvider } from '../jobs/context';
import {
  getFeelingsAndActivities,
  getUpcomingMeetingCount,
  setHeaderGlobalNotifCount,
} from 'v4/store/actions/global.data.actions';
import { TeamsLayout } from '../teams/layout/listing';
import { TeamsContext, useTeamsContext } from '../teams/context';
import { useRouter } from 'next/router';
export const GlobalContext = createContext({});

// eslint-disable-next-line no-empty-pattern
export const useGlobalContext = ({}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [imageType, setImageType] = useState();
  const imagePopupDisc = useDisclosure();
  const { loggedIn, pageLoaded } = useSelector((state) => state.user);
  const { feelingsAndActivities } = useSelector((s) => s.globalData) ?? {};
  const { unreadMeetingCount } = useSelector((state) => state.globalData);
  const { teamChatNotications, mainChatNotications } = useSelector((state) => state.chatReducer);
  const { globalNotifCount } = useSelector((state) => state.notifications);
  const globalCount = unreadMeetingCount + teamChatNotications + mainChatNotications + globalNotifCount ?? 0;
  const openImagePopup = (src, type = 'image') => {
    setImage(src);
    setImageType(type);
    imagePopupDisc?.onOpen?.();
  };

  useEffect(() => {
    if (loggedIn) {
      dispatch(getFeelingsAndActivities());
      dispatch(getUpcomingMeetingCount());
    } 
  }, [loggedIn]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (globalCount) {
        dispatch(setHeaderGlobalNotifCount(globalCount));
      } 
    }, [globalCount]);

  return {
    image,
    setImage,
    imageType,
    setImageType,
    imagePopupDisc,
    openImagePopup,
    feelingsAndActivities
  };
};

export const GlobalContextProvider = ({ children, ...props }) => {
  const globalCtx = useGlobalContext({ ...props });
  const { query: { slug } = {} } = useRouter();
  const teamsContext = useTeamsContext({
    slug,
  });
  const { pageHead, pageHeadAppend, pageHeadPrepend } = useSelector(
    (s) => s.globalData
  );
  return (
    <>
      <Head>
        <title>
          {pageHeadAppend} myBridge {pageHeadAppend}
        </title>
      </Head>
      <GlobalContext.Provider value={globalCtx}>
        {/* <TeamsLayout> */}
        <TeamsContext.Provider value={teamsContext}>
          <MyNetworkProvider>
            <JobsListingProvider>
              <CreateJobsProvider>{children}</CreateJobsProvider>
            </JobsListingProvider>
            <GlobalImagePopup />
          </MyNetworkProvider>
        </TeamsContext.Provider>
        {/* </TeamsLayout> */}
      </GlobalContext.Provider>
    </>
  );
};
