import { createSlice } from "@reduxjs/toolkit";

const translations = {
  en: () => import("../../locales/en/common.json"),
  de: () => import("../../locales/de/common.json"),
  es: () => import("../../locales/es/common.json"),
  fr: () => import("../../locales/fr/common.json"),
  pt: () => import("../../locales/pt/common.json"),
};

const initialState = {
  language: "en", // Default language
  dictionary: {},
};

export const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDictionary: (state, action) => {
      state.dictionary = action.payload;
    },
  },
});

export const { setLanguage, setDictionary } = translationSlice.actions;

// Function to normalize the language code
// const normalizeLanguageCode = (language) => {
//   // Strip out the region part like 'en_us' to 'en'
//   return language.split('_')[0]; // This returns the base language code, like 'en' from 'en_us'
// };

// Function to normalize the language code
const normalizeLanguageCode = (language) => {
  // Check if language is defined
  if (!language) return 'en'; // Default to 'en' if language is undefined
  
  // Strip out the region part like 'en_us' to 'en'
  return language.split('_')[0]; // This returns the base language code, like 'en' from 'en_us'
};

export const loadDictionary = (language) => async (dispatch) => {
  // Normalize language code (e.g., 'en_us' -> 'en')
  const normalizedLanguage = normalizeLanguageCode(language);

  // Check if the normalized language exists in the translations object
  const translationModule = await (translations[normalizedLanguage] || translations["en"])();


  // Dispatch the dictionary
  dispatch(setDictionary(translationModule.default));
};

export default translationSlice.reducer;
