import {
  BlockIcon,
  ChatIcon,
  ReportIcon,
  ScheduleIcon,
  SharedConnectionIcon,
  ThreeDotsIcon,
  VerticalThreeDot,
  NetworkCardMenuIcon,
} from '@mybridge/icons';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@mybridge/ui';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { forwardRef, useContext, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { MyNetworkContext } from 'v4/components/network/context';
export const MyNetworkCircleCardMenu = forwardRef(
  ({ fromOnboarding=false,user, children, ...props }, ref) => {
    const { isFriend, setSelectedUser, blockUserDisc, reportUserDisc } =
      useContext(MyNetworkContext);
    const { t } = useTranslator();
    const { startMeetingSingle, startChatSingle } = useContext(MatrixContext);
    console.log(isFriend?.(user),"isFriend?.(user)");
    const menuItems = useMemo(() => {
      const items = [
        ...(isFriend?.(user)
          ? [
              {
                title: t('profileNetwork.message') || 'Message',
                icon: <ChatIcon width={20} height={20} />,
                onClick: () => {
                  startChatSingle?.(user);
                },
                forMobileOnly: true,
              },
              {
                title: t('profileNetwork.meetNow') || 'Meet now',
                icon: <MeetingDefaultIcon width={20} height={20} />,
                onClick: () => {
                  startMeetingSingle?.(user);
                },
                forMobileOnly: false,
              },
              {
                title: t('profileNetwork.schedule') || 'Schedule',
                icon: <ScheduleIcon width={20} height={20} />,
                link: `/profile/calendar/schedule?attendee=${user?.email}`,
                forMobileOnly: false,
              },
            ]
          : []),
        {
          title: t('profileNetwork.sharedConnections') || 'Shared Connections',
          icon: <SharedConnectionIcon width={20} height={20} />,
          link: `/profile/network/${user?.slug}`,
          forMobileOnly: false,
        },
        {
          title: t('profileNetwork.block') || 'Block',
          icon: <BlockIcon width={20} height={20} />,
          onClick: () => {
            setSelectedUser?.(user);
            blockUserDisc?.onOpen?.();
          },
          forMobileOnly: false,
        },
        {
          title: t('profileNetwork.report') || 'Report',
          icon: <ReportIcon width={20} height={20} />,
          onClick: () => {
            setSelectedUser?.(user);
            reportUserDisc?.onOpen?.();
          },
          forMobileOnly: false,
        },
      ];
    
   
    
      // Return all items if the user is not a friend
      return items;
    }, []);
    return (
      <>
        <Menu placement="bottom-end" {...props}>
        {!fromOnboarding && <MenuButton>
            {children ?? (
              <IconButton
                bg="none"
                width="30px"
                height="40px"
                borderRadius="50%"
                border="1px solid #00000033"
              >
                <NetworkCardMenuIcon height="16px" />
              </IconButton>
            )}
          </MenuButton>}
          <Portal>
            <MenuList>
              {menuItems?.map?.((mi, mIndex) => {
                if (!isMobile) {
                  if (!mi.forMobileOnly) {
                    return (
                      <MenuItem
                        color="black"
                        {...(mi.link ? { as: Link, href: mi.link } : {})}
                        key={mIndex}
                        icon={mi.icon}
                        onClick={mi?.onClick}
                      >
                        {mi.title}
                      </MenuItem>
                    );
                  }
                } else if (isMobile) {
                  return (
                    <MenuItem
                      color="black"
                      {...(mi.link ? { as: Link, href: mi.link } : {})}
                      key={mIndex}
                      icon={mi.icon}
                      onClick={mi?.onClick}
                    >
                      {mi.title}
                    </MenuItem>
                  );
                }
              })}
            </MenuList>
          </Portal>
        </Menu>
      </>
    );
  }
);
