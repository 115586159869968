export default async function API({ url, method = 'GET', body = '', isFormData = false, isNoToken = false, headers, isResponseJSON = true }) {
  let options = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };
  // Check if we're in a browser environment before accessing localStorage
  let userLanguage = 'en'; // Default language
  if (typeof window !== 'undefined') {
    userLanguage = localStorage.getItem('userLanguage') || 'en';
  }
  // Add Accept-Language header with the user's selected language
  options.headers['Accept-Language'] = userLanguage;
  if (!isNoToken) {
    options.headers['Authorization'] = 'Bearer ' + localStorage.getItem('chatAccess');
  }
  if (body) {
    options.body = body;
  }
  if (!isFormData) {
    options.headers['content-type'] = 'application/json; charset=UTF-8';
  }
  if (headers) {
    options.headers['content-type'] = 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e';
  }

  const res = await fetch(`${process.env.BASE_CHAT_API}${url}`, options);
  if (isResponseJSON) {
    return await res.json()
  }
  return res;
}
