import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { CompanyAPIEndpoints } from '../common/endPoint';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const getCompanyAllJobs = createAsyncThunk('getCompanyAllJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=all&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyAllJobsPaginated = createAsyncThunk('getCompanyAllJobsPaginated', async ({id, page = 1, status}) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=${status}&hiring_company_id=${id}&page=${page}&page_size=10`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyActiveJobs = createAsyncThunk('getCompanyActiveJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=active&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyActiveJobsPaginated = createAsyncThunk('getCompanyActiveJobsPaginated', async ({id, pageparams = 1, status}) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=${status}&hiring_company_id=${id}&page=${pageparams}&page_size=10`,
    method: 'GET',
  };
  return await API(obj);
});


export const getCompanyCloseJobs = createAsyncThunk('getCompanyCloseJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=closed&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});
export const getCompanyDraftJobs = createAsyncThunk('getCompanyDraftJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=draft&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCandidates = createAsyncThunk('getCandidates', async ({ id, jobId, status, perPage }) => {
  const queryParams = new URLSearchParams({});

  if (jobId) {
    queryParams.append('job_ids', jobId);
  }
  if (status) {
    queryParams.append('status', status);
  }
  if (perPage) {
    queryParams.append('perPage', perPage);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/company/${id}/candidates?${queryParams.toString()}`,
    method: 'GET',
  };
  return await API(obj);
});

export const updateCandidateStatus = createAsyncThunk(
  'updateCandidateStatus',
  async ({ jobId, candidateId, status }) => {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${jobId}/candidate/${candidateId}/status/update`,
      method: 'PUT',
      body: JSON.stringify({
        status,
      }),
    };
    return await API(obj);
  },
);

export const postNewJob = createAsyncThunk('postNewJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}`,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const UpdateJob = createAsyncThunk('UpdateJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${payload.id}`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const UpdatePartialJob = createAsyncThunk('UpdateJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${payload.id}`,
    method: payload?.method ? payload.method : 'PATCH',
    body: JSON.stringify(payload.body),
  };
  return await API(obj);
});

export const RepostJob = createAsyncThunk('RepostJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/repost/${payload}/`,
    method: 'PUT',
  };
  return await API(obj);
});

export const checkCompanyJobFreeSlot = createAsyncThunk('checkCompanyJobFreeSlot', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/get_free_slots/by_company/?company_id=${payload}`,
  };
  return await API(obj);
});

export const getJobCheckout = createAsyncThunk('getJobCheckout', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.JobSubscriptionCheckout}`,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const getJobSubscriptionDetails = createAsyncThunk('getJobSubscriptionDetails', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}`,
  };
  return await API(obj);
});

export const getJobScreeningQuestions = createAsyncThunk('getJobScreeningQuestions', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/screening_questions`,
  };
  return await API(obj);
});

export const jobSubscriptionPriceDetails = createAsyncThunk('jobSubscriptionPriceDetails', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}new/?interval=yearly`,
  };
  return await API(obj);
});
export const createJobSubscription = createAsyncThunk('createJobSubscription', async (payload) => {
  const pathName = 'create';
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}${pathName}/`,
    method:'POST',
    body:JSON.stringify(payload),
  };
  return await API(obj);
});

export const previewJobSubscription = createAsyncThunk('previewJobSubscription', async (payload) => {
  const pathName = 'preview';
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}${pathName}/`,
    method:'POST',
    body:JSON.stringify(payload),
  };
  return await API(obj);
});

export const updateJobSubscription = createAsyncThunk('updateJobSubscription', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}update/`,
    method:'POST',
    body:JSON.stringify(payload),
  };
  return await API(obj);
});

export const closeJob = createAsyncThunk('closeJob', async ({ payload, toast }, { rejectWithValue }) => {
  const { t } = useTranslator();
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${payload.id}`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);

    // Show success toast notification
    toast({
      title: t('toast.jobClosedSuccess') || 'Job closed successfully!',
      status: 'success',
      position: 'top',
    });

    return response;
  } catch (error) {
    // Show error toast notification
    toast({
      title: t('toast.jobCloseError') || 'Failed to close job.',
      description: error.message || 'An error occurred while closing the job.',
      status: 'error',
      position: 'top',
    });

    return rejectWithValue(error);
  }
});

export const setGlobalJobId = createAction('setGlobalJobId');
export const clearGlobalJobId = createAction('clearGlobalJobId');
export const purchasedJobSlots = createAction('purchasedJobSlots');
export const setSubscriptionCompanyId = createAction('setSubscriptionCompanyId');
export const clearSubscriptionCompanyId = createAction('clearSubscriptionCompanyId');
