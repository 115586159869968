/* eslint-disable @nx/enforce-module-boundaries */
import { ModalBody } from '@chakra-ui/react';
import { HStack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { useDispatch, useSelector } from 'react-redux';
import { LocationContext, useCurrentLocation } from 'v4/lib/hooks/use-location';
import { UserOnboardingContext } from '../context';
import { UserOnboardingStepsContainer } from '../onboarding-steps-container';
import { Spinner } from '@mybridge/ui';

export const UserOnboardingModal = (props) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const dispatch = useDispatch();

  return (
    <UserOnboardingContext.Provider value={props}>
      {/* <Modal size="full" {...props}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalCloseButton />
          <ModalBody bg="brandGray.200"> */}
      <HStack
        h="100%"
        w="100%"
        alignItems="flex-start"
        justifyContent="center"
        pos="fixed"
        top="0"
        left="0"
        right="0"
        bg="rgb(255,255,255)"
        zIndex="20"
        overflow="auto"
      >
        <UserOnboardingStepsContainer />
      </HStack>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </UserOnboardingContext.Provider>
  );
};
