/* eslint-disable @nx/enforce-module-boundaries */

import { Box } from '@chakra-ui/react';
import { ArrowRight, TagTick, TickMarkIcon } from '@mybridge/icons';
import { Button } from '@mybridge/ui/button';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { Heading } from '@mybridge/ui/heading';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Radio, RadioGroup } from '@mybridge/ui/radio';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MyNetworkContext } from 'v4/components/network/context';
import { getUserFullName } from 'v4/lib/commons';

export const NetworkBlockAndReportUserModal = ({
  follower,
  profileReport,
  ...props
}) => {
  const [reason, setReason] = useState('');
  const [openModal, setOpenModal] = useState({ open: false, data: '' });
  const { t } = useTranslator();
  const {
    selectedUser,
    reportUser,
    reportUserLoading,
    errorExistCompany,
    reportUserDisc,
  } = useContext(MyNetworkContext);
  const blockReportReasons = useMemo(() => [
    {
      title: t('reportBlock.pretendingToBeSomeoneElse') || 'Pretending to be someone else',
    },
    {
      title: t('reportBlock.fakeAccount') || 'Fake account',
    },
    {
      title: t('reportBlock.postingInappropriateThings') || 'Posting inappropriate things',
    },
    {
      title: t('reportBlock.harassmentOrBullying') || 'Harrasment or bullying',
    },
    {
      title: t('reportBlock.somethingNotRight') || "Something's not right",
    },
  ]);
  const handleBlockReport = (messsage) => {
    const payload = {
      user: selectedUser,
      message: messsage,
      onReport: true,
    };
    reportUser(payload);
  };

  const { handleSubmit, control } = useForm();
  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />

        <ModalContent minW="559px">
          <form>
            <ModalHeader
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {/* Centering the text */}
              <Text color={'#3D5A80'} fontWeight={400} lineHeight={'24px'}>
                {follower
                  ? t('reportBlock.reportFollower') || 'Report the Follower'
                  : profileReport
                  ? t('reportBlock.reportProfile') || 'Report the Profile'
                  : selectedUser?.display_name
                  ? `${t('profilePhotosVideos.report') || "Report"} ${selectedUser?.display_name}`
                  : t('reportBlock.reportTeam') || 'Report the team'}
              </Text>
              <ModalCloseButton />
            </ModalHeader>
            <hr
              style={{
                width: '90%',
                margin: '0 auto',
                padding: '0 10px',
                border: '1 px solid #F6F6F6',
              }}
            />
            <ModalBody mb={6}>
              <Heading
                pl={1}
                fontSize={'18px'}
                color={'#3D5A80'}
                fontWeight={400}
                lineHeight={'36px'}
              >
                {t('reportBlock.pleaseSelectAProblem') || "Please select a problem"}
              </Heading>
              <Text mb={6} pl={1}>
                {
                  t('profileNetwork.immediateDangerWarning') || "If someone is in immediate danger, get help before reporting to myBridge. Don't wait."
                }
              </Text>

              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Stack spacing={6}>
                    {blockReportReasons?.map((rm, rmIndex) => (
                      <Box
                        key={rmIndex}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        display="flex"
                        justifyContent="space-between" // Add this line
                        alignItems="center"
                        onClick={() => {
                          handleBlockReport({ message: rm.title });
                          onChange(rm.title); // Update the form value
                          setOpenModal({ open: true, data: rm.title });

                          // handleOptionSelect(rm);  // Call if you need to handle selection
                        }}
                        pr={2} // Apply padding to the right side only
                        pl={1}
                      >
                        <Box>
                          <Text>{rm.title}</Text>
                        </Box>
                        <ArrowRight ml="auto" />
                      </Box>
                    ))}
                  </Stack>
                )}
              />
            </ModalBody>
            {/* <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={(e) => reportTeamDisc?.onClose?.()}
              >
                Cancel
              </Button>
              <Button
                isLoading={reportTeamLoading}
                variant="primary"
                type="submit"
              >
                Report
              </Button>
            </HStack>
          </ModalFooter> */}
          </form>
        </ModalContent>

        {openModal?.open && !errorExistCompany && (
          <Modal isOpen={openModal?.open} onClose={() => setOpenModal(false)}>
            <ModalOverlay />

            <ModalContent minW="559px">
              <form>
                <ModalHeader
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mt={5}>
                    <TickMarkIcon />
                  </Box>

                  {/* Centering the text */}

                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                  <Text
                    color={'#3D5A80'}
                    fontSize={'20px'}
                    fontWeight={400}
                    lineHeight={'24px'}
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                  >
                    {follower
                      ? t('reportBlock.reportFollower') || 'Report the Follower'
                      : profileReport
                      ? t('reportBlock.reportProfile') || 'Report the Profile'
                      : selectedUser?.display_name
                      ? `${t('profilePhotosVideos.report') || "Report"} ${selectedUser?.display_name}`
                      : t('reportBlock.reportTeam') || 'Report the team'}
                  </Text>
                  <Heading
                    fontSize={'16px'}
                    fontWeight={400}
                    lineHeight={'19.2px'}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    color={'#747474'}
                    padding={'12px'}
                  >
                    {follower
                      ? t('reportBlock.thanksForReportingFollower') || 'Thanks for reporting follower'
                      : profileReport
                      ? t('reportBlock.thanksForReportingProfile') || 'Thanks for reporting profile'
                      : selectedUser?.display_name
                      ? `${t('reportBlock.thanksForReporting') || "Thanks for reporting"} ${selectedUser?.display_name}`
                      : t('reportBlock.thanksForReportingTeam') || 'Thanks for reporting team'}
                  </Heading>
                  <Box
                    pl={1}
                    bg={'#D9D9D9'}
                    borderRadius={'10px'}
                    display={'flex'}
                    alignItems={'center'} // Center vertically
                    justifyContent={'center'} // Center horizontally
                    gap={1}
                    m={'0 22%'}
                    width="auto" // Dynamic width based on content
                  >
                    <TagTick />
                    <Text>{openModal?.data}</Text>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      reportUserDisc?.onClose?.();
                    }}
                    variant="primary"
                    type="submit"
                    width="100%"
                  >
                    {t('buttons.done') || "Done"}
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        )}

        {/* <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Block and Report {getUserFullName(selectedUser)}?
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Heading size="sm" color="brandPrimary.500">
                  Please select a problem
                </Heading>
                <Text>
                  If someone is in immediate danger, get help before reporting
                  to myBridge. Don't wait.
                </Text>
              </Stack>
              <RadioGroup
                onChange={(value) => setReason(value)}
                py={4}
                value={reason}
              >
                <Stack spacing={4}>
                  {blockReportReasons?.map?.((brr, index) => (
                    <Radio key={index} value={brr?.title}>
                      {brr?.title}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleBlockReport}
                isDisabled={reason.length <= 0}
                variant="primary"
                isLoading={reportUserLoading}
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent> */}
      </Modal>
    </>
  );
};
