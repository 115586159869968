/* eslint-disable @nx/enforce-module-boundaries */

import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatLogin,
  clearChatWithUserbyMxId,
  startChatWithUser,
  startChatWithUserbyMxId,
  chatRegisterSession,
  chatRegister,
  setTeamChatNotification,
} from 'v4/store/actions/chat.actions';
import {
  generateMeetingId,
  inviteUserToChat,
  joinMeeting,
} from 'v4/store/actions/meeting.actions';
import { getTeamCommunityAllTeamsList } from 'v4/store/actions/teamCommunity.actions';
import { useDisclosure } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { getMatrixEncryption } from 'v4/store/actions/auth.actions';
import CryptoJS from 'crypto-js';
import {
  blockConnection,
  unblockConnection,
} from 'v4/store/actions/connection.actions';
import { MyNetworkContext } from 'v4/components/network/context';
import { getUserDetailsByChatId } from 'v4/store/actions/user-profile.actions';
// import * as sdk from 'matrix-js-sdk';
// import Olm from '@matrix-org/olm';
export const MatrixContext = createContext();
export const MatrixProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { chatLoggedIn } = useSelector((state) => state.chatReducer);
  const { allTeamsListForChat } = useSelector((state) => state.teamsCommunity);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn, userLoggedOut } = useSelector((state) => state.user);
  const [matrixClient, setMatrixClient] = useState(null);
  const [conversationList, setConversationList] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  const [olmFile, setOlmFile] = useState(false);
  const [chatPresets, setChatPresets] = useState();
  const [joinRules, setJoinRules] = useState();
  const [callStatus, setCallStatus] = useState(null);
  const [callDetails, setCallDetails] = useState(null);
  const [ringingOutgoing, setOutgoingRinging] = useState(false);
  const [ringingIncoming, setIncomingRinging] = useState(false);
  const [unknownDevices, setUnknownDevices] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [teamsChats, setTeamsChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [connectionList, setConnectionList] = useState([]);
  const [roomId, setRoomId] = useState(null);
   const [messages, setMessages] = useState([]);
  const [matrixUserId, setMatrixUserId] = useState(null);
      
  const newChatDisc = useDisclosure();
  const createGroupModal = useDisclosure()
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [callingModal,setCallingModal]=useState(false)

  // const {
  //   refetchBlocked,
  // } = useContext(MyNetworkContext);

  const accessToken =
    typeof window !== 'undefined' && localStorage.getItem('chatAccess');
  const myDeviceId =
    typeof window !== 'undefined' && localStorage.getItem('chatDeviceId');

  const indexedDB = typeof window !== 'undefined' && window.indexedDB;

  const matrixServerUrl = `https://${process.env.BASE_CHAT_SERVICE}`;
  const myUserId = userProfileInfo?.matrix_user_id;
  const displayName = `${userProfileInfo.first_name} ${userProfileInfo.last_name}`;
  const avatarUrl = userProfileInfo?.profile_pic
    ? userProfileInfo?.profile_pic?.split('&')
    : '';
  // Initialize the matrix client

  // Loading OLM library for encryprion
  const initOlm = async () => {
    const Olm = (await import('@matrix-org/olm')) ?? {};
    if (!Olm) return;
    Olm?.init?.({
      locateFile: () => '/olm.wasm',
    }).then(() => {
      setOlmFile(true);
    });
  };

  useEffect(() => {
    initOlm?.();
  }, []);

  useEffect(() => {
    if (loggedIn && userProfileInfo && myDeviceId && myUserId) {
      setupMatrixClient();
    }
  }, [loggedIn, userProfileInfo, myDeviceId, myUserId]);

  useEffect(() => {
    // if (matrixClient) return;
    setTimeout(async () => {
      console.info('setting up client', accessToken, olmFile, chatLoggedIn);
      if (accessToken && olmFile && chatLoggedIn) {
        await setupMatrixClient();
        console.info('setting up done');
      }
    }, 0);
  }, [olmFile, chatLoggedIn, accessToken]);

  // console.log('teamsChats', allTeamsListForChat);
  useEffect(() => {
    if (matrixClient) {
      const storedRooms = matrixClient?.getRooms();
      // Set up event listener for incoming messages
      // console.log('setting listner for room ', storedRooms);
      // storedRooms.forEach(addListenersForEachRoom)
      storedRooms?.forEach(function (room, key) {
        room.on('Room.timeline', (event, room, toStartOfTimeline) => {
          // Check if the event is a new message

          if (event.getType() === 'm.room.message' && !toStartOfTimeline) {
            // Identify the room associated with the incoming message
            const roomId = room.roomId;
            console.log('open auto chat window before', roomId);
            // Open or focus on the chat room
            if (teamsChats.includes(roomId) === false) {
              // console.log('open auto chat window', teamsChats.includes(roomId));
              const roomUnreadMessages =
                room.getUnreadNotificationCount('total');
              setUnreadCount(unreadCount + roomUnreadMessages);
              dispatch(startChatWithUser(roomId));
            }
          }
        });
      });
    }
  }, [matrixClient, conversationList, teamsChats]);

  useEffect(() => {
    if (allTeamsListForChat?.length) {
      (async () => {
        const processedRooms = new Set();

        for (const team of allTeamsListForChat) {
          setTeamsChats((prev) => [...prev, team.matrix_chat_room_id]);

          if (!processedRooms.has(team.matrix_chat_room_id)) {
            const room = await matrixClient?.getRoom(team.matrix_chat_room_id);

            if (room) {
              const unreadMessages = await room.getUnreadNotificationCount(
                'total'
              );
              await dispatch(setTeamChatNotification(unreadMessages));
              processedRooms.add(team.matrix_chat_room_id);
            }
          }
        }
      })();
    }
  }, [allTeamsListForChat]);

  useEffect(() => {
    if (matrixClient && myUserId) {
      let pageParam = 1;
      matrixClient.on('Call.incoming', function (call) {
        setIncomingRinging(true);
        setCallDetails(call);
        addListeners(call);
      });
      matrixClient.on('RoomMember.membership', function (event, member) {
        const content = event.getContent();
        if (
          member.membership === 'invite' &&
          member.userId === myUserId &&
          content?.reason === undefined
        ) {
          try {
            matrixClient
              .joinRoom(member.roomId)
              .then(function () {
                console.info('Auto-joined %s', member.roomId);
              })
              .catch(console.error);
          } catch (e) {
            console.error(e);
            return;
          }
        }
      });
      dispatch(getTeamCommunityAllTeamsList());
    }
  }, [matrixClient]);

  const startMatrixClient = async () => {
    const sdk = await import('matrix-js-sdk');
    const options = {
      baseUrl: matrixServerUrl,
      accessToken,
      userId: myUserId ?? currentUserId,
      crypto: { trustCrossSignedDevices: true },
      deviceId: currentDeviceId ?? myDeviceId,
    };
    const opts = {
      initialSyncLimit: 100,
      lazyLoadMembers: true,
      clientWellKnownPollPeriod: 2 * 60 * 60,
      resolveInvitesToProfiles: true,
    };

    const { LocalStorageCryptoStore } =
      (await import(
        'matrix-js-sdk/lib/crypto/store/localStorage-crypto-store'
      )) ?? {};
    if (!sdk || !LocalStorageCryptoStore) {
      console.error('Could not load matrix sdk or crypto store lib');
      return;
    }
    const {
      MemoryCryptoStore,
      PendingEventOrdering,
      createClient,
      JoinRule,
      WebStorageSessionStore,
      IndexedDBCryptoStore,
    } = sdk ?? {};

    setJoinRules(JoinRule);
    setChatPresets(sdk.Preset);

    if (MemoryCryptoStore) {
      options.sessionStore = new MemoryCryptoStore();
      // options.sessionStore = WebStorageSessionStore(localStorage);
    }
    if (LocalStorageCryptoStore) {
      options.cryptoStore = new LocalStorageCryptoStore(localStorage);
    }

    // if (IndexedDBCryptoStore) {
    //   options.cryptoStore = new IndexedDBCryptoStore(
    //     indexedDB,
    //     'matrix-crypto'
    //   );
    // }
    options.timelineSupport = true;
    if (PendingEventOrdering) {
      opts.pendingEventOrdering = PendingEventOrdering.Detached;
    }
    opts.hidden = false;

    // console.log('myDeviceId', options);

    try {
      const client = sdk.createClient(options);
      await client.initCrypto().then(() => {
        client.startClient(opts);
      });

      await client.once('sync', function (state, prevState, res) {
        if (state === 'PREPARED') {
          const cryptoApi = client.getCrypto();
          // console.log('PREPARED', cryptoApi);
          if (cryptoApi) {
            cryptoApi.globalBlacklistUnverifiedDevices = true;
          }
          client.getCrypto().setTrustCrossSignedDevices(true);
          // if(myDeviceId){
          //   client.getCrypto().setDeviceVerified(myUserId, myDeviceId, true);
          // }
         
          setMatrixClient(client);
          const loggedInUserId = client.getUserId();
          const userDisplayName = client.getUser(loggedInUserId).displayName;
          setConversationList(client.store.rooms);
          // console.log(client.getUserId());
          setLoggedInUser(client.getUserId());
          client.on('RoomMember.membership', function (event, member) {
            const content = event.getContent();
            if (
              member.membership === 'invite' &&
              member.userId === myUserId &&
              content?.reason === undefined
            ) {
              client.joinRoom(member.roomId).then(function () {
                // console.log('Auto-joined %s', member.roomId);
              });
            }
          });
          // if (userDisplayName.includes('@')) {
          client
            .setDisplayName(displayName)
            .then(() => {
              // console.log('Display name updated successfully.');
            })
            .catch((error) => {
              console.error('Error updating display name:', error);
            });
          client
            .setAvatarUrl(avatarUrl.toString())
            .then(() => {
              console.log('Avatar updated successfully.');
            })
            .catch((error) => {
              console.error('Error updating display name:', error);
            });

          // setUnknownDevices(client.getDevices());
          // }
          // client.setDeviceKnown(myUserId, myDeviceId, true);
        } else {
          console.error('syncerror', res);
        }
      });
    } catch (e) {
      console.error('Matrix Login Error', e);
    }
  };

  const setupMatrixClient = async () => {
    if (loggedIn && !accessToken) {
      dispatch(getMatrixEncryption()).then(async (res) => {
        if (res?.payload?.mkey) {
          const encrypted = res?.payload?.mkey; //python is base64 ECB
          let key = process.env.PYTHONENCKEY; //key used in Python
          // let key = 'RHFMKDRIXQILOTEC'; //key used in Python
          key = CryptoJS.enc.Utf8.parse(key);
          const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            mode: CryptoJS.mode.ECB,
          });

          const payload = {
            type: 'm.login.password',
            identifier: {
              type: 'm.id.user',
              user: res?.payload?.matrix_user_id,
            },
            password: decrypted.toString(CryptoJS.enc.Utf8),
          };
          setCurrentUserId(res?.payload?.matrix_user_id);
          if (res?.payload?.matrix_user_id) {
            await dispatch(chatLogin(payload)).then(async (res) => {
              if (res?.payload?.access_token) {
                setCurrentDeviceId(res?.payload?.device_id);
                await startMatrixClient();
              }
            });
          } else {
            const device_name = 'chat.mybridge.io: Chrome on macOS';
            const matrixUserId = userProfileInfo?.email?.replace(/[+@]/g, '_');
            await dispatch(
              chatRegisterSession({ initial_device_display_name: device_name })
            ).then(async (res) => {
              if (res?.payload?.session) {
                const regPayload = {
                  username: matrixUserId,
                  password: decrypted.toString(CryptoJS.enc.Utf8),
                  initial_device_display_name: device_name, //any device id in string can work here.
                  auth: {
                    session: res?.payload?.session, //Provided by server on initial register call
                    type: 'm.login.dummy',
                  },
                };
                await dispatch(chatRegister(regPayload)).then(async (res) => {
                  if (res) {
                    await startMatrixClient();
                  }
                });
              }
            });
          }
        }
      });
    } else {
      if (accessToken && myDeviceId) {
        await startMatrixClient();
      }
    }
  };

  const startChatwithUserIDifExisted = async (user1Id) => {
    try {
      // Ensure that you have joined rooms first
      const rooms = await matrixClient.getRooms();

      const existingRoom = rooms.find((room) => {
        const members = room.getJoinedMembers();

        // if (members.length === 2) {
        return (
          members.length === 2 &&
          members.some((member) => member.userId === user1Id?.matrix_user_id) &&
          members.some(
            (member) => member.userId === userProfileInfo?.matrix_user_id
          )
        );
        // }
      });
      // console.log('members', existingRoom);

      if (existingRoom) {
        console.log(
          `Direct room between ${user1Id?.matrix_user_id} and ${loggedInUser} already exists. Room ID: ${existingRoom.roomId}`
        );
        updateDirectRooms(existingRoom.roomId, user1Id?.matrix_user_id);
        dispatch(startChatWithUser(existingRoom.roomId));
        dispatch(clearChatWithUserbyMxId());
        return existingRoom.roomId;
      } else {
        await matrixClient
          .createRoom({
            preset: chatPresets.TrustedPrivateChat,
            invite: !user1Id?.matrix_user_id ? [] : [user1Id?.matrix_user_id],
            name: `${user1Id?.first_name} ${user1Id?.last_name}`,
            is_direct: true,
            joinRules: 'invite',
          })
          .then((response) => {
            if (response) {
              console.log('Direct room created:', response.room_id);
              dispatch(
                inviteUserToChat({
                  invitee_id: [user1Id?.id],
                  sender_id: userProfileInfo?.id,
                  room_id: response.room_id,
                })
              );
              updateDirectRooms(response.room_id, user1Id?.matrix_user_id);
              dispatch(startChatWithUser(response.room_id));
              dispatch(clearChatWithUserbyMxId());
              return response.room_id;
            }
          })
          .catch((error) => {
            console.error('Error creating room:', error);
          });
        console.log('need tp create new room');
      }
      // onClose();
    } catch (error) {
      console.error('Error creating or checking direct room:', error);
    }
  };

  const addListeners = (call) => {
    let lastError = '';
    call.on('hangup', function (call) {
      console.log('hangup', call);
      setOutgoingRinging(false);
      setIncomingRinging(false);
    });

    call.on('state', function (state) {
      setCallStatus(state);
      if (state === 'create_offer') {
        setOutgoingRinging(true);
      }
      if (state === 'connecting') {
        setOutgoingRinging(false);
      }
      // console.log('Call status', state);
    });
    call.on('error', function (err) {
      lastError = err.message;
      call.hangup();
      console.error('error', lastError);
    });
  };

  const initiateMatrixCall = (roomId, callType = "audio") => {
    try {
      if (!roomId) {
        console.error("Error: Room ID is missing.");
        return;
      }
  
      // Ensure Matrix client is initialized
      if (!matrixClient) {
        console.error("Matrix client is not initialized. Cannot place call.");
        return;
      }
  
      // Ensure Matrix client is synced before calling
      if (matrixClient.getSyncState() !== "SYNCING") {
        console.warn("Matrix client is not synced yet. Waiting...");
        setTimeout(() => {
          if (matrixClient && matrixClient.getSyncState() === "SYNCING") {
            console.log("Matrix client is now synced. Proceeding with the call.");
            initiateMatrixCall(roomId, callType);
          } else {
            console.error("Matrix client is still not synced. Cannot initiate call.");
          }
        }, 2000);
        return;
      }
  
      // Ensure user is part of the room
      const room = matrixClient.getRoom(roomId);
      const userMembership = room?.currentState?.members[matrixClient.getUserId()]?.membership;
  
      if (userMembership !== "join") {
        console.warn(`User is not joined in the room (${roomId}). Retrying...`);
        setTimeout(() => {
          if (!matrixClient) {
            console.error("Matrix client is null during retry.");
            return;
          }
          
          const updatedRoom = matrixClient.getRoom(roomId);
          const updatedMembership = updatedRoom?.currentState?.members[matrixClient.getUserId()]?.membership;
  
          if (updatedMembership === "join") {
            console.log("User is now in the room. Proceeding with the call.");
            initiateMatrixCall(roomId, callType);
          } else {
            console.error("User is still not in the room. Cannot place call.");
          }
        }, 2000);
        return;
      }
  
      console.log(`Initiating ${callType} call in room: ${roomId}`);
      const call = matrixClient.createCall(roomId);
  
      if (!call) {
        console.error("Error: Failed to create a Matrix call object.");
        return;
      }
  
      // Place call based on call type
      if (callType === "audio") {
        call.placeVoiceCall(true);
      } else if (callType === "video") {
        call.placeVideoCall(true);
      }
  
      addListeners(call);
      setCallDetails(call);
      setCallingModal(true);
  
    } catch (err) {
      console.error("Calling error:", err);
    }
  };

  const initiateCall = (userId, callType) => {
    try {
     let user= connectionList?.find(
        (message) => message?.user_details?.user_id === userId
      )
      console.log(roomId,"connectionListconnectionList");
      initiateMatrixCall(user?.room_id,callType)
      // Create a MatrixCall object for the audio call
    } catch (err) {
      console.error('Calling error:', err);
    }
  };

  const startMeetingSingle = async (user) => {
    //check if matrixroom exist or create one and sned the meeting invitation
    const getRoom = await startChatwithUserIDifExisted(user);
    dispatch(generateMeetingId()).then(async (res) => {
      if (res) {
        const meeting = {
          created_by: userProfileInfo,
          meetingId: res.payload.meeting_id,
          attendees: [user],
          isWaiting: false,
        };
        if (meeting) {
          dispatch(joinMeeting(meeting));
          // .then(async () => {
          let body = "Let's meet<br/> Please join using this link: ";
          body +=
            '<a href="' +
            process.env.BASE_PUBLIC_URL +
            'meet/' +
            res.payload.meeting_id +
            '">' +
            process.env.BASE_PUBLIC_URL +
            'meet/' +
            res.payload.meeting_id +
            '</a>';
          body += '  Meeting ID: ' + res.payload.meeting_id;

          const content = {
            body: body,
            format: 'org.matrix.custom.html',
            msgtype: 'm.text',
          };
          matrixClient
            .sendEvent(getRoom, 'm.room.message', content)
            .then((eventId) => {
              if (eventId) {
                console.log('Meeting message sent');
              }
            });
          // });
        }
      }
    });
  };

  const startChatSingle = async (user) => {
    console.log(matrixClient,'matrixClientmatrixClient')
    try {
      // Ensure the matrix client is ready
      if (!matrixClient || !matrixClient.isLoggedIn()) {
        console.log('Matrix client is not ready');
        return;
      }
      // Fetch the m.direct account data
      const directRooms =
        (await matrixClient.getAccountData('m.direct')?.getContent()) || {};
      // Check if the otherUserId is in the m.direct data
      console.log(directRooms,'directRoomsdirectRooms')
      if (
        directRooms[user?.matrix_user_id] &&
        directRooms[user?.matrix_user_id].length > 0 &&
        directRooms[user?.matrix_user_id][0] !== null
      ) {
        // For simplicity, we return the first one here
        dispatch(startChatWithUser(directRooms[user?.matrix_user_id][0]));
        return directRooms[user?.matrix_user_id][0]; // or apply logic to find the latest room
      } else {
        console.log('need to create of find room');
        dispatch(startChatWithUserbyMxId(user))
      }
    } catch (error) {
      console.error('Error finding direct room ID:', error);
    }
  };

  const findDirectRoomId = (otherUserId) => {
    try {
      // Fetch the m.direct account data
      const directRooms =
        matrixClient.getAccountData('m.direct')?.getContent() || {};

      // Check if the otherUserId is in the m.direct data
      if (directRooms[otherUserId] && directRooms[otherUserId].length > 0) {
        // If there are multiple rooms, you may want to choose the most recent one
        // For simplicity, we return the first one here
        return directRooms[otherUserId][0]; // or apply logic to find the latest room
      }
      return null; // No direct room found between the users
    } catch (error) {
      console.error('Error finding direct room ID:', error);
      return null;
    }
  };

  const isRoomDirect = (roomId) => {
    try {
      // Fetch the m.direct account data
      const directRooms =
        matrixClient.getAccountData('m.direct')?.getContent() || {};

      // Loop through the m.direct data to see if roomId is listed
      for (const [userId, rooms] of Object.entries(directRooms)) {
        if (rooms.includes(roomId)) {
          return true; // Room is a direct room
        }
      }

      return false; // Room is not a direct room
    } catch (error) {
      console.error('Error checking if room is direct:', error);
      return false;
    }
  };

  const verifyDevices = async (unknownDevices) => {
    if (!unknownDevices || !matrixClient) return;
    try {
      await Promise.all(
        unknownDevices.forEach((innerMap, userId) => {
          innerMap.forEach((value, key) => {
            matrixClient.setDeviceVerified(userId, key, true);
            console.log(key);
            console.log(value);
          });
        })
      );
      setUnknownDevices(null);
      console.log('Devices verified successfully');
      return true;
    } catch (error) {
      console.error('Error verifying devices:', error);
      return false;
    }
  };

  // Update directRooms for looged in user
  const updateDirectRooms = async (roomId, invitee) => {
    try {
      const accountData = await matrixClient.getAccountData('m.direct');
      let directRooms = accountData ? accountData.getContent() : {};

      if (!directRooms[invitee]) {
        directRooms[invitee] = [];
      }

      directRooms[invitee].push(roomId);

      await matrixClient.setAccountData('m.direct', directRooms);
      console.log(`Room ${roomId} added to m.direct list for user ${invitee}`);
    } catch (error) {
      console.error('Error updating m.direct account data:', error);
    }
  };

  // Get user blocked list from matrix
  const getIgnoredUsers = async () => {
    try {
      const ignoredUsers = await matrixClient.getAccountData(
        'm.ignored_user_list'
      );
      const ignoredRooms = await matrixClient.getAccountData('m.direct');
      console.log('rooms', ignoredRooms);
      return ignoredUsers ? ignoredUsers.getContent().ignored_users : [];
    } catch (error) {
      console.error('Error getting ignored users:', error);
      return [];
    }
  };
  // Add user to blocked list from matrix
  const addIgnoredUser = async (user) => {
    try {
      const ignoredUsers = await getIgnoredUsers();
      if (ignoredUsers.includes(user?.matrix_user_id)) {
        console.log(`User ${user?.matrix_user_id} is already ignored`);
        return;
      }

      ignoredUsers.push(user?.matrix_user_id);
      await matrixClient.setAccountData('m.ignored_user_list', {
        ignored_users: ignoredUsers,
      });
      await dispatch(
        blockConnection({
          block_user_id: user?.id,
        })
      );
      // await refetchBlocked?.();
      console.log(`User ${user?.matrix_user_id} added to ignored user list`);
    } catch (error) {
      console.error('Error adding ignored user:', error);
    }
  };
  // Check user exists in blocked list from matrix
  const checkUserBlockState = async (user) => {
    const ignoredUsers = await getIgnoredUsers();
    if (ignoredUsers?.includes(user)) {
      console.log(`User ${user} is already ignored`);
      return true;
    } else {
      return false;
    }
  };

  // Remove user from the blocked list from matrix
  const removeIgnoredUser = async (userId, user) => {
    try {
      let ignoredUsers = await getIgnoredUsers();
      if (!ignoredUsers.includes(userId)) {
        console.log(`User ${userId} is not in the ignored list`);
        return;
      }
      ignoredUsers = ignoredUsers.filter((user) => user !== userId);
      await matrixClient.setAccountData('m.ignored_user_list', {
        ignored_users: ignoredUsers,
      });
      await dispatch(
        unblockConnection({
          block_user_id: user?.id,
        })
      );
      // await refetchBlocked?.();
      console.log(`User ${userId} removed from ignored user list`);
    } catch (error) {
      console.error('Error removing ignored user:', error);
    }
  };

  // console.log('matrixClient', matrixClient);

  return (
    <MatrixContext.Provider
      value={{
        matrixClient,
        conversationList,
        chatPresets,
        joinRules,
        loggedInUser,
        initiateMatrixCall,
        initiateCall,
        callStatus,
        ringingIncoming,
        callDetails,
        setIncomingRinging,
        setCallDetails,
        setupMatrixClient,
        startMeetingSingle,
        startChatSingle,
        teamsChats,
        setTeamsChats,
        createGroupModal,
        startChatwithUserIDifExisted,
        newChatDisc,
        selectedChat, 
        setSelectedChat,
        connectionList,
        setConnectionList,
        roomId, 
        setRoomId,
        setUnknownDevices,
        verifyDevices,
        unreadCount,
        setUnreadCount,
        addIgnoredUser,
        getIgnoredUsers,
        checkUserBlockState,
        removeIgnoredUser,
        updateDirectRooms,
        findDirectRoomId,
        isRoomDirect,
        setCallingModal,
        callingModal,
        messages,
        setMessages
      }}
    >
      {children}

      {ringingIncoming && (
        <audio id="ringing" loop autoPlay controls={false}>
          <source src="/audio/incomingcallring.mp3" type="audio/mpeg" />
        </audio>
      )}
      {ringingOutgoing && (
        <audio id="ringing" loop autoPlay controls={false}>
          <source src="/audio/outgoingcallring.mp3" type="audio/mpeg" />
        </audio>
      )}
    </MatrixContext.Provider>
  );
};

// export { MatrixContext, MatrixProvider };
