/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { TeamsContext } from '../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const TeamLeaveModal = ({ onClose, title }) => {
  const { t } = useTranslator();
  const {
    isOrganizer,
    leaveTeam,
    team,
    teamDetails,
    leaveTeamLoading,
    leaveTeamDisc,
    userProfileInfo,
  } = useContext(TeamsContext);

  console.log('teamDetailsisOrganizer', teamDetails?.disk_payer !== userProfileInfo?.email &&
    teamDetails?.organizers?.length >= 1);
  const generalMessage = (
    <Stack>
      <Box mb={4}>
        <Text display="inline">{t('teamsPage.noLongerAccess') || "You will no longer be able to access to"} </Text>
        <Text display="inline" fontWeight="medium">
          "{team?.name ?? teamDetails?.name}"
        </Text>
        <Text display="inline">
          {' '}
          {t('teamsPage.teamResources') || "and any team resources, including all files and conversations."}
        </Text>
      </Box>
      <Text>{t('teamsPage.') || "Would you still like to leave this team?"}</Text>
    </Stack>
  );

  return (
    <Modal {...leaveTeamDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('teamsPage.leaveTeam') || "Leave this team"}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {(isOrganizer || teamDetails?.disk_payer === userProfileInfo?.email &&
            teamDetails?.organizers?.length === 1) ? (
            teamDetails?.organizers?.length <= 1 ? (
              <Stack>
                <Text>{t('teamsPage.cannotLeaveOnlyOrganizer') || "Can't leave team as you are the only organizer!"}</Text>
              </Stack>
            ) : teamDetails?.disk_payer === userProfileInfo?.email ? (
              <Stack>
                <Text>
                  {t('teamsPage.assignSubscription') || "To leave this team, you will need to assign the team's subscription to another organizer."}
                </Text>
              </Stack>
            ) : (
              generalMessage
            )
          ) : (
            generalMessage
          )}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => leaveTeamDisc?.onClose?.()}
            >
              {t('buttons.cancel') || "Cancel"}
            </Button>
            {teamDetails?.disk_payer !== userProfileInfo?.email &&
            teamDetails?.organizers?.length >= 1 ? (
              <Button
                isLoading={leaveTeamLoading}
                variant="primary"
                onClick={(e) => leaveTeam?.(team ?? teamDetails)}
              >
                {t('meeting.leave') || "Leave"}
              </Button>
            ) : (
              ''
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
