import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meeting_status: "idle",
};

const meetingStateSlice = createSlice({
  name: 'meetingStates',
  initialState,
  reducers: {
    setMeetingStatus: (state, action) => {
      state.meeting_status = action.payload;
    }
  },
});

export const { setMeetingStatus } =
meetingStateSlice.actions;

export default meetingStateSlice.reducer;
