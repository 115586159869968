export const PageRoutes = [
  {
    path: '^/jobs.*',
    isPublic: true,
  },
  {
    path: '^/privacy-policy',
    isPublic: true,
  },
  {
    path: '^/terms-of-use',
    isPublic: true,
  },
  {
    path: '^/csae-policy',
    isPublic: true,
  },
  {
    path: '^/about-us',
    isPublic: true,
  },
  {
    path: '^/business',
    isPublic: true,
  },
  {
    path: '^/p/.*',
    isPublic: true,
  },
  {
    path: '^/t/.*',
    isPublic: true,
  },
  {
    path: '^/j/.*',
    isPublic: true,
  },
  {
    path: '^/c/.*',
    isPublic: true,
  },
  {
    path: '^/a/.*',
    isPublic: true,
  },
  {
    path: '^/p/.*',
    isPublic: true,
  },
  {
    path: '^/support.*',
    isPublic: true,
  },
  {
    path: '^/event/details',
    isPublic: true,
  },
  {
    path: '^/articles/detail',
    isPublic: true,
  },
  {
    path: '^/password_resetconfirm',
    isPublic: true,
  },
  {
    path: '^/g/people.*',
    isPublic: true,
  },
  {
    path: '^/g/.*',
    isPublic: true,
  },
  {
    path: '^/g',
    isPublic: true,
  },
  {
    path: '^/$',
    isPublic: true,
  },
  {
    path: '^/signin',
    isPublic: true,
  },
  {
    path: '^/signup',
    isPublic: true,
  },
  {
    path: '^/appointment',
    isPublic: true,
  },
  {
    path: '^/forgot-password',
    isPublic: true,
  },
];
